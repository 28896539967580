import _ from 'lodash';
import { DSP, GOAL_VALUE_TYPE } from 'constantsBase';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import { DspToAnalyticsData, PrimaryStrategyGoal, ViewabiltyGoal } from '../types';
import { formatGoalValue, getPrimaryGoalKey } from '../utils';
import { GoalChartVizData, goalChartStyles } from './constants';

export const getValueRange = (data: Array<GoalChartVizData>) => (
  // include 0 in the value range
  [..._.map(data, (d) => d.value ?? d.point), 0]
);

export const getMaxBarWidth = (data: Array<GoalChartVizData>) => {
  const maxDataPoint = _.maxBy(data, (d) => (d.value ?? d.point));
  const maxLabelLength = _.size(`${maxDataPoint.value ?? maxDataPoint.point}${maxDataPoint.label}`);
  const baseBarLen = 200 - (3 * maxLabelLength);

  // calculate bar length of highest DSP performance value if Goal is higher than performances
  if (maxDataPoint.name === 'Goal') {
    return (baseBarLen / maxDataPoint.point) * _.maxBy(data, (d) => d.value).value;
  }

  return baseBarLen;
};

export const getBarPath = (
  value: number,
  barX: number,
  barY: number,
  barWidth: number,
  barHeight: number,
  borderRadius: number,
) => (value >= 0
  ? `
    M ${barX},${barY} 
    L ${barX + barWidth - borderRadius},${barY} 
    A ${borderRadius},${borderRadius} 0 0 1 ${barX + barWidth},${barY + borderRadius} 
    L ${barX + barWidth},${barY + barHeight - borderRadius} 
    A ${borderRadius},${borderRadius} 0 0 1 ${barX + barWidth - borderRadius},${barY + barHeight} 
    L ${barX},${barY + barHeight} 
    Z
  ` : `
    M ${barX + borderRadius},${barY}
    L ${barX + barWidth},${barY} 
    L ${barX + barWidth},${barY + barHeight} 
    L ${barX + borderRadius},${barY + barHeight} 
    A ${borderRadius},${borderRadius} 0 0 1 ${barX},${barY + barHeight - borderRadius}
    L ${barX},${barY + borderRadius}
    A ${borderRadius},${borderRadius} 0 0 1 ${barX + borderRadius},${barY}
    Z
  `
);

export const getCustomPadding = ({ name }: GoalChartVizData) => {
  switch (name) {
    case 'ALL':
      return -8;
    case 'Goal':
      return 8;
    default:
      return 0;
  }
};

export const buildCrossPlatformGoalChartVizData = (
  goal: PrimaryStrategyGoal | ViewabiltyGoal,
  currency: string,
  mostRecentStratCumData: StrategyGoalAnalyticsData,
  dspToMostRecentCumData: DspToAnalyticsData,
): Array<GoalChartVizData> => {
  const { name, target, valueType } = goal;
  const allLabel = valueType === GOAL_VALUE_TYPE.PERCENTAGE ? '%' : ` ${currency}`;
  const dspAndGoalLabel = valueType === GOAL_VALUE_TYPE.PERCENTAGE ? '%' : '';
  const goalKpiKey = getPrimaryGoalKey(mostRecentStratCumData) ?? name;

  const strategyData = {
    name: 'ALL',
    color: goalChartStyles.ALL.color,
    value: formatGoalValue(name, _.get(mostRecentStratCumData, goalKpiKey, 0), valueType),
    label: valueType ? allLabel : '',
  };

  const individualDspData = _.map(dspToMostRecentCumData, (data, dsp) => {
    const dspCode = DSP.getById(dsp).code;
    return {
      name: dspCode,
      color: goalChartStyles[dspCode].color,
      value: formatGoalValue(name, _.get(data, goalKpiKey, 0), valueType),
      label: dspAndGoalLabel,
    };
  });

  // omit value as there will be a separate goal point/line
  const goalData = {
    name: 'Goal',
    color: goalChartStyles.Goal.color,
    label: dspAndGoalLabel,
    point: formatGoalValue(name, target, valueType),
  };

  return [strategyData, ...individualDspData, goalData];
};

export const buildCrossPlatformBudgetDeliveryVizData = (dspToMostRecentCumData: DspToAnalyticsData, deliveryKpi: BudgetTypeMapping) => {
  const totalDelivery = _.sumBy(_.values(dspToMostRecentCumData), deliveryKpi);
  return _.map(
    dspToMostRecentCumData,
    (mostRecentData: StrategyGoalAnalyticsData, dsp: string) => {
      const dspCode = DSP.getById(dsp).code;
      return {
        name: dspCode,
        color: goalChartStyles[dspCode].color,
        value: _.round((_.get(mostRecentData, deliveryKpi, 0) / totalDelivery) * 100),
      };
    },
  );
};
