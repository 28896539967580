import React from 'react';
import _ from 'lodash';
import { Icon, SemanticCOLORS, SemanticICONS } from 'buildingBlocks';

type InsightsConfigFn = (input?: {}) => string;
type InsightSingleConfig = {
  title: InsightsConfigFn
  statisticValue: InsightsConfigFn
  statisticSuffix: InsightsConfigFn
  description: (input?: {}) => React.FunctionComponent | InsightsConfigFn
  upliftArrow?: (input?: {}) => { direction: string, color: string },
  stylingClassNames?: string
};

type InsightsBoxProps = {
  insights: {
    config: Array<InsightSingleConfig>
    data: {}
  }
};

type SingleInsightsBoxProps = {
  config: InsightSingleConfig
  data: {}
};

const SingleInsight = ({ config, data }: SingleInsightsBoxProps) => {
  const { title, statisticValue, statisticSuffix, description, stylingClassNames, upliftArrow } = config;
  const titleText = title(data);
  const arrow = upliftArrow && upliftArrow(data);
  return (
    <div key={titleText}>
      <span className="num"><Icon name="lightbulb" />{titleText}</span>
      <div>
        <span className="stat">{statisticValue(data)}</span>
        { arrow && <span className="arrow"><Icon color={arrow.color as SemanticCOLORS} name={`arrow ${arrow.direction}` as SemanticICONS} /></span> }
        <span className="unit">{statisticSuffix(data)}</span>
      </div>
      <hr />
      <p className={stylingClassNames}>{description(data)}</p>
    </div>
  );
};

export default (props: InsightsBoxProps) => {
  const { config, data } = props.insights;
  return (
    <div className="insights-box">
      {
        _.map(config, (c, i) => (
          <SingleInsight key={`insight-page-${i}`} config={c} data={data} />
        ))
      }
    </div>
  );
};
