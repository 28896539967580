import { ScaleThreshold } from 'd3';
import _ from 'lodash';
import React from 'react';
import { Grid } from 'buildingBlocks';
import { TopDeliveringChildWithGoal, VIZ_HEIGHT } from './constants';

const BudgetAllocationVizRow = ({ InnerComponent }: { InnerComponent: JSX.Element }) => (
  <Grid.Row>
    <Grid.Column>
      {InnerComponent}
    </Grid.Column>
  </Grid.Row>
);

type BudgetAllocationVizColumnProps = {
  className: string
  width: number
  data: Array<TopDeliveringChildWithGoal>
  InnerComponent: React.FunctionComponent<{
    data: TopDeliveringChildWithGoal
    index?: number
    topChildrenCount?: number
    colorScale?: ScaleThreshold<number, string>
    valueType?: string
  }>
  colorScale?: ScaleThreshold<number, string>
  valueType?: string
};

const BudgetAllocationVizColumn = (props: BudgetAllocationVizColumnProps) => {
  const { className, width, data, InnerComponent, colorScale, valueType } = props;
  return (
    <Grid style={{ width, height: VIZ_HEIGHT }} className={className}>
      {_.map(data, (d, i) => (
        <BudgetAllocationVizRow
          key={d.childExtId}
          InnerComponent={(
            <InnerComponent
              data={d}
              index={i}
              topChildrenCount={_.size(data)}
              colorScale={colorScale}
              valueType={valueType}
            />
          )}
        />
      ))}
    </Grid>
  );
};

export default BudgetAllocationVizColumn;
