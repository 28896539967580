import _ from 'lodash';
import React, { createContext, useContext, useEffect, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { GlobalState } from 'reducers';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { getAdditionalGoalInfo, getViewabilityGoal, hasBudgetGroupsCheck, useInsightsAnalyticsDataFetcher } from '../utils';

const InsightsContext = createContext(null);

type Props = {
  children: ReactNode
};

const InsightsProvider = ({ children }: Props) => {
  const [values, setValues] = useState({ kind: PossibleStates.initial });
  const strategyAnalyticsMetadata = useSelector<GlobalState>((state) => state.strategyAnalytics.metadata) as any;
  const {
    strategy,
    strategyGoalAnalyticsMetadata,
    currency: { code: currency },
    budgetOptData,
    stratData,
    childData,
  } = strategyAnalyticsMetadata;
  const { config: { intelligentChildObjects } } = strategy;
  const { budgetOptConfig: { childGroups }, budgetSettings, goal } = strategyGoalAnalyticsMetadata;
  const isCrossPlatform = isCrossPlatformStrategyType(strategy.strategyType.id);
  const hasBudgetGroups = hasBudgetGroupsCheck(childGroups);
  const insightsAnalyticsDataFetchState = useInsightsAnalyticsDataFetcher(strategy.id, isCrossPlatform, hasBudgetGroups, intelligentChildObjects);

  useEffect(() => {
    if (insightsAnalyticsDataFetchState.kind === PossibleStates.hasData) {
      const primaryStrategyGoal = getAdditionalGoalInfo(goal);
      const budgetType = _.get(_.head(budgetSettings), 'parentSettings.budgetType') as BudgetTypes;
      const viewabilityGoal = getViewabilityGoal(strategy.strategyGoals);
      setValues({
        kind: insightsAnalyticsDataFetchState.kind,
        budgetOptData,
        stratData,
        childData,
        currency,
        strategy,
        budgetType,
        metadata: strategyGoalAnalyticsMetadata,
        primaryStrategyGoal,
        viewabilityGoal,
        ...insightsAnalyticsDataFetchState.data,
      });
    }
    if (insightsAnalyticsDataFetchState.kind === PossibleStates.error) {
      setValues({ kind: insightsAnalyticsDataFetchState.kind });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsAnalyticsDataFetchState]);

  return (
    <InsightsContext.Provider value={values}>
      {children}
    </InsightsContext.Provider>
  );
};

export const useInsightsContext = () => {
  const context = useContext(InsightsContext);
  if (!context) {
    throw new Error('useInsightsContext must be used within a InsightsProvider');
  }
  return context;
};

export default InsightsProvider;
