import React from 'react';
import _ from 'lodash';
import { OrigCloneMapping } from 'charts/InsightsViz/types';
import { formatDspObjectName } from 'charts/InsightsViz/utils';
import { BaseListRowProps } from './ListRow';

type Props = BaseListRowProps & {
  labels: { [childExtIs: string]: string }
  cloneToOrig: OrigCloneMapping
};

const childNameCharLimit = 44;
export const formatChildName = (name: string, charLimit: number) => {
  const formattedName = formatDspObjectName(name);
  return (_.size(formattedName) > charLimit) ? `...${_.join(_.takeRight(formattedName, charLimit), '')}` : formattedName;
};

export const ChildItemListRow = (props: Props) => {
  const {
    datum,
    delivery,
    perfStyle,
    formattedPerfVal,
    labels,
    cloneToOrig,
  } = props;
  const isClone = _.has(cloneToOrig, datum.childExtId);
  const label = labels[datum.childExtId] || labels[cloneToOrig[datum.childExtId]];
  return (
    <tr className={`list-row child ${isClone ? 'intelligent' : ''}`} key={datum.childExtId}>
      <td className="name" title={datum.name}>
        <strong>{label}</strong>
        {isClone && <span className="intelligent-i">i</span>}
        <span> &#8594; </span>
        <span>{formatChildName(datum.name, childNameCharLimit)}</span>
      </td>
      <td className="delivery">{delivery}</td>
      <td className="perf">
        <span style={perfStyle}>{formattedPerfVal}</span>
      </td>
    </tr>
  );
};

export default ChildItemListRow;
