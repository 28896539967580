import { Options } from 'highcharts';
import _ from 'lodash';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { COPILOT_COLORS } from 'globalStyles';
import { GroupedIsCloneDataType } from './utils';

const { NEW_DESIGN_SYSTEM: { BLUES, NEUTRALS } } = COPILOT_COLORS;

const deliveryAllocationChartConfig = (deliveryForOriginals, deliveryForIntelligent): Options => ({
  chart: {
    height: 270,
    width: 750,
    animation: false,
    style: { fontFamily: 'Gilroy' },
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      shadow: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  xAxis: {
    title: {
      text: null,
    },
    lineWidth: 0,
    minorGridLineWidth: 0,
    labels: {
      enabled: false,
    },
    minorTickLength: 0,
    tickLength: 0,
    lineColor: 'transparent',
    visible: false,
  },
  yAxis: {
    tickAmount: 5,
    title: {
      text: 'DAILY DELIVERY (%)',
      align: 'middle',
      rotation: -90,
      y: 5,
      style: {
        fontSize: '12px',
      },
    },
    tickWidth: 1,
    gridLineColor: 'transparent',
    min: 0,
    max: 100,
  },
  series: [
    {
      name: '',
      type: 'area',
      data: deliveryForOriginals,
      zIndex: 0,
      color: NEUTRALS.N750_INSIGHTS,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      showInLegend: false,
    },
    {
      name: '',
      type: 'areaspline',
      data: deliveryForIntelligent,
      zIndex: 10,
      lineColor: NEUTRALS.N0_WHITE,
      fillColor: BLUES.B200_LAGOON,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      showInLegend: false,
    },
  ],
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
});

type IntelligentOptDeliveryAllocationChartProps = {
  groupedIsCloneDailyData: GroupedIsCloneDataType
  intelligentCumDelPercent: number
  childDisplayName: string
  budgetType: BudgetTypes
};

const IntelligentOptDeliveryAllocationChart = (props: IntelligentOptDeliveryAllocationChartProps) => {
  const {
    groupedIsCloneDailyData: { intelligent, original },
    intelligentCumDelPercent,
    childDisplayName,
    budgetType,
  } = props;
  const deliveryKpi = BudgetTypeMapping[budgetType];
  const dates = _.map(original, 'date');
  const deliveryForOriginal = _.map(dates, () => [100]);
  const deliveryForIntelligent = _.map(dates, (d: string) => {
    const cloneDelivery = _.get(_.find(intelligent, ['date', d]), deliveryKpi, 0);
    const originalDelivery = _.get(_.find(original, ['date', d]), deliveryKpi);
    return (cloneDelivery / (originalDelivery + cloneDelivery)) * 100;
  });

  return (
    <div className="intelligent-opt-delivery-allocation-viz">
      <ReactHighcharts config={deliveryAllocationChartConfig(deliveryForOriginal, deliveryForIntelligent)} isPureConfig />
      <div className="intelligent-del-text">
        Intelligent {childDisplayName}s accounted for <b>{intelligentCumDelPercent}%</b> of total delivery
      </div>
    </div>
  );
};

export default IntelligentOptDeliveryAllocationChart;
