import _ from 'lodash';
import React, { useState } from 'react';
import { Icon } from 'buildingBlocks';
import { DSP, STRATEGY_TYPE } from 'constantsBase';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import ErrorSegment from './Components/ErrorSegment';
import SlideTitle from './Components/SlideTitle';
import SlideWrapper from './Components/SlideWrapper';
import { IconConfig, IconType, SlideIcons, VizId } from './constants';
import { useInsightsContext } from './contexts/InsightsProvider';
import { BaseInsightsSlideProps } from './types';
import { getChildDisplayName, getParentDisplayName } from './utils';

const vizId = VizId.tableOfContents;

type SlideTitleAndDescriptionProps = { title: string, description: string, icon: IconConfig };

const SlideTitleAndDescription = ({ title, description, icon }: SlideTitleAndDescriptionProps) => (
  <div className="slide-preview">
    <h6>
      {icon.kind === IconType.fontAwesome
        ? <Icon name={icon.name} />
        : <img alt="icon" src={`/img/icons/insights/${icon.name}.svg`} />}
      {title}
    </h6>
    <p>{description}</p>
  </div>
);

type TableOfContentProps = {
  isYouTube: boolean
  hasGeoData: boolean
  shouldRenderIntTargetingViz: boolean
};

const TableOfContents = ({ onVizLoaded, onVizError, isYouTube, hasGeoData, shouldRenderIntTargetingViz }: BaseInsightsSlideProps & TableOfContentProps) => {
  const [dataError, setDataError] = useState<boolean>(false);
  const {
    metadata: { attachments },
    strategy: {
      config: { intelligentChildObjects },
      strategyType: { dsp, id: stratTypeId },
    },
  } = useInsightsContext();

  const parentDisplayName = getParentDisplayName(dsp);
  const childDisplayName = getChildDisplayName(dsp);
  const isCrossPlatform = isCrossPlatformStrategyType(stratTypeId);
  const isAmzn = stratTypeId === STRATEGY_TYPE.amznBudgetOptimization.id;
  const isWalmart = isCrossPlatform ? _.some(attachments, { dsp: DSP.WALMART.id }) : dsp === DSP.WALMART.id;

  useMount(() => {
    if (!attachments || !dsp) {
      setDataError(true);
      onVizError();
    }
    onVizLoaded(['The table of contents lays out the Insights content available for this strategy. The content that\'s available depends on the strategy settings and buying platform settings. See help center for more details.']);
  });

  return dataError ? <ErrorSegment /> : (
    <div id={vizId} className="slide">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SlideTitle
          section="Table of Contents"
          icon={SlideIcons.tableOfContents}
        />
      </div>
      <div className="flex-container">
        <div className="main-visualization">
          <h1>1</h1>
          <hr />
          <h2 className="header-2">Campaign Insights</h2>
          <p className="section-description">
            Strategy summary comparing delivery and performance to the strategy goals.
          </p>
          <SlideTitleAndDescription
            title="Campaign Overview"
            description="Provides summary of performance versus goal, goal performance over time, delivery over time and estimated uplift."
            icon={SlideIcons.performanceAndDeliverySummary}
          />
        </div>
        <div className="main-visualization">
          <h1>2</h1>
          <hr />
          <h2 className="header-2">Optimization Insights</h2>
          <p className="section-description">
            Application of Copilot to the {parentDisplayName}{_.size(attachments) !== 1 ? 's' : ''} and visualization of model processes.
          </p>
          <SlideTitleAndDescription
            title="Optimization Summary"
            description={`Overview of Strategy hierarchy, where Copilot models are applied and Copilot generated ${childDisplayName}s.`}
            icon={SlideIcons.optimizationSummary}
          />
          <SlideTitleAndDescription
            title="Budget Optimization Summary"
            description={`Visualizes budget delivery and goal performance for top spending ${childDisplayName}s.`}
            icon={SlideIcons.budgetOptimization}
          />
          <SlideTitleAndDescription
            title="Budget Allocation & Available Scale"
            description={`Visualizes budget allocation versus budget delivery per ${childDisplayName} for top spending ${childDisplayName}s.`}
            icon={SlideIcons.budgetOptimization}
          />
          {intelligentChildObjects && (
            <SlideTitleAndDescription
              title={`Intelligent ${childDisplayName} Summary`}
              description={`Visualizes and compares delivery, performance and uplift of Copilot's Intelligent ${childDisplayName}s.`}
              icon={SlideIcons.intelligentOptimization}
            />
          )}
          {(intelligentChildObjects && shouldRenderIntTargetingViz && !isWalmart) && (
            <SlideTitleAndDescription
              title={`Intelligent ${childDisplayName} Targeting`}
              description={`Visualizes and compares feature values that were analyzed and targeted on Copilot generated Intelligent ${childDisplayName}s.`}
              icon={SlideIcons.intelligentOptimization}
            />
          )}
        </div>
        {(!isCrossPlatform && !isAmzn && !isWalmart) && (
          <div className="main-visualization">
            <h1>3</h1>
            <hr />
            <h2 className="header-2">Feature Insights</h2>
            <p className="section-description">
              Section provides feature insight highlighting contextual, device and geographical performance.
            </p>
            {!isYouTube && (
              <SlideTitleAndDescription
                title="Contextual"
                description={`Overview of Strategy hierarchy, where Copilot models are applied and Copilot generated ${childDisplayName}s.`}
                icon={SlideIcons.contextualInsights}
              />
            )}
            <SlideTitleAndDescription
              title="Device"
              description={`Visualizes and compares goal performance and delivery across sites and applications contributing to delivery on the ${parentDisplayName}.`}
              icon={SlideIcons.deviceInsights}
            />
            {hasGeoData && (
              <SlideTitleAndDescription
                title="Geography"
                description={`Visualizes and compares goal performance and delivery across regions contributing to delivery on the ${parentDisplayName}.`}
                icon={SlideIcons.geoInsights}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(SlideWrapper(TableOfContents));
