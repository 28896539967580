import { format } from 'd3';
import React from 'react';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { DeliveryLegendThicknesses } from '../Components/LegendLine';

type LegendLineProps = {
  thickness: number
  label: string
};

const BudgetAllocationLegendLine = ({ thickness, label }: LegendLineProps) => (
  <div className="budget-allocation-legend-line-container">
    <div className="budget-allocation-legend-line" style={{ height: thickness }} />
    <span>{label}</span>
  </div>
);

type BudgetAllocationLegendProps = {
  getDeliveryFromThickness: (del: number) => number
  deliveryKpi: BudgetTypeMapping
  currency: string
};

const BudgetAllocationLegend = (props: BudgetAllocationLegendProps) => {
  const { getDeliveryFromThickness, deliveryKpi, currency } = props;
  // format labels to two significant figures
  const generateLabel = (thickness: number) => {
    const value = getDeliveryFromThickness(thickness);
    return `${format(value >= 1 ? '.2s' : '.2f')(value)} ${deliveryKpi === BudgetTypeMapping.amount ? `${currency}` : 'IMP'}`;
  };

  return (
    <div id="budget-opt-overview-allocation-legend">
      <h6>Budget Allocation</h6>
      <div className="budget-opt-overview-allocation-legend-row">
        <BudgetAllocationLegendLine
          thickness={DeliveryLegendThicknesses.eight}
          label={generateLabel(DeliveryLegendThicknesses.eight)}
        />
        <BudgetAllocationLegendLine
          thickness={DeliveryLegendThicknesses.twelve}
          label={generateLabel(DeliveryLegendThicknesses.twelve)}
        />
      </div>
      <div className="budget-opt-overview-allocation-legend-row">
        <BudgetAllocationLegendLine
          thickness={DeliveryLegendThicknesses.sixteen}
          label={generateLabel(DeliveryLegendThicknesses.sixteen)}
        />
        <BudgetAllocationLegendLine
          thickness={DeliveryLegendThicknesses.twenty}
          label={generateLabel(DeliveryLegendThicknesses.twenty)}
        />
      </div>
    </div>
  );
};

export default BudgetAllocationLegend;
