import _ from 'lodash';
import { Options, SeriesOptionsType } from 'highcharts';
import moment from 'moment';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { DSP } from 'constantsBase';
import { StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import { COPILOT_COLORS } from 'globalStyles';
import { useInsightsContext } from '../contexts/InsightsProvider';

const { NEW_DESIGN_SYSTEM: { DSP: dspColors } } = COPILOT_COLORS;

const getChartSeries = (groupedDailyDspData: { [dsp: string]: Array<StrategyGoalAnalyticsData> }, deliveryKpi: BudgetTypeMapping): Array<SeriesOptionsType> => (
  _.map(groupedDailyDspData, (dailyData: Array<StrategyGoalAnalyticsData>, dspId: string) => {
    const dsp = DSP.getById(+dspId);
    return {
      name: _.isEqual(dspId, DSP.TTD.id) ? dsp.code : dsp.displayName,
      color: dspColors[dspId].dark,
      lineColor: dspColors[dspId].dark,
      fillColor: dspColors[dspId].light,
      data: _.map(dailyData, (d) => [moment(d.date).valueOf(), d[deliveryKpi] ?? 0]),
    } as SeriesOptionsType;
  })
);

const getCrossPlatformChartConfig = (series: Array<SeriesOptionsType>, title: string): Options => ({
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    type: 'area',
    height: 235,
    width: 846,
    animation: false,
    style: { fontFamily: 'Gilroy' },
  },
  plotOptions: {
    area: {
      stacking: 'normal',
      lineWidth: 2,
      marker: {
        enabled: false,
      },
      label: { enabled: false },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    series: {
      events: {
        legendItemClick: (e) => e.preventDefault(),
      },
      lineWidth: 1,
    },
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    title: {
      text: null,
    },
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    tickWidth: 1,
    type: 'datetime',
    dateTimeLabelFormats: { day: '%e %b' },
    labels: {
      step: 2,
      align: 'center',
    },
  },
  yAxis: {
    title: {
      text: title,
      align: 'middle',
      rotation: -90,
      y: 5,
      style: {
        fontSize: '12px',
        fontWeight: '500',
      },
    },
    gridLineColor: 'transparent',
    tickWidth: 1,
  },
  series,
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
});

const CustomLegend = ({ dsps }: { dsps: Array<number> }) => (
  <div className="custom-legend-container">
    {_.map(dsps, (dspId: number) => {
      const dsp = DSP.getById(dspId);
      return (
        <div className="custom-legend" key={dspId}>
          <div className="dsp-legend-symbol" style={{ backgroundColor: dspColors[dspId].light, borderTop: `1px solid ${dspColors[dspId].dark}` }} />
          <span className="dsp-legend-label" style={{ ...(!_.isEqual(dspId, _.last(dsps)) && { marginRight: 16 }) }}>{_.isEqual(dspId, DSP.TTD.id) ? dsp.code : dsp.displayName}</span>
        </div>
      );
    })}
  </div>
);

type CrossPlatformDeliveryChartProps = {
  title: string
};

const CrossPlatformDeliveryChart = (props: CrossPlatformDeliveryChartProps) => {
  const { title } = props;
  const { dspData: { dailyData }, budgetType } = useInsightsContext();
  const groupedDailyDspData = _.groupBy(dailyData, 'dsp');
  const dsps = _.map(_.keys(groupedDailyDspData), _.toNumber);

  const series = getChartSeries(groupedDailyDspData, BudgetTypeMapping[budgetType]);

  return (
    <div className="cross-platform-delivery">
      <ReactHighcharts
        config={getCrossPlatformChartConfig(series, title)}
        isPureConfig
      />
      <CustomLegend dsps={dsps} />
    </div>
  );
};

export default CrossPlatformDeliveryChart;
