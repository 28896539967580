import * as d3 from 'd3';
import React from 'react';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { CrossPlatformBudgetDeliveryData } from './constants';
import { buildCrossPlatformBudgetDeliveryVizData } from './utils';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { DspToAnalyticsData } from '../types';

const buildViz = (data: Array<CrossPlatformBudgetDeliveryData>) => {
  // dimensions of the chart
  const width = 310;
  const height = 245;
  const radius = 100;

  // select container and append SVG element
  const svg = d3.select('.cross-platform-budget-delivery')
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${width / 2}, ${height / 2})`);

  // define the arc generator of the slices
  const arc = d3.arc<d3.PieArcDatum<CrossPlatformBudgetDeliveryData>>()
    .innerRadius(radius * 0.7)
    .outerRadius(radius * 0.9);

  // define the pie generator for the layout
  const pie = d3.pie<void, CrossPlatformBudgetDeliveryData>()
    .sort(null)
    .value((d) => d.value)
    .padAngle(0.03);

  // create the slices
  svg.selectAll('path.slice')
    .data(pie(data))
    .enter()
    .append('path')
    .attr('class', 'slice')
    .attr('d', arc)
    .attr('fill', (d) => d.data.color);

  // define the arc generator for the labels
  const labelArc = d3.arc<d3.PieArcDatum<CrossPlatformBudgetDeliveryData>>()
    .innerRadius(radius)
    .outerRadius(radius + 15);

  // add labels to each slice
  svg.append('g')
    .attr('text-anchor', 'middle')
    .selectAll()
    .data(pie(data))
    .join('text')
    .attr('class', 'label')
    .style('fill', (d) => d.data.color)
    .style('font-size', '12px')
    .style('font-weight', '600')
    .attr('transform', (d) => `translate(${labelArc.centroid(d)})`)
    .style('dominant-baseline', 'middle')
    .call((text) => text.append('tspan')
      .attr('y', '-0.3em')
      .text((d) => d.data.name))
    .call((text) => text.append('tspan')
      .attr('x', 0)
      .attr('y', '0.6em')
      .text((d) => `${d.data.value}%`));
};

type CrossPlatformBudgetDeliveryProps = {
  dspToMostRecentCumData: DspToAnalyticsData
};

const CrossPlatformBudgetDelivery = (props: CrossPlatformBudgetDeliveryProps) => {
  const { dspToMostRecentCumData } = props;
  const { budgetType } = useInsightsContext();
  const deliveryKpi = BudgetTypeMapping[budgetType];

  useMount(() => {
    buildViz(buildCrossPlatformBudgetDeliveryVizData(dspToMostRecentCumData, deliveryKpi));
  });

  return (
    <div className="cross-platform-budget-delivery" />
  );
};

export default CrossPlatformBudgetDelivery;
