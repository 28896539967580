import * as d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import { COPILOT_COLORS } from 'globalStyles';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import BudgetAllocationVizTableHeader from './BudgetAllocationVizTableHeader';
import { FLIGHT_CELL_WIDTH, DELIVERY_RATIO_WIDTH, TopDeliveringChildWithGoal, VIZ_HEIGHT, VIZ_WIDTH, GOAL_PERFORMANCE_WIDTH } from './constants';
import BudgetAllocationVizColumn from './BudgetAllocationVizColumn';
import { DeliveryRatioCell, FlightNameCell, GoalPerformanceCell } from './BudgetAllocationVizTableComponents';

const {
  NEW_DESIGN_SYSTEM: {
    NEUTRALS: { N0_WHITE, N600_ABBEY, N800_MATTER },
    METTLES: { M300_FOG },
  },
} = COPILOT_COLORS;

const buildViz = (data: Array<TopDeliveringChildWithGoal>, deliveryLabel: string) => {
  const barHeight = 14;
  const sideMargin = 8;
  const marginBottom = 20;
  const maxValue = d3.max(data, (d) => d3.max([d.averageDailyDelivery, d.averageDailyBudgetAllocation]));

  // create the svg
  const svg = d3.select('.delivery-and-budget-allocated-chart')
    .append('svg')
    .attr('width', VIZ_WIDTH + (2 * sideMargin))
    .attr('height', VIZ_HEIGHT + marginBottom);

  // add border around the viz
  svg.append('rect')
    .attr('class', 'border-rect')
    .attr('x', sideMargin)
    .attr('y', 1)
    .attr('width', VIZ_WIDTH)
    .attr('height', VIZ_HEIGHT - 2);

  // define the scales
  const xScale = d3.scaleLinear()
    .domain([0, maxValue])
    .range([0, VIZ_WIDTH - sideMargin]);

  const yScale = d3.scaleBand()
    .domain(data.map((d) => d.childExtId))
    .range([1, VIZ_HEIGHT - 1])
    .padding(0);

  // create the x axis and grid lines
  const unformattedTickScale = xScale.invert(VIZ_WIDTH + sideMargin) / 5;
  const tickScale = unformattedTickScale >= 1 ? _.floor(unformattedTickScale) : _.round(unformattedTickScale, 2);
  const tickValues = [0, tickScale, tickScale * 2, tickScale * 3, tickScale * 4];

  const xAxis = d3.axisBottom(xScale)
    .tickValues(tickScale >= 1 ? tickValues : _.map(tickValues, (val) => _.round(val, 2))) // prevent extra decimal places
    .tickFormat((d, i) => ((i === 4) ? `${d} ${deliveryLabel}` : _.toString(d)));

  const xAxisGroup = svg.append('g')
    .attr('transform', `translate(${sideMargin - 0.5}, ${VIZ_HEIGHT})`)
    .call(xAxis);

  xAxisGroup.selectAll('.tick line')
    .attr('y1', 5)
    .attr('y2', -VIZ_HEIGHT);

  // remove the y-axis labels
  svg.selectAll('.domain').remove();

  // create the bars
  const barGroups = svg.append('g')
    .attr('transform', `translate(${sideMargin}, 0)`)
    .selectAll('g')
    .data(data)
    .join('g')
    .attr('transform', (d) => `translate(0, ${yScale(d.childExtId) + (yScale.bandwidth() / 2) - 12})`);

  barGroups.append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', (d) => xScale(d.averageDailyBudgetAllocation))
    .attr('height', barHeight)
    .attr('fill', M300_FOG);

  barGroups.append('rect')
    .attr('x', 0)
    .attr('y', 10) // slight overlap
    .attr('width', (d) => xScale(d.averageDailyDelivery))
    .attr('height', barHeight)
    .attr('fill', N600_ABBEY);

  // add daily delivery labels to the bars
  barGroups.append('text')
    .attr('class', 'delivery-label-text')
    .each(({ averageDailyDelivery }, i, g) => {
      const textWithinBar = xScale(averageDailyDelivery) > 40;
      d3.select(g[i])
        .attr('x', xScale(averageDailyDelivery))
        .attr('y', 17) // center text vertically to the averageDailyDelivery bar
        .attr('x', xScale(averageDailyDelivery))
        .attr('text-anchor', textWithinBar ? 'end' : 'start')
        .style('fill', textWithinBar ? N0_WHITE : N800_MATTER)
        .attr('transform', `translate(${textWithinBar ? -5 : 5}, 0)`)
        .text(`${d3.format(averageDailyDelivery >= 1 ? '.2s' : '.2f')(averageDailyDelivery)}${i === 0 ? ` ${deliveryLabel}` : ''}`);
    });

  // add row border lines between each group
  svg.append('g')
    .attr('transform', `translate(${sideMargin}, 0)`)
    .selectAll('line')
    .data(data)
    .join('line')
    .attr('x1', 0)
    .attr('y1', (d) => yScale(d.childExtId))
    .attr('x2', VIZ_WIDTH)
    .attr('y2', (d) => yScale(d.childExtId))
    .attr('stroke-alignment', 'center');
};

type BudgetAllocationVizTableProps = {
  goal: string
  valueType: string
  childDisplayName: string
  data: Array<TopDeliveringChildWithGoal>
  deliveryLabel: string
  colorScale: d3.ScaleThreshold<number, string>
};

const BudgetAllocationVizTable = (props: BudgetAllocationVizTableProps) => {
  const { goal, valueType, childDisplayName, data, deliveryLabel, colorScale } = props;

  useMount(() => {
    buildViz(data, deliveryLabel);
  });

  return (
    <div className="budget-allocation-viz">
      <BudgetAllocationVizTableHeader
        goal={goal}
        topChildrenCount={_.size(data)}
        childDisplayName={childDisplayName}
      />
      <div className="budget-allocation-viz-table">
        <BudgetAllocationVizColumn
          className="budget-allocation-flight-col"
          width={FLIGHT_CELL_WIDTH}
          data={data}
          InnerComponent={FlightNameCell}
        />
        <div className="delivery-and-budget-allocated-chart" />
        <BudgetAllocationVizColumn
          className="budget-allocation-delivery-ratio-col"
          width={DELIVERY_RATIO_WIDTH + 1}
          data={data}
          InnerComponent={DeliveryRatioCell}
        />
        <BudgetAllocationVizColumn
          className="budget-allocation-goal-perf-col"
          width={GOAL_PERFORMANCE_WIDTH + 1}
          data={data}
          InnerComponent={GoalPerformanceCell}
          valueType={valueType}
          colorScale={colorScale}
        />
      </div>
    </div>
  );
};

export default BudgetAllocationVizTable;
