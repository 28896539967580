import React from 'react';
import { DeviceImageComponentProps } from '../types';

export default ({ x, y }: DeviceImageComponentProps) => (
  <svg width="108" height="157" viewBox="0 0 108 157" fill="none" xmlns="http://www.w3.org/2000/svg" x={x} y={y}>
    <path d="M97.5303 0.297363H9.51517C4.42431 0.297363 0.297363 4.42431 0.297363 9.51517V147.485C0.297363 152.576 4.42431 156.703 9.51517 156.703H97.5303C102.621 156.703 106.748 152.576 106.748 147.485V9.51517C106.748 4.42431 102.621 0.297363 97.5303 0.297363Z" stroke="black" strokeWidth="0.25" />
    <path d="M54.1176 8.56281C55.1029 8.56281 55.9017 7.76405 55.9017 6.77872C55.9017 5.79339 55.1029 4.99463 54.1176 4.99463C53.1323 4.99463 52.3335 5.79339 52.3335 6.77872C52.3335 7.76405 53.1323 8.56281 54.1176 8.56281Z" stroke="black" strokeWidth="0.25" />
    <path d="M54.7122 152.242C57.3397 152.242 59.4697 150.112 59.4697 147.485C59.4697 144.857 57.3397 142.727 54.7122 142.727C52.0846 142.727 49.9546 144.857 49.9546 147.485C49.9546 150.112 52.0846 152.242 54.7122 152.242Z" stroke="black" strokeWidth="0.25" />
    <path d="M100.801 14.0962H5.05469V138.388H100.801V14.0962Z" stroke="black" strokeWidth="0.25" />
  </svg>
);
