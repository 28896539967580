import _ from 'lodash';
import React from 'react';
import { DSP } from 'constantsBase';
import { COPILOT_MODELS, CopilotModels, INSIGHTS_IMG_PREFIX } from './constants';
import { getAdditionalClass } from './utils';
import { DspSummaryType } from './types';
import { pluralizeFlightName } from '../utils';

type DspSummaryProps = {
  dspInfo: DspSummaryType
  intelligentChildObjects: boolean
  viewabilityEnabled: boolean
  platformCount: number
};

const DspSummary = ({ dspInfo, intelligentChildObjects, viewabilityEnabled, platformCount }: DspSummaryProps) => {
  const { dsp, name, color, parentCount, lineItemCount, cloneCount, parentName, childName } = dspInfo;

  const badgesToDisplay = [
    ...intelligentChildObjects ? [COPILOT_MODELS[CopilotModels.intelligentLineItems]] : [],
    ...viewabilityEnabled ? [COPILOT_MODELS[CopilotModels.viewabilityControl]] : [],
    ...(dsp === DSP.TTD.id) ? [COPILOT_MODELS[CopilotModels.bidModeling]] : [],
  ];

  return (
    <div className={`dsp-summary-container ${getAdditionalClass(platformCount)}`}>
      <div className="dsp-summary-name" style={{ border: `${color} 2px solid`, ...(!dsp && { color }) }}>
        {name}
      </div>
      <div className="dsp-summary-flight-container parent">
        {parentCount}
        <span className="dsp-summary-flight-name">{parentName}{pluralizeFlightName(parentCount)}</span>
      </div>
      <img className="dsp-summary-arrow" src={`${INSIGHTS_IMG_PREFIX}dspSummaryArrow.svg`} alt="arrow-down" />
      <div className="dsp-summary-flight-container child">
        {lineItemCount}
        <span className="dsp-summary-flight-name">{childName}{pluralizeFlightName(lineItemCount)}</span>
        {!_.isEmpty(badgesToDisplay) && (
          <div className="dsp-summary-badge-container">
            {_.map(badgesToDisplay, ({ title, imageSrc }) => <img className="dsp-summary-badge" key={title} src={imageSrc} alt={title} />)}
          </div>
        )}
        <div className="dsp-summary-child originals">
          {lineItemCount - cloneCount} Original
        </div>
        {intelligentChildObjects && (
          <div className="dsp-summary-child clones">
            {cloneCount} Intelligent
            <img className="intelligent-icon" src={`${INSIGHTS_IMG_PREFIX}iliIcon.svg`} alt="intelligent-icon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DspSummary;
