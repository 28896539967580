import _ from 'lodash';
import { StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';

export type GroupedIsCloneDataType = {
  original: Array<StrategyGoalAnalyticsData>
  intelligent: Array<StrategyGoalAnalyticsData>
};

export type LastDayIsCloneDataType = {
  original: StrategyGoalAnalyticsData
  intelligent: StrategyGoalAnalyticsData
};

export const getGroupedIsCloneData = (isCloneData: Array<StrategyGoalAnalyticsData>) => (
  _.groupBy(isCloneData, ({ isClone }) => (isClone ? 'intelligent' : 'original')) as GroupedIsCloneDataType
);
