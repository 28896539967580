import React from 'react';
import { Segment } from 'buildingBlocks';

type ErrorSegmentProps = {
  error?: string
};

const ErrorSegment = ({ error }: ErrorSegmentProps) => (
  <Segment>{error ?? 'There was an error loading this content.'}</Segment>
);

export default ErrorSegment;
