export enum BudgetOptOverviewAggLevel {
  platform = 'platform',
  budgetGroup = 'budgetGroup',
  child = 'child',
}

export const MAX_ITEM_DISPLAY = 7;
export const MAX_CROSS_PLATFORM_TOTAL_LINE_THICKNESS = 150;
export const MAX_SINGLE_PLATFORM_TOTAL_LINE_THICKNESS = 105;
export const GROUP_CONTAINER_WIDTH = 303;
export const GROUP_CONTAINER_HEIGHT = 73;
export const GROUP_CONTAINER_LINE_MAX_THICKNESS = GROUP_CONTAINER_HEIGHT - 16;
export const SUB_CONTAINER_WIDTH = 107;
export const SUB_CONTAINER_HEIGHT = 30;
export const SUB_CONTAINER_LINE_MAX_THICKNESS = SUB_CONTAINER_HEIGHT - 8;
export const GROUP_TO_SUB_CONTAINER_LINE_LENGTH = 17;
export const MIN_LINE_THICKNESS = 2;
export const GOAL_PERFORMANCE_INDICATOR_WIDTH = 8;

export type BudgetOptOverviewVizDataType = {
  name: string
  origCount: number
  origDel: number
  origGoalPerf: number
  cloneCount: number
  cloneDel: number
  cloneGoalPerf: number
  delivery: number
  goalPerf: number
  dsp?: number
  isOverflow?: true
};

export const BudgetOptOverviewNotes = [
  'Visualizes how the Strategy budget was spent.',
  'Depending on your strategy settings the first break out is either line items, line items groups or buying platforms.',
  'If Intelligent Line Items was turned on, the second break out is Original line items and Intelligent Line Items.',
  'The width of the stems represents the volume of delivery and the coloured bars denote performance relative to the mean.',
  'It\'s a good sign when the groups with most spend are also the best performers.',
  'Where this doesn\'t happen it\'s usually due to scale limitations of the best performing lines.',
  'Dynamic Insights',
  'Budget updates: The number of budget adjustments highlights the amount of work Copilot has done on the strategy.',
  'Best performance: highlights the best performance seen.',
  'Highest delivery: highlights the item with highest delivery. In a perfect scenario, the item with best performance is the same as highest delivery, however this is not always possible due to restrictions on each line item.',
];
