import _ from 'lodash';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { DSP } from 'constantsBase';
import { ChildGroupType, StrategyGoalAnalyticsData, StrategyGoalAnalyticsDatum } from 'containers/StrategyAnalytics/types';
import { COPILOT_COLORS } from 'globalStyles';
import { INSIGHTS_IMG_PREFIX, SinglePlatformOptSummaryAggLevel } from './constants';
import { DspSummaryType, SinglePlatformOptSummaryDataType } from './types';
import { getChildDisplayName, getParentDisplayName } from '../utils';

const { NEW_DESIGN_SYSTEM: { DSP: dspColors, NEUTRALS } } = COPILOT_COLORS;

export const formatDspOptData = (
  dspToMostRecentCumData: { [key: number]: StrategyGoalAnalyticsDatum },
  attachments: Array<{ dsp: number }>,
  deliveryKpi: BudgetTypeMapping,
): Array<DspSummaryType> => {
  const sortedDspOptData = _.orderBy(
    _.map(dspToMostRecentCumData, (perfData, dsp) => {
      const { id: dspId, displayName } = DSP.getById(dsp);
      const flights = _.filter(attachments, ['dsp', dspId]);
      const lineItems = _.flatMap(flights, 'children');
      return {
        dsp: dspId,
        name: displayName,
        delivery: perfData[deliveryKpi],
        parentCount: _.size(flights),
        lineItemCount: _.size(lineItems),
        cloneCount: _.size(_.filter(lineItems, 'isClone')),
        color: dspColors[dsp].dark,
        parentName: getParentDisplayName(dspId),
        childName: getChildDisplayName(dspId),
      };
    }),
    'delivery',
    'desc',
  );

  if (_.size(sortedDspOptData) <= 4) {
    return sortedDspOptData;
  }

  // if more than four DSPs then show the top 3 and combine remaining
  const topThreePerformingDsps = _.slice(sortedDspOptData, 0, 3);
  const combinedRemainingDsps = _.slice(sortedDspOptData, 3);
  const remainingDspObj = {
    dsp: null,
    name: `+${_.size(combinedRemainingDsps)} Platforms`,
    color: NEUTRALS.N400_GRANITE,
    delivery: _.sumBy(combinedRemainingDsps, 'delivery'),
    parentCount: _.sumBy(combinedRemainingDsps, 'parentCount'),
    lineItemCount: _.sumBy(combinedRemainingDsps, 'lineItemCount'),
    cloneCount: _.sumBy(combinedRemainingDsps, 'cloneCount'),
    parentName: 'Insertion Order',
    childName: 'Line Item',
  };
  return [...topThreePerformingDsps, remainingDspObj];
};

export const getCrossPlatformOptSummaryArrow = (platformCount: number) => {
  switch (platformCount) {
    case 4:
      return `${INSIGHTS_IMG_PREFIX}fourPlatformOptArrow.svg`;
    case 3:
      return `${INSIGHTS_IMG_PREFIX}threePlatformOptArrow.svg`;
    case 2:
      return `${INSIGHTS_IMG_PREFIX}twoPlatformOptArrow.svg`;
    default:
      return `${INSIGHTS_IMG_PREFIX}dspSummaryArrow.svg`;
  }
};

export const getAdditionalClass = (platformCount: number) => {
  switch (platformCount) {
    case 4:
      return 'four';
    case 3:
      return 'three';
    default:
      return '';
  }
};

export const buildSinglePlatformOptSummaryVizData = (
  lastDayOfChildData: { [childExtId: string]: StrategyGoalAnalyticsData },
  deliveryKpi: BudgetTypeMapping,
  hasBudgetGroups: boolean,
  origToName: { [childExtId: string]: string },
  origToClone: { [childExtId: string]: string },
  childGroups?: Array<ChildGroupType>,
  childToGroupId?: { [childExtId: string]: string },
): Array<SinglePlatformOptSummaryDataType> => {
  const origAndCloneDel = _.reduce(origToName, (res, name: string, origId: string) => {
    const origDel = _.get(lastDayOfChildData, `${origId}.${deliveryKpi}`, 0) as number;
    const cloneExtId = _.get(origToClone, origId);
    const cloneDel = _.get(lastDayOfChildData, `${cloneExtId}.${deliveryKpi}`, 0) as number;
    res[origId] = {
      aggregation: SinglePlatformOptSummaryAggLevel.lineItem,
      name,
      origCount: 1,
      cloneCount: cloneExtId ? 1 : 0,
      delivery: origDel + cloneDel,
    };
    return res;
  }, {});

  // if there are no budgetGroups or single budget group return line items ordered by orig and clone delivery
  if (!hasBudgetGroups || _.size(childGroups) === 1) {
    return _.orderBy(_.values(origAndCloneDel), 'delivery', 'desc');
  }

  const originalChildIds = _.keys(origToName);
  return _.orderBy(
    _.reduce(childGroups, (res, { groupId, groupName }) => {
      const childrenInGroup = _.map(
        _.filter(originalChildIds, (extId: string) => childToGroupId[extId] === groupId),
        (extId: string) => origAndCloneDel[extId],
      );
      return [
        ...res,
        {
          aggregation: SinglePlatformOptSummaryAggLevel.budgetGroup,
          name: groupName,
          origCount: _.sumBy(childrenInGroup, 'origCount'),
          cloneCount: _.sumBy(childrenInGroup, 'cloneCount'),
          delivery: _.sumBy(childrenInGroup, 'delivery'),
        },
      ];
    }, []),
    'delivery',
    'desc',
  );
};
