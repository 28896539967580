import _ from 'lodash';
import React from 'react';
import { CreativeDatumWithPosition, Devices } from 'charts/InsightsViz/types';
import { DEVICE_GRAPHIC } from 'charts/InsightsViz/constants';
import SingleDeviceViz from './SingleDeviceViz';

type Props = { creativeData: Array<CreativeDatumWithPosition & { fill: string }> };

export default ({ creativeData }: Props) => {
  const creativeDataByDevice = _.groupBy(creativeData, 'deviceType');
  const mobileData = creativeDataByDevice[Devices.PHONE];
  const desktopData = creativeDataByDevice[Devices.LAPTOP_OR_PC];
  const tabletData = creativeDataByDevice[Devices.TABLET];
  const ctvData = creativeDataByDevice[Devices.CTV];
  return (
    <div id="device-container">
      { (_.size(mobileData) > 0) && (
        <SingleDeviceViz
          deviceConfig={DEVICE_GRAPHIC[Devices.PHONE]}
          data={mobileData}
        />
      )}
      { (_.size(desktopData) > 0) && (
        <SingleDeviceViz
          deviceConfig={DEVICE_GRAPHIC[Devices.LAPTOP_OR_PC]}
          data={desktopData}
        />
      )}
      { (_.size(tabletData) > 0) && (
        <SingleDeviceViz
          deviceConfig={DEVICE_GRAPHIC[Devices.TABLET]}
          data={tabletData}
        />
      )}
      { (_.size(ctvData) > 0) && (
        <SingleDeviceViz
          deviceConfig={DEVICE_GRAPHIC[Devices.CTV]}
          data={ctvData}
        />
      )}
    </div>
  );
};
