import * as d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { DSP } from 'constantsBase';
import { StrategyGoalAnalyticsAttachmentType } from 'containers/StrategyAnalytics/types';
import { COPILOT_COLORS } from 'globalStyles';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import {
  BADGE_HEIGHT, BADGE_WIDTH, BOTTOM_RIGHT_IDX, CHILD_RECT_HEIGHT, CHILD_RECT_WIDTH, COL_GAP, COPILOT_MODELS, CopilotModels,
  INSIGHTS_IMG_PREFIX, MAX_ARROW_DISPLAY, PARENT_RECT_HEIGHT, PARENT_RECT_WIDTH, ROW_GAP, SINGLE_PLATFORM_OPT_VIZ_HEIGHT,
  SINGLE_PLATFORM_OPT_VIZ_WIDTH, SinglePlatformOptSummaryAggLevel, singlePlatformChildDirectionOrder,
} from './constants';
import { SinglePlatformOptSummaryDataType } from './types';
import { buildSinglePlatformOptSummaryVizData } from './utils';
import { useInsightsContext } from '../contexts/InsightsProvider';
import {
  getChildDisplayName, getLastDayOfDataByKey, getParentDisplayName, getTextLinesWithEllipsis, getFullWordTextLinesWithEllipsis,
  hasBudgetGroupsCheck, getOrigChildIdToName, mouseOver, mouseMove, mouseOut, createTooltip,
} from '../utils';

const buildViz = (
  parentFlight: { dsp: number, name: string, parentName: string },
  data: Array<SinglePlatformOptSummaryDataType>,
  intelligentChildObjects: boolean,
  viewabilityEnabled: boolean,
) => {
  const dsp = parentFlight.dsp;
  const containsDataOverflow = _.size(data) > MAX_ARROW_DISPLAY;
  const badgesToDisplay = [
    ...intelligentChildObjects ? [COPILOT_MODELS[CopilotModels.intelligentLineItems].imageSrc] : [],
    ...viewabilityEnabled ? [COPILOT_MODELS[CopilotModels.viewabilityControl].imageSrc] : [],
    ...(dsp === DSP.TTD.id) ? [COPILOT_MODELS[CopilotModels.bidModeling].imageSrc] : [],
  ];
  const isBudgetGroup = _.head(data)?.aggregation === SinglePlatformOptSummaryAggLevel.budgetGroup;
  const childObjLabel = isBudgetGroup ? 'Group' : getChildDisplayName(parentFlight.dsp);

  // reorganize direction order if LI/LI groups is more than eight
  const childDirectionOrder = containsDataOverflow
    ? [..._.without(singlePlatformChildDirectionOrder, singlePlatformChildDirectionOrder[BOTTOM_RIGHT_IDX]), singlePlatformChildDirectionOrder[BOTTOM_RIGHT_IDX]]
    : singlePlatformChildDirectionOrder;

  const vizContainer = d3.select('.single-platform-opt-summary-container');
  const svgContainer = d3.select('.single-platform-opt-summary-container svg');

  // add LI/child group objs
  svgContainer
    .selectAll('.child-obj')
    .data(containsDataOverflow ? _.take(data, MAX_ARROW_DISPLAY - 1) : data) // only use top 7 objs if there is overflow
    .join((enter) => enter
      .append('g')
      .attr('class', 'child-obj')
      .attr('width', CHILD_RECT_WIDTH)
      .attr('height', CHILD_RECT_HEIGHT)
      .attr('transform', (_d, i) => {
        const [xUnits, yUnits] = childDirectionOrder[i].grid;
        const translateX = (xUnits * CHILD_RECT_WIDTH) + (xUnits * COL_GAP);
        const translateY = (yUnits * CHILD_RECT_HEIGHT) + (yUnits * ROW_GAP);
        return `translate(${translateX}, ${translateY})`;
      }))
    .each(({ name, origCount, cloneCount }, i, svg) => {
      const childObjGroup = d3.select(svg[i]);
      childObjGroup.append('rect')
        .attr('width', CHILD_RECT_WIDTH)
        .attr('height', CHILD_RECT_HEIGHT);

      childObjGroup
        .append('g')
        .append('text')
        .attr('class', 'child-obj-agg')
        .text(childObjLabel);

      childObjGroup
        .append('g')
        .attr('class', 'child-obj-name')
        .attr('id', `child-${i}`)
        .selectAll('.chunk')
        .data(getTextLinesWithEllipsis(name, 47, 2))
        .join((chunk) => chunk.append('text')
          .attr('class', 'chunk')
          .text((c) => _.join(c, ''))
          .attr('transform', (_d, idx) => `translate(16, ${(idx * 11.5) + 36})`));

      // add tooltip if child obj name is truncated
      if (_.size(name) > (47 * 2)) {
        const childObjTooltip = createTooltip(vizContainer, name);

        d3.select(`g#child-${i}`)
          .on('mouseover', () => mouseOver(childObjTooltip))
          .on('mousemove', () => mouseMove(vizContainer, childObjTooltip))
          .on('mouseout', () => mouseOut(childObjTooltip));
      }

      // add original label
      const orig = childObjGroup
        .append('g')
        .attr('class', 'orig');

      orig
        .append('rect')
        .attr('width', intelligentChildObjects ? 116 : 248);

      orig.append('text')
        .text(`${isBudgetGroup ? `${origCount} ` : ''}Original`);

      orig
        .selectAll('.child-obj-badges')
        .data(_.filter(badgesToDisplay, (imgSrc) => !(imgSrc === COPILOT_MODELS[CopilotModels.intelligentLineItems].imageSrc))) // omit intelligent clone badge on orig-label
        .enter()
        .append('image')
        .attr('class', 'child-obj-badges')
        .attr('xlink:href', (imgSrc) => imgSrc)
        .attr('transform', (_d, idx) => `translate(${8 + ((BADGE_WIDTH + 2) * idx)}, 8)`);

      // add intelligent clone label
      if (intelligentChildObjects) {
        const clone = childObjGroup
          .append('g')
          .attr('class', 'clone');

        clone.append('rect')
          .attr('width', 124);

        clone.append('text')
          .text(`${isBudgetGroup ? `${cloneCount} ` : ''}Intelligent`);

        clone
          .selectAll('.child-obj-badges')
          .data(badgesToDisplay)
          .enter()
          .append('image')
          .attr('class', 'child-obj-badges')
          .attr('xlink:href', (imgSrc) => imgSrc)
          .attr('transform', (_d, idx) => `translate(${8 + ((BADGE_WIDTH + 2) * idx)}, 8)`);

        clone.append('image')
          .attr('class', 'intelligentIcon')
          .attr('xlink:href', `${INSIGHTS_IMG_PREFIX}iliIcon.svg`);
      }
    });

  // add the connector arrows
  svgContainer
    .selectAll('.opt-summary-arrow')
    .data(containsDataOverflow ? _.take(data, MAX_ARROW_DISPLAY) : data) // display eight arrows if there is overflow
    .enter()
    .append('svg')
    .attr('class', 'opt-summary-arrow')
    .each((_d, i, svg) => {
      const connectorSvg = d3.select(svg[i]);
      connectorSvg
        .append('path')
        .attr('d', childDirectionOrder[i].path)
        .attr('transform', () => {
          const { width: svgWidth, height: svgHeight } = connectorSvg.node().getBBox();
          const { childRectsOver, columnsOver, childRectsDown, rowsDown, widthDiff, heightDiff } = childDirectionOrder[i].calculations;
          const x = (childRectsOver * CHILD_RECT_WIDTH) + (columnsOver * COL_GAP) - (widthDiff * svgWidth);
          const y = (childRectsDown * CHILD_RECT_HEIGHT) + (rowsDown * ROW_GAP) - (heightDiff * svgHeight);
          return `translate(${x},${y})`;
        })
        .attr('fill', COPILOT_COLORS.NEW_DESIGN_SYSTEM.YELLOWS.Y400_INSIGHTS_ORANGE);
    });

  // add parent obj
  const parentObj = svgContainer
    .selectAll('.parent-obj')
    .data([parentFlight])
    .join((enter) => enter
      .append('g')
      .attr('class', 'parent-obj')
      .attr('width', PARENT_RECT_WIDTH)
      .attr('height', PARENT_RECT_HEIGHT)
      .attr('transform', `translate(${(SINGLE_PLATFORM_OPT_VIZ_WIDTH - PARENT_RECT_WIDTH) / 2}, ${(SINGLE_PLATFORM_OPT_VIZ_HEIGHT - PARENT_RECT_HEIGHT) / 2})`));

  parentObj.append('rect')
    .attr('width', PARENT_RECT_WIDTH)
    .attr('height', PARENT_RECT_HEIGHT)
    .attr('filter', 'url(#single-platform-opt-summary-shadow)');

  parentObj.append('svg:image')
    .attr('xlink:href', COPILOT_MODELS.budgetOptimization.imageSrc)
    .attr('transform', `translate(${(PARENT_RECT_WIDTH - BADGE_WIDTH) / 2}, 16)`);

  const parentExtTypeText = parentObj.append('text')
    .attr('class', 'parent-ext-type')
    .text(parentFlight.parentName);
  const { width: parenExtTypeWidth, height: parenExtTypeHeight } = parentExtTypeText.node().getBBox();
  parentExtTypeText.attr('transform', () => `translate(${(PARENT_RECT_WIDTH - parenExtTypeWidth) / 2}, ${24 + BADGE_HEIGHT})`);

  parentObj.append('g')
    .attr('class', 'parent-name')
    .selectAll('.chunk')
    .data(getTextLinesWithEllipsis(parentFlight.name, 25, 4))
    .join((chunk) => chunk.append('text')
      .attr('class', 'chunk')
      .text((c) => c))
    .attr('transform', (_d, i) => `translate(16, ${(i * 13) + (parenExtTypeHeight + BADGE_HEIGHT + 32)})`);

  // add tooltip if parent name is truncated
  if (_.size(parentFlight.name) > (25 * 4)) {
    const parentNameTooltip = createTooltip(vizContainer, parentFlight.name);

    d3.select('g.parent-name')
      .on('mouseover', () => mouseOver(parentNameTooltip))
      .on('mousemove', () => mouseMove(vizContainer, parentNameTooltip))
      .on('mouseout', () => mouseOut(parentNameTooltip));
  }

  // group the remaining LI/child groups if there is overflow
  if (containsDataOverflow) {
    const direction = _.last(childDirectionOrder);
    const overflowData = _.drop(data, MAX_ARROW_DISPLAY - 1);

    const overflowContainer = svgContainer.append('g')
      .attr('class', 'child-obj')
      .attr('transform', () => {
        const [xUnits, yUnits] = direction.grid;
        const translateX = (xUnits * CHILD_RECT_WIDTH) + (xUnits * COL_GAP);
        const translateY = (yUnits * CHILD_RECT_HEIGHT) + (yUnits * ROW_GAP);
        return `translate(${translateX}, ${translateY})`;
      });

    overflowContainer.append('rect')
      .attr('width', CHILD_RECT_WIDTH)
      .attr('height', CHILD_RECT_HEIGHT);

    overflowContainer.append('text')
      .attr('class', 'overflow-title')
      .text(`+ ${_.size(overflowData)} ${childObjLabel}s`);

    const overflowCountWithLabel = isBudgetGroup
      ? `${_.size(overflowData)} ${childObjLabel}s`
      : `${_.sumBy(overflowData, 'origCount')} Original${intelligentChildObjects ? ` ${_.sumBy(overflowData, 'cloneCount')} Intelligent` : ''} ${childObjLabel}s`;

    const overflowText = `* This view supports a maximum of 7 ${childObjLabel}s. Copilot additionally managed optimization on ${overflowCountWithLabel}.`;

    overflowContainer.append('g')
      .selectAll('.overflow-chunk')
      .data(getFullWordTextLinesWithEllipsis(overflowText, 43, 4))
      .join((chunk) => chunk.append('text')
        .attr('class', 'overflow-chunk')
        .text((c) => c))
      .attr('transform', (_d, i) => `translate(16, ${(i * 12) + 40})`);
  }
};

const SinglePlatformOptSummary = () => {
  const {
    metadata: {
      attachments,
      budgetSettings,
      budgetOptConfig: { childGroups, childToGroupId },
      copilot: { origToClone },
    },
    viewabilityGoal,
    strategy: { config: { intelligentChildObjects } },
    budgetType,
    childData,
  } = useInsightsContext();

  const hasBudgetGroups = hasBudgetGroupsCheck(childGroups);
  const flight = _.head(attachments as Array<StrategyGoalAnalyticsAttachmentType>);
  const { children, dsp } = flight;
  const parentFlight = {
    dsp,
    name: _.get(_.head(budgetSettings), 'parentSettings.name', ''),
    parentName: getParentDisplayName(dsp),
  };

  useMount(() => {
    buildViz(
      parentFlight,
      buildSinglePlatformOptSummaryVizData(
        getLastDayOfDataByKey(childData.cumData, 'childExtId'),
        BudgetTypeMapping[budgetType],
        hasBudgetGroups,
        getOrigChildIdToName(children),
        origToClone,
        childGroups,
        childToGroupId,
      ),
      intelligentChildObjects,
      !!viewabilityGoal,
    );
  });

  return (
    <div className="single-platform-opt-summary-container">
      <svg className="single-platform-opt-summary-viz">
        <defs>
          <filter id="single-platform-opt-summary-shadow">
            <feDropShadow dx="0" dy="4" stdDeviation="8" floodColor="hsla(0,0%,0%,0.13)" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default SinglePlatformOptSummary;
