import React, { ComponentType, useMemo } from 'react';
import _ from 'lodash';
import { HSLColor, RGBColor } from 'd3';
import { COLORS } from 'charts/constants';
import { ModelName } from './ModelBadgeAndDescription';

type InputProps = {
  modelName: ModelName
};

export type OutputProps = {
  linearGradientId: string
  startColor: string
  stopColor: string | RGBColor | HSLColor
};

const withRenderData = (WrappedComponent: ComponentType<OutputProps>) => (props: InputProps) => {
  const { modelName, ...passThroughProps } = props;

  const { START, STOP } = COLORS.MODELS[modelName];

  const linearGradientId = useMemo(() => `linearGradient-${_.replace(modelName, /\s/g, '')}`, [modelName]);

  return (
    <WrappedComponent
      linearGradientId={linearGradientId}
      startColor={START}
      stopColor={STOP}
      {...passThroughProps}
    />
  );
};

export default withRenderData;
