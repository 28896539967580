import React from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import { format, rgb, ScaleThreshold } from 'd3';
import { FeatureInsightsDatum, OrigCloneMapping } from 'charts/InsightsViz/types';
import { COLORS } from 'charts/constants';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { ListType } from '.';
import ChildItemListRow from './ChildItemListRow';
import FeatureComboListRow from './FeatureComboListRow';

type CumulativeDatum = any;

export type BaseListRowProps = {
  datum: { name: string } & CumulativeDatum & FeatureInsightsDatum
  delivery: string
  perfStyle: { [key: string]: string }
  formattedPerfVal: string
};

type CommonListRowProps = {
  datum: CumulativeDatum & { name: string } & FeatureInsightsDatum
  budgetType: string
  goalMetricConfig: Metric & { target: number }
  colorScale: ScaleThreshold<number, string>
  primaryGoalSuccessEvent: string
  features?: Array<string>
};

type ChildItemListRowProps = CommonListRowProps & {
  type: ListType.child
  labels: { [childExtId: string]: string }
  cloneToOrig: OrigCloneMapping
};

type FeatureComboListRowProps = CommonListRowProps & {
  type: ListType.featureCombo
};

const ListRow = (props: ChildItemListRowProps | FeatureComboListRowProps) => {
  const { datum,
    budgetType,
    goalMetricConfig,
    colorScale,
    primaryGoalSuccessEvent,
    features,
  } = props;

  const noSuccessEvents = !datum[primaryGoalSuccessEvent]; // 0 or otherwise falsey

  const performanceVal = (noSuccessEvents)
    ? null
    : _.get(datum, goalMetricConfig.value, 0);

  const formattedPerfVal = noSuccessEvents ? 'N/A' : numeral(performanceVal).format(goalMetricConfig.format);

  const fillColor = noSuccessEvents
    ? COLORS.GREYS.NO_DATA
    : colorScale(performanceVal);

  const { r, g, b } = rgb(fillColor);
  const perfStyle = {
    backgroundColor: `rgba(${r},${g},${b},0.6)`,
    border: `1.5px solid ${fillColor}`,
  };

  // 2 sig figs
  const numFormat = format('.2s');
  const delivery = numFormat(_.get(datum, budgetType, 0));

  const commonProps = { datum, delivery, perfStyle, formattedPerfVal };
  return (props.type === ListType.child)
    ? (
      <ChildItemListRow
        {...commonProps}
        labels={props.labels}
        cloneToOrig={props.cloneToOrig}
      />
    )
    : (
      <FeatureComboListRow
        {...commonProps}
        features={features}
      />
    );
};

export default ListRow;
