import {
  ScaleThreshold, forceSimulation, forceX, forceY, timeout, SimulationNodeDatum,
  scaleThreshold, deviation, max, Selection, mouse, BaseType,
} from 'd3';
import { bboxCollide } from 'd3-bboxCollide';
import _ from 'lodash';
import { compose } from 'lodash/fp';
import moment from 'moment';
import numeral from 'numeral';
import { Dispatch, SetStateAction } from 'react';
import { BudgetOptDataPacingDatum } from 'charts/BudgetOptimizationViz/types';
import { COLORS as CHART_COLORS, COLORS_PINK_TO_GREEN } from 'charts/constants';
import { getColorScaleDomain, getValForZScore } from 'charts/utils';
import { FLIGHT_EXTERNAL_TYPE, DSP, GOAL_TYPES, GOAL_VALUE_TYPE, STRATEGY_TYPE } from 'constantsBase';
import { Metric, COST_BASED_GOALS, RATE_BASED_GOALS } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { analyticsDesnakifyFunc } from 'containers/StrategyAnalytics/utils/metricsUtils';
import { BudgetTypes, DEFAULT_GROUP_ID } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { AggregationLevel, STRATEGY_GOAL_ANALYTICS_MICROSERVICE } from 'containers/StrategyAnalytics/constants/strategyAnalyticsConstants';
import { ChildGroupType, StrategyGoalAnalyticsAttachmentChild, StrategyGoalAnalyticsAttachmentType, StrategyGoalAnalyticsData, StrategyGoalAnalyticsDatum, StrategyGoalAnalyticsGoalType } from 'containers/StrategyAnalytics/types';
import { TRUE_VIEW } from 'containers/StrategyWizard/constants';
import useFetcher, { PossibleStates, State } from 'utils/hooks/useFetcher';
import { DBMLineItem, Microservices } from 'utils/copilotAPI';
import desnakify from 'utils/desnakify';
import { GoalType, Strategy, StrategyGoalsDB } from 'utils/types';
import {
  VizId, VALUE_PRECISION, CURRENCY_FORMAT, PERCENTAGE_FORMAT, ROUNDED_PERCENTAGE_FORMAT, CURRENCY_OR_PERCENTAGE_PRECISION,
  FeatureInsightsDatavizType, MAX_ITERATIONS, Status, POLL_DELAY_TIME_MS, MAX_CACHE_AGE,
} from './constants';
import {
  OrigCloneMapping, Metadata, MetaDatum, CumulativeDailyDatum, BudgetType, CreativeDatum, CreativeDatumWithPosition, DeviceConfig,
  GeoInsightsDatum, Placements, PrimaryStrategyGoal, ViewabiltyGoal, InsightsState, PossibleInsightsStates, TaskStatus,
  SlideErrors,
} from './types';
import { processInsightsDataForDisplay } from './StatBoxes/utils';
import { statBoxesConfig } from './StatBoxes/config';
import { RegionDatum } from './GeoInsights';
import { getOrderedStratGoalsWithRevenueTypes, InsightsGoal } from './transforms';

export const getInsightsByVizId = (vizId: VizId, data: any) => {
  const processingFn = processInsightsDataForDisplay[vizId];
  const config = statBoxesConfig[vizId];
  if (processingFn) {
    const processedData = processingFn(data);
    return { config, data: processedData };
  }
  return { config, data };
};

export const truncateText = (text: string, maxChars: number) => {
  if (_.size(text) <= maxChars) {
    return text;
  }
  return `${_.join(_.take(text, maxChars - 3), '')}...`;
};

export const getTextLinesWithEllipsis = (text: string, charsPerLine: number, maxLines?: number) => {
  const chunks = _.chunk(text, charsPerLine);
  let lines = chunks;
  if (maxLines && (_.size(chunks) > maxLines)) {
    const lastChunk = chunks[maxLines - 1];
    lines = [..._.take(chunks, maxLines - 1), [..._.slice(lastChunk, 0, _.size(lastChunk) - 3), '...']];
  }
  return _.map(lines, (l) => _.join(l, ''));
};

export const getFullWordTextLinesWithEllipsis = (text: string, charsPerLine: number, maxLines?: number) => {
  const fullWords = _.split(text, ' ');
  let currentChunk = '';
  const chunkedText = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < fullWords.length; i++) {
    const currentWord = fullWords[i];
    const newLength = currentWord.length + currentChunk.length + 1;
    if (newLength >= charsPerLine) {
      chunkedText.push(currentChunk.trim());
      currentChunk = '';
      if (chunkedText.length > maxLines) {
        break;
      }
    }
    currentChunk += `${currentWord} `;
  }

  if (currentChunk.trim()) {
    chunkedText.push(currentChunk.trim());
  }

  if (maxLines && chunkedText.length > maxLines) {
    const textWithinMaxLines = chunkedText.slice(0, maxLines);
    return textWithinMaxLines.map((str, idx) => {
      if (idx === maxLines - 1) {
        return `${str.slice(0, -3)}...`;
      }
      return str;
    });
  }

  return chunkedText;
};

export const getSetSlideError = (slideId: VizId, setSlideErrors: Dispatch<SetStateAction<SlideErrors>>) => () => {
  setSlideErrors((prevState) => (!prevState[slideId] ? { ...prevState, [slideId]: true } : prevState));
};

export const hasIntOptOverviewData = (intelligentOptDataFetchState): boolean => !_.isNil(_.get(intelligentOptDataFetchState, 'data.performanceViz.intelligent'));

export type UsableMetadata = {
  childExtTypeDisplayName: string
  childrenMetadata: Array<MetaDatum>
  parentExtTypeDisplayName: string
  parentMetadata: MetaDatum
  origToClone: OrigCloneMapping
  cloneToOrig: OrigCloneMapping
  primaryGoal: InsightsGoal
  secondaryGoal: InsightsGoal
  budgetType: BudgetType
  bidOptEnabled: boolean
  budgetOptEnabled: boolean
  intelligenceOptEnabled: boolean
  viewabilityOptEnabled: boolean
  colorScaleRange: Array<string>
  strategyGoals: StrategyGoalsDB,
  currency: string
  primaryGoalSuccessEvent: string
  primaryGoalOverallValue: number
  dsp: number
  hasRevenueType: boolean
  statBoxes: any
  revenueType: string
  features?: Array<string>
  lowerIsBetter?: boolean
};

export const extractMetadata = (metadata: Metadata): UsableMetadata => {
  const {
    childExtType,
    children,
    parentExtType,
    parent,
    origToClone,
    strategy,
    budgetType,
    currency,
    statBoxes,
    features,
    dsp,
  } = metadata;
  const {
    strategyGoals,
    clientEventRevenueValue,
    revenueType,
    budgetOptEnabled,
    intelligenceOptEnabled,
    bidOptEnabled,
    viewabilityOptEnabled,
  } = strategy;
  const { primaryGoalSuccessEvent, primaryGoalOverallValue } = statBoxes;
  const childType = FLIGHT_EXTERNAL_TYPE.getById(childExtType);
  const parentType = FLIGHT_EXTERNAL_TYPE.getById(parentExtType);
  const cloneToOrig = _.invert(origToClone);
  const [primaryGoal, secondaryGoal] = getOrderedStratGoalsWithRevenueTypes(strategyGoals, revenueType, clientEventRevenueValue);
  // should always be from small to large
  const colorScaleRange = primaryGoal.lowerIsBetter ? _.reverse([...COLORS_PINK_TO_GREEN]) : COLORS_PINK_TO_GREEN;
  return {
    childExtTypeDisplayName: _.get(childType, 'wizardDisplayName'),
    childrenMetadata: children,
    parentExtTypeDisplayName: _.get(parentType, 'wizardDisplayName'),
    parentMetadata: parent,
    origToClone,
    cloneToOrig,
    primaryGoal,
    secondaryGoal,
    budgetType,
    budgetOptEnabled,
    bidOptEnabled,
    intelligenceOptEnabled,
    viewabilityOptEnabled,
    colorScaleRange,
    strategyGoals,
    currency,
    primaryGoalSuccessEvent: _.get(primaryGoalSuccessEvent, 'name'),
    primaryGoalOverallValue,
    features,
    dsp,
    statBoxes,
    hasRevenueType: !_.isNil(revenueType) && !_.isNil(clientEventRevenueValue),
    revenueType,
  };
};

export const isRateBasedGoal = (goalType: string): boolean => _.includes(RATE_BASED_GOALS, goalType);
export const isCostBasedGoal = (goalType: string): boolean => _.includes(COST_BASED_GOALS, goalType);

export const findIntersectionOnChart = (
  prevX: number, /** previous X coordinate value */
  currX: number, /** current X coordinate value */
  prevY1: number, /** previous Y coordinate value of Line 1 */
  currY1: number, /** current Y coordinate value of Line 1 */
  prevY2: number, /** previous Y coordinate value of Line 2 */
  currY2: number, /** current Y coordinate value of Line 2 */
): number => {
  const diffY2 = currY2 - prevY2;
  const diffY1 = currY1 - prevY1;
  const diffXY2 = (currX * prevY2) - (prevX * currY2);
  const diffXY1 = (currX * prevY1) - (prevX * currY1);
  return (diffXY1 - diffXY2) / (diffY2 - diffY1);
};

export const displayBudgetType = (budgetType: BudgetType, currency?: string, displayCurrency: boolean = false) => ((budgetType === BudgetType.advRevenue)
  ? `Revenue ${displayCurrency ? `(${currency})` : ''}` : 'Impressions');

const pixelToCoord = (
  pixel: number,
  pixelWidth: number,
  sectionWidth: number,
) => (pixel / pixelWidth) * sectionWidth;

const reflectYCoor = (
  yCoord: number,
  sectionHeight: number,
  placement: string,
) => (placement === Placements.BELOW ? sectionHeight - yCoord : yCoord);

export const getPolyColor = (
  creative: CreativeDatum,
  selectedKPIValue: string,
  colorScale: ScaleThreshold<number, string>,
  primaryGoalSuccessEvent: string,
) => {
  if (creative[primaryGoalSuccessEvent] < 1) {
    return CHART_COLORS.GREYS.NO_DATA;
  }
  return creative[selectedKPIValue] ? colorScale(creative[selectedKPIValue]) : CHART_COLORS.GREYS.NO_DATA;
};

export const getCreativePositionAndDimensions = (creative: CreativeDatumWithPosition, deviceConfig: DeviceConfig) => {
  const { imageWidth, imageHeight, screenMargins, pixelWidth } = deviceConfig;

  const placementText = creative.foldPosition;

  const screenWidth = imageWidth - screenMargins.left - screenMargins.right;

  const x1 = pixelToCoord(creative.x, pixelWidth, screenWidth);
  const x2 = pixelToCoord(creative.x + creative.width, pixelWidth, screenWidth);

  const innerScreenHeight = imageHeight - screenMargins.top - screenMargins.bottom;

  const y1 = reflectYCoor(pixelToCoord(creative.y, pixelWidth, screenWidth), innerScreenHeight / 2, placementText);
  const y2 = reflectYCoor(pixelToCoord(creative.y + creative.height, pixelWidth, screenWidth), innerScreenHeight / 2, placementText);

  return {
    x: Math.min(x1, x2),
    y: Math.min(y1, y2),
    width: Math.abs(x2 - x1),
    height: Math.abs(y2 - y1),
  };
};

export const getColorLegendLabel = (strategyGoalConfig: Metric & Partial<GoalType> & { target?: number }, currencyCode: string) => {
  const { value, insightsLabel, text } = strategyGoalConfig;
  const displayLabel = insightsLabel || text;
  return isCostBasedGoal(value) ? `${displayLabel} (${currencyCode})` : displayLabel;
};

// sleep time expects milliseconds
// eslint-disable-next-line no-promise-executor-return
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

// eslint-disable-next-line consistent-return
export const pollInsightsMicroservice = async (taskId: string) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < MAX_ITERATIONS; i++) { /* eslint-disable no-await-in-loop */
    const { data: { status, error } }: { data: TaskStatus } = await Microservices.getTaskStatus(taskId);
    if (status === Status.error) {
      throw new Error(error);
    } else if (status === Status.complete) {
      const { data } = await Microservices.getTaskResult(taskId);
      return data;
    }
    await sleep(POLL_DELAY_TIME_MS);
  }
};

export const useIntelligentOptVizDataFetcher = (
  strategyId: number,
  flightId: number,
  hasIntelligence: boolean,
  isCrossPlatformOrAmznOrWalmart: boolean,
): InsightsState => {
  if (!hasIntelligence || isCrossPlatformOrAmznOrWalmart) {
    return { kind: PossibleInsightsStates.willNotFetch };
  }
  const fetchData = async (): Promise<State> => {
    const { data: { id } } = await Microservices.runService(
      {
        strategy_id: strategyId,
        flight_id: flightId,
        max_cache_age: MAX_CACHE_AGE,
      },
      'insights_int_opt',
    );
    const data = await pollInsightsMicroservice(id);
    return { kind: PossibleStates.hasData, data };
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useFetcher(fetchData, [strategyId, flightId]);
};

export const useFeatureInsightsVizDataFetcher = (
  strategyId: number,
  flightId: number,
  dataVizType: FeatureInsightsDatavizType,
  isCrossPlatformOrAmznOrWalmart: boolean,
) => {
  const fetchData = async (): Promise<InsightsState> => {
    if (isCrossPlatformOrAmznOrWalmart) {
      return { kind: PossibleInsightsStates.willNotFetch };
    }
    const baseRequestBody = {
      strategy_id: strategyId,
      flight_id: flightId,
      type: dataVizType,
      max_cache_age: MAX_CACHE_AGE,
    };
    const requestBody = baseRequestBody;
    const { data: { id } } = await Microservices.runService(requestBody, 'insights_features');
    const data = await pollInsightsMicroservice(id);
    if (_.get(data, 'geoJSON[0].metadata.key') === 'India-geo-projected') {
      return { kind: PossibleStates.error, errorObject: new Error('India geo insights currently unsupported. (No matching country)') };
    }
    return { kind: PossibleStates.hasData, data };
  };
  return useFetcher(fetchData, [strategyId, flightId, dataVizType]);
};

export type BooleanFetchState = { kind: PossibleStates.hasData, data: boolean };

const notYouTube = { kind: PossibleStates.hasData, data: false } as BooleanFetchState;
export const useIsYouTubeFetcher = (strategyTypeId: number, attachments: Array<StrategyGoalAnalyticsAttachmentType>) => {
  // For the purposes of insights, we need only know if ALL line items are TrueView
  const fetchIsYouTube = async (): Promise<BooleanFetchState> => {
    if (strategyTypeId !== STRATEGY_TYPE.dbmBudgetOptimization.id || !_.size(attachments)) {
      return notYouTube;
    }

    const lineItemIds = _.flatMap(attachments, ({ children }) => _.map(children, 'childId'));

    if (!_.size(lineItemIds)) {
      return notYouTube;
    }

    const { data: lineItems } = await DBMLineItem.get({
      where: { externalId: lineItemIds },
    });

    return { kind: PossibleStates.hasData, data: _.every(lineItems, { lineItemType: TRUE_VIEW }) };
  };
  return useFetcher(fetchIsYouTube, [strategyTypeId, attachments]);
};

export const useHasGeoDataFetcher = (geoDataFetchState: InsightsState) => {
  const fetchHasGeoData = async (): Promise<BooleanFetchState> => {
    if (geoDataFetchState.kind === PossibleStates.hasData && _.every(geoDataFetchState.data.performance, (d: GeoInsightsDatum) => d.geoRegion !== 'Unknown')) {
      return { kind: PossibleStates.hasData, data: true };
    }
    return { kind: PossibleStates.hasData, data: false };
  };
  return useFetcher(fetchHasGeoData, [geoDataFetchState]);
};

// using _.titleCase removes special chars like "_"
export const formatDspObjectName = (name: string) => _.replace(name, /\w+/g, _.capitalize);

export const getDeliveryText = ({ budgetType, currencyCode }: { budgetType: string, currencyCode?: string }) => {
  const spendStr = currencyCode ? `(${currencyCode})` : '';
  if (budgetType === BudgetTypes.amount) {
    return `spend ${spendStr}`;
  }
  return 'impressions';
};

export const getDeliveryTextUpper = (upperFn: (s: string) => string = _.upperFirst) => compose(upperFn, getDeliveryText);

const getTooltipsForIOT = (
  goalType: string,
  childExtTypeDisplayName: string,
  parentExtTypeDisplayName: string,
  dsp: number,
) => [
  `Visualizes all features analyzed aggregated across all Intelligent ${childExtTypeDisplayName}s. `
    + 'Each bubble represents a features value. The size of each feature bubble represents its delivery and color '
    + `denotes the relative ${goalType} performance. Delivery is measured in ${parentExtTypeDisplayName} due to the `
    + `budget type selected in ${DSP.getById(dsp).displayName}. Features that have been targeted on Intelligent `
    + `${childExtTypeDisplayName}s have a blue outline. Features that are more likely to be targeted can be found `
    + 'within the "Exploit" range but other factors such volatility and delivery are taken into account.',
];

const getTooltipsForCFI = (
  goalType: string,
  childExtTypeDisplayName: string,
  budgetType: string,
  currencyCode: string,
  dsp: number,
) => {
  const delivery = getDeliveryText({ budgetType, currencyCode });
  const dspName = DSP.getById(dsp).displayName;
  return [
    `Compares delivery and ${goalType} of top 25 sites and applications filtered by delivery. Delivery is measured `
    + `in ${delivery} due to the budget type selected in ${dspName}. Each bubble represents a site or application. `
    + `The size of each site or application bubble represents its delivery and color denotes the relative ${goalType} `
    + `performance. Sites or Applications that have been targeted on Intelligent ${childExtTypeDisplayName}s have a blue outline.`,
  ];
};

const getTooltipsForDFI = (goalType: string) => [
  `Compares ${goalType} across the intersection of device, ad size and ad position. `
  + `Ad size and positioning are layered over devices types to visualize their relative ${goalType}. `
  + `Color denotes the relative ${goalType} performance.`,
];

const getTooltipsForGeo = (
  goalType: string,
  budgetType: string,
  currencyCode: string,
  dsp: number,
) => {
  const delivery = getDeliveryText({ budgetType, currencyCode });
  const dspName = DSP.getById(dsp).displayName;
  return [
    `Compares delivery and ${goalType} across regions within a particular country. Delivery is measured in ${delivery} `
    + `due to the budget type selected in ${dspName}. Boundaries layered across the map represent a geographical `
    + 'region within that country. The number positioned next to a region\'s abbreviated name represents its raw '
    + `delivery and color denotes the relative ${goalType} performance.`,
  ];
};

export const tooltipsByVizId = {
  [VizId.intelligentOptimizationTargeting]: getTooltipsForIOT,
  [VizId.contextualFeatureInsights]: getTooltipsForCFI,
  [VizId.deviceFeatureInsights]: getTooltipsForDFI,
  [VizId.geoInsights]: getTooltipsForGeo,
};

export const formatCurrencyGoal = (goal, currency, showCurrency = false) => (
  `${numeral(goal.toPrecision(VALUE_PRECISION)).format(CURRENCY_FORMAT)} ${showCurrency ? currency : ''}`
);
export const formatPercentageGoal = (goal) => numeral(goal.toPrecision(VALUE_PRECISION)).format(PERCENTAGE_FORMAT);
export const roundedPercentageGoal = (goal) => numeral(goal).format(ROUNDED_PERCENTAGE_FORMAT);

export const getMinValue = (data) => _.get(_.minBy(data, 1), 1, 0);

export const getMaxValue = (data) => _.get(_.maxBy(data, 1), 1, 0);

/* From https://stackoverflow.com/questions/29031659/calculate-width-of-text-before-drawing-the-text
   by TxRegex https://stackoverflow.com/users/1544706/txregex
   Returns the render width of some text given the font size and font face */
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
export const getBrowserText = (text: string, fontSize: number, fontFace: string) => {
  context.font = `${fontSize + 1}px ${fontFace}`;
  return context.measureText(text).width;
};

export type GeoRegionSimNode = SimulationNodeDatum & RegionDatum;
export const runSimulationToPositionGeoLabels = (
  fontSize: number,
  regionData: Array<RegionDatum>,
  cb: () => void,
) => {
  /*
    Then, perform forceSimulation on the region label data to make sure they do not collide

    Normally, forceSimulation is done with circles, but in this case we are using text and use bboxCollide
    from Elijah Meeks - https://github.com/emeeks/d3-bboxCollide:
  */
  const padding = 5;
  const rectangleCollide = bboxCollide((d) => ([
    [-d.textLength / 2 - padding, -fontSize - padding], [d.textLength / 2 + padding, fontSize + padding],
  ]));

  const simulation = forceSimulation<GeoRegionSimNode>(regionData)
    .force('y', forceY<GeoRegionSimNode>((d) => (d.centroid ? d.centroid[1] : 0)).strength(1))
    .force('x', forceX<GeoRegionSimNode>((d) => (d.centroid ? d.centroid[0] : 0)).strength(1.2))
    .force('collide', rectangleCollide)
    .stop();

  // use a timeout so the page can load first
  timeout(() => {
    // run the simulation to determine label positions
    // see here https://github.com/d3/d3-force/blob/master/README.md#simulation_tick for the logic behind the loop
    const loopIterations = Math.ceil(Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()));
    for (let i = 0, n = loopIterations; i < n; i += 1) {
      simulation.tick();
    }
    cb();
  });
};

export const cleanSingleAppSiteName = (appOrSiteName: string) => {
  const leadingStrsToRemove = [
    'play.google.com/store/apps/details?id=',
    'com.',
    'www.',
  ];
  let copyOfAppOrSiteName = appOrSiteName;
  _.forEach(leadingStrsToRemove, (s) => {
    copyOfAppOrSiteName = _.replace(copyOfAppOrSiteName, s, '');
  });
  return _.toLower(copyOfAppOrSiteName);
};

export const stratHasRevenueType = (strategy: Strategy) => !_.isEmpty(_.get(strategy, 'revTypeConfig'));

export const getParentDisplayName = (dsp: number) => {
  switch (dsp) {
    case DSP.APN.id:
      return FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.wizardDisplayName;
    case DSP.TTD.id:
      return FLIGHT_EXTERNAL_TYPE.ttdCampaign.wizardDisplayName;
    case DSP.DBM.id:
      return FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.wizardDisplayName;
    case DSP.AMZN.id:
      return FLIGHT_EXTERNAL_TYPE.amznCampaign.wizardDisplayName;
    default:
      return 'Insertion Order';
  }
};

export const getChildDisplayName = (dsp: number) => {
  switch (dsp) {
    case DSP.APN.id:
      return FLIGHT_EXTERNAL_TYPE.apnLineItem.wizardDisplayName;
    case DSP.TTD.id:
      return FLIGHT_EXTERNAL_TYPE.ttdMegagon.wizardDisplayName;
    case DSP.DBM.id:
      return FLIGHT_EXTERNAL_TYPE.dbmLineItem.wizardDisplayName;
    case DSP.AMZN.id:
      return FLIGHT_EXTERNAL_TYPE.amznAdGroup.wizardDisplayName;
    default:
      return 'Line Item';
  }
};

export const hasBudgetGroupsCheck = (childGroups?: Array<ChildGroupType>) => !!(childGroups && !_.isEmpty(childGroups) && !(_.size(childGroups) === 1 && _.get(childGroups, '[0].groupId') === DEFAULT_GROUP_ID));

export const useInsightsAnalyticsDataFetcher = (strategyId: number, isCrossPlatform: boolean, hasBudgetGroups: boolean, intelligentChildObjects: boolean): State => {
  const fetchData = async (): Promise<State> => {
    if (_.isNil(strategyId)) {
      return { kind: PossibleStates.initial };
    }

    try {
      const [isCloneData, lineItemPairData, dspData, dspIsCloneData, budgetGroupData, budgetGroupIsCloneData] = await Promise.all([
        // conditionally make the following calls to improve latency
        intelligentChildObjects ? Microservices.runService({ strategy: strategyId, aggLevel: AggregationLevel.isClone }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE) : null,
        (!isCrossPlatform && !hasBudgetGroups && intelligentChildObjects) ? Microservices.runService({ strategy: strategyId, aggLevel: AggregationLevel.lineItemPair }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE) : null,
        isCrossPlatform ? Microservices.runService({ strategy: strategyId, aggLevel: AggregationLevel.dsp }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE) : null,
        (isCrossPlatform && !hasBudgetGroups && intelligentChildObjects) ? Microservices.runService({ strategy: strategyId, aggLevel: AggregationLevel.dspIsClone }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE) : null,
        hasBudgetGroups ? Microservices.runService({ strategy: strategyId, aggLevel: AggregationLevel.budgetGroup }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE) : null,
        hasBudgetGroups ? Microservices.runService({ strategy: strategyId, aggLevel: AggregationLevel.budgetGroupIsClone }, STRATEGY_GOAL_ANALYTICS_MICROSERVICE) : null,
      ]);

      return desnakify(
        {
          kind: PossibleStates.hasData,
          data: {
            isCloneData: isCloneData && _.omit(isCloneData.data, 'metadata'),
            lineItemPairData: lineItemPairData && _.omit(lineItemPairData.data, 'metadata'),
            dspData: dspData && _.omit(dspData.data, 'metadata'),
            dspIsCloneData: dspIsCloneData && _.omit(dspIsCloneData.data, 'metadata'),
            budgetGroupData: budgetGroupData && _.omit(budgetGroupData.data, 'metadata'),
            budgetGroupIsCloneData: budgetGroupIsCloneData && _.omit(budgetGroupIsCloneData.data, 'metadata'),
          },
        },
        analyticsDesnakifyFunc,
      ) as State;
    } catch (e) {
      return { kind: PossibleStates.error, errorObject: e };
    }
  };
  return useFetcher(fetchData, [strategyId]);
};

export const getViewabilityGoal = (strategyGoals: StrategyGoalsDB): false | ViewabiltyGoal => {
  const viewabilityGoal = _.find(strategyGoals, (goal) => _.camelCase(goal.type) === GOAL_TYPES.ivrMeasured.value);
  if (!viewabilityGoal) {
    return false;
  }

  return {
    valueType: GOAL_TYPES.ivrMeasured.valueType,
    shortText: GOAL_TYPES.ivrMeasured.shortText,
    name: GOAL_TYPES.ivrMeasured.value,
    ...viewabilityGoal,
  };
};

export const getAdditionalGoalInfo = (goal: StrategyGoalAnalyticsGoalType): PrimaryStrategyGoal => {
  // strategy goal analytics metadata return the goal name snake cased
  const name = goal.isSystemGoal ? _.camelCase(_.replace(goal.name, 'legacy', '')) : _.camelCase(goal.name);
  const lowerIsBetter = goal.direction === 'down';
  const baseGoalTypeInfo = _.get(GOAL_TYPES, name);
  if (!goal.isSystemGoal || !baseGoalTypeInfo) {
    return { ...goal, name, lowerIsBetter };
  }

  const { valueType, shortText } = baseGoalTypeInfo;
  return { ...goal, name, valueType, shortText, lowerIsBetter };
};

export const includeEstimatedKpiInCumulativeAnalytics = (cumData: Array<StrategyGoalAnalyticsDatum>, budgetOptData: Array<BudgetOptDataPacingDatum>): Array<CumulativeDailyDatum> => _.map(cumData, (cumDataPerDate) => {
  const date = cumDataPerDate.date;
  const budgetOptPacingDataForDate = _.find(budgetOptData, (budgetOptDataPerDate) => moment(date).isSame(budgetOptDataPerDate.date, 'day'));
  return {
    ...cumDataPerDate,
    ...(budgetOptPacingDataForDate && { estimatedKpiCumulative: budgetOptPacingDataForDate.estimatedKpiCumulative }),
  };
});

export const getLastDayOfDataByKey = (data: Array<StrategyGoalAnalyticsData>, iteratee: _.ValueIteratee<StrategyGoalAnalyticsData>) => (_
  .chain(data)
  .groupBy(iteratee)
  .map((dataArr, key) => [key, _.last(dataArr)])
  .fromPairs()
  .value()
);

export const formatGoalValue = (goalType: string, goalValue: number, goalValueType?: string): number => {
  switch (goalValueType) {
    case GOAL_VALUE_TYPE.PERCENTAGE: {
      if (goalType === GOAL_TYPES.ivrMeasured.value) {
        return _.round(goalValue * 100);
      }
      return _.round(goalValue * 100, CURRENCY_OR_PERCENTAGE_PRECISION);
    }
    case GOAL_VALUE_TYPE.CURRENCY:
      return _.round(goalValue, CURRENCY_OR_PERCENTAGE_PRECISION);
    default:
      return _.round(goalValue, VALUE_PRECISION);
  }
};

export const getPrimaryGoalKey = (singleDateData: StrategyGoalAnalyticsDatum) => _.find(_.keys(singleDateData), (key: string) => _.startsWith(key, 'goal1'));

export const getPrimaryGoalPerf = (singleDateData: StrategyGoalAnalyticsDatum) => _.get(singleDateData, getPrimaryGoalKey(singleDateData));

export const pluralizeFlightName = (count: number) => (count === 1 ? '' : 's');

export const getOrigChildIdToName = (children: Array<StrategyGoalAnalyticsAttachmentChild>) => _.reduce(children, (res, { childId, childName, isClone }) => {
  if (!isClone) {
    res[childId] = childName;
  }
  return res;
}, {});

// generates a function that accepts a goal performance <number> and returns the corresponding
// hex code string of the performance within given color scale range
export const getGoalPerfColorScale = (
  colorScaleRange: Array<string>,
  dataRange: Array<number>,
  mean: number,
  valueType?: string,
): ScaleThreshold<number, string> => {
  const stdDevPerf = deviation(dataRange);
  const meanPlusNZScoresPerf = getValForZScore(2, mean, stdDevPerf);

  // percentage value type goals can only range between 0 and 100
  const perfMax = valueType === GOAL_VALUE_TYPE.PERCENTAGE
    ? _.min([meanPlusNZScoresPerf, 1, max(dataRange)])
    : meanPlusNZScoresPerf;
  const perfMin = _.max([getValForZScore(-2, mean, stdDevPerf), 0]);
  const colorScaleDomain = getColorScaleDomain(mean, perfMin, perfMax);

  return scaleThreshold<number, string>()
    .domain(colorScaleDomain)
    .range(colorScaleRange);
};

export const getRoundedGoalText = (valueType: string, goal: number, precise: boolean = false): string => {
  switch (valueType) {
    case (GOAL_VALUE_TYPE.PERCENTAGE):
      // eslint-disable-next-line no-case-declarations
      const percentage = goal * 100;
      return `${_.round(percentage, (percentage < 1 ? CURRENCY_OR_PERCENTAGE_PRECISION : 0))}%`;
    case (GOAL_VALUE_TYPE.CURRENCY):
      return _.toString(_.round(goal, precise ? VALUE_PRECISION : CURRENCY_OR_PERCENTAGE_PRECISION));
    default:
      return _.toString(_.round(goal, VALUE_PRECISION));
  }
};

export const createTooltip = (container: Selection<BaseType, unknown, HTMLElement, any>, name: string): Selection<HTMLDivElement, unknown, HTMLElement, any> => (
  container
    .append('div')
    .attr('class', 'name-tooltip')
    .text(name)
    .style('display', 'none')
);

export const mouseOver = (tooltip: Selection<HTMLDivElement, unknown, HTMLElement, any>) => {
  tooltip.style('display', 'block');
};

export const mouseMove = (container: Selection<BaseType, unknown, HTMLElement, any>, tooltip: Selection<HTMLDivElement, unknown, HTMLElement, any>) => {
  const [mx, my] = mouse(container.node() as d3.ContainerElement);
  // update the tooltip position
  tooltip.style('top', `${my}px`)
    .style('left', `${mx + 8}px`);
};

export const mouseOut = (tooltip: Selection<HTMLDivElement, unknown, HTMLElement, any>) => {
  tooltip.style('display', 'none');
};
