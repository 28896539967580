import { max, min } from 'd3';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import ColorLegend from 'charts/Components/ColorLegend';
import { COLORS_PINK_TO_GREEN } from 'charts/constants';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { ISO_DATE } from 'utils/dateTime';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import BudgetAllocationLegend from './BudgetAllocationLegend';
import BudgetOptOverviewViz from './BudgetOptOverviewViz';
import {
  BudgetOptOverviewAggLevel, BudgetOptOverviewNotes, GROUP_CONTAINER_LINE_MAX_THICKNESS, MAX_CROSS_PLATFORM_TOTAL_LINE_THICKNESS,
  MAX_SINGLE_PLATFORM_TOTAL_LINE_THICKNESS, MIN_LINE_THICKNESS, SUB_CONTAINER_LINE_MAX_THICKNESS,
} from './constants';
import { buildBudgetOptOverviewVizData, combineOverflowData, getAggLevel } from './utils';
import ErrorSegment from '../Components/ErrorSegment';
import InsightsBox from '../Components/InsightsBox';
import SlideTitle from '../Components/SlideTitle';
import SlideWrapper from '../Components/SlideWrapper';
import VizHeader from '../Components/VizHeader';
import { SlideIcons, VizId } from '../constants';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { COPILOT_MODELS, CopilotModels } from '../OptimizationSummary/constants';
import { getInsightsByVizId, getChildDisplayName, getLastDayOfDataByKey, hasBudgetGroupsCheck, getGoalPerfColorScale, getRoundedGoalText, getPrimaryGoalKey } from '../utils';
import { BaseInsightsSlideProps } from '../types';

const vizId = VizId.budgetOptimizationOverview;

const BudgetOptimizationOverview = ({ onVizLoaded, onVizError }: BaseInsightsSlideProps) => {
  const [dataError, setDataError] = useState<boolean>(false);
  const {
    primaryStrategyGoal: {
      displayName: goalName,
      target: goalTarget,
      shortText,
      valueType,
      lowerIsBetter,
    },
    strategy: {
      strategyType: { dsp, id: stratTypeId },
      config: { intelligentChildObjects },
    },
    metadata: {
      attachments,
      budgetOptConfig: { childGroups, childToGroupName },
      copilot: { origToClone },
    },
    budgetType,
    childData: { cumData: childCumData },
    lineItemPairData,
    dspData,
    dspIsCloneData,
    budgetGroupData,
    budgetGroupIsCloneData,
    currency,
  } = useInsightsContext();

  const isCrossPlatform = isCrossPlatformStrategyType(stratTypeId);
  const hasBudgetGroups = hasBudgetGroupsCheck(childGroups);
  const childDisplayName = _.lowerCase(getChildDisplayName(dsp));
  const { aggLevel, aggDisplayText } = getAggLevel(hasBudgetGroups, isCrossPlatform, childDisplayName);
  const lineItemData = getLastDayOfDataByKey(childCumData, 'childExtId');
  const primaryGoalKey = getPrimaryGoalKey(_.head(_.values(lineItemData)));
  const deliveryKpi = budgetType && BudgetTypeMapping[budgetType];
  const tooltipContent = 'Visualizes how the strategy\'s budget has been allocated. The width of stems represents the volume of delivery allocated and colored bars denote performance to the mean.';

  useMount(() => {
    if (
      !attachments || !budgetType || !goalName
      || ((aggLevel === BudgetOptOverviewAggLevel.budgetGroup) && (!budgetGroupData || (intelligentChildObjects && !budgetGroupIsCloneData)))
      || ((aggLevel === BudgetOptOverviewAggLevel.platform) && (!dspData || (intelligentChildObjects && !dspIsCloneData)))
      || ((aggLevel === BudgetOptOverviewAggLevel.child) && (!childCumData || (intelligentChildObjects && !lineItemPairData)))
    ) {
      setDataError(true);
      onVizError();
    } else {
      onVizLoaded(BudgetOptOverviewNotes);
    }
  });

  if (dataError) {
    return <ErrorSegment />;
  }

  const getAnalyticsData = () => {
    switch (aggLevel) {
      case (BudgetOptOverviewAggLevel.budgetGroup):
        return {
          budgetGroupData: getLastDayOfDataByKey(budgetGroupData.cumData, 'budgetGroup'),
          budgetGroupIsCloneData: getLastDayOfDataByKey(budgetGroupIsCloneData?.cumData ?? [], ({ budgetGroup, isClone }) => `${budgetGroup}-${isClone ? 'clone' : 'orig'}`),
        };
      case (BudgetOptOverviewAggLevel.platform):
        return {
          dspData: getLastDayOfDataByKey(dspData.cumData, 'dsp'),
          dspIsCloneData: getLastDayOfDataByKey(dspIsCloneData?.cumData ?? [], ({ dsp: dspId, isClone }) => `${dspId}-${isClone ? 'clone' : 'orig'}`),
        };
      case (BudgetOptOverviewAggLevel.child):
      default:
        return {
          lineItemData,
          lineItemPairData: getLastDayOfDataByKey(lineItemPairData?.cumData ?? [], 'childExtId'),
        };
    }
  };

  const data = buildBudgetOptOverviewVizData(
    aggLevel,
    intelligentChildObjects,
    getAnalyticsData(),
    primaryGoalKey,
    deliveryKpi,
    attachments,
    origToClone,
    childGroups,
    childToGroupName,
  );
  const totalDelivery = _.sumBy(data, 'delivery');
  const dataWithOverflow = combineOverflowData(data, aggDisplayText);
  const maxGroupDelivery = max(intelligentChildObjects ? _.flatMap(dataWithOverflow, ({ origDel, cloneDel }) => [origDel, cloneDel]) : _.map(dataWithOverflow, 'delivery'));
  const maxDeliveryLineThickness = intelligentChildObjects
    ? SUB_CONTAINER_LINE_MAX_THICKNESS
    : Math.min(
      (maxGroupDelivery / totalDelivery) * (isCrossPlatform ? MAX_CROSS_PLATFORM_TOTAL_LINE_THICKNESS : MAX_SINGLE_PLATFORM_TOTAL_LINE_THICKNESS),
      GROUP_CONTAINER_LINE_MAX_THICKNESS,
    );
  // function to calculate delivery line thicknesses
  const getDeliveryLineScale = (del: number) => Math.max(
    (del / maxGroupDelivery) * maxDeliveryLineThickness,
    MIN_LINE_THICKNESS,
  );
  const getDeliveryFromThickness = (thickness: number) => (thickness * maxGroupDelivery) / maxDeliveryLineThickness;

  const colorScaleRange = lowerIsBetter ? _.reverse([...COLORS_PINK_TO_GREEN]) : COLORS_PINK_TO_GREEN;
  const dataRangeForColorScale = _.flatMap(dataWithOverflow, ({ goalPerf, origGoalPerf, cloneGoalPerf }) => [goalPerf, origGoalPerf, cloneGoalPerf]);
  const minMaxMean = (min(dataRangeForColorScale) + max(dataRangeForColorScale)) / 2;
  const colorScale = getGoalPerfColorScale(colorScaleRange, dataRangeForColorScale, minMaxMean, valueType);

  return (
    <div id={vizId} className="slide">
      <SlideTitle section="Optimization Insights" subSection="Budget Optimization" icon={SlideIcons.budgetOptimization} />
      <div className="flex-container">
        <div className="main-visualization">
          <VizHeader
            title="How Budget Was Allocated Across Your Strategy"
            subtitle={`Budget distribution and ${shortText ?? _.lowerCase(goalName)} per ${aggDisplayText}`}
            tooltipContent={tooltipContent}
          />
          <BudgetOptOverviewViz
            data={dataWithOverflow}
            getDeliveryLineScale={getDeliveryLineScale}
            colorScale={colorScale}
            aggLevel={aggLevel}
            aggDisplayText={aggDisplayText}
            isCrossPlatform={isCrossPlatform}
          />
        </div>
        <div className="viz-legend">
          <div className="budget-opt-overview-desc">
            <div className="budget-opt-overview-badge">
              <img src={COPILOT_MODELS[CopilotModels.budgetOptimization].imageSrc} alt="budget opt badge" />
              <span>Budget Optimization</span>
            </div>
            <p>Copilot dynamically adjusts budget across {childDisplayName}s to maximize performance.</p>
          </div>
          <ColorLegend
            id="budget-optimization-viz"
            type="discrete"
            label={`${goalName}${valueType === GOAL_VALUE_TYPE.CURRENCY ? ` (${currency})` : ''}`}
            width={264}
            meanValue={minMaxMean}
            showGoalValue
            goalValue={goalTarget}
            lowerIsBetter={lowerIsBetter}
            data={{
              colorRange: colorScaleRange,
              domain: colorScale.domain(),
              format: (d) => getRoundedGoalText(valueType, d),
            }}
            margins={{ top: 8, bottom: 0, left: 0, right: 0 }}
          />
          <BudgetAllocationLegend
            getDeliveryFromThickness={getDeliveryFromThickness}
            deliveryKpi={deliveryKpi}
            currency={currency}
          />
        </div>
      </div>
      <InsightsBox
        insights={getInsightsByVizId(vizId, {
          lowerIsBetter,
          childDisplayName,
          totalChildCount: _.size(_.flatMap(attachments, 'children')),
          totalDaysOfData: moment().diff(moment(_.get(_.minBy(childCumData, 'date'), 'date')).format(ISO_DATE), 'days'),
          goal: shortText ?? goalName,
          valueType,
          deliveryKpi,
          currency,
          aggLevel,
          aggDisplayText,
          vizData: data,
        })}
      />
    </div>
  );
};

export default React.memo(SlideWrapper(BudgetOptimizationOverview));
