import * as d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import { GOAL_TYPES } from 'constantsBase';
import { StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { GoalChartVizData } from './constants';
import GoalHeader from './GoalHeader';
import { buildCrossPlatformGoalChartVizData, getBarPath, getCustomPadding, getMaxBarWidth, getValueRange } from './utils';
import { DspToAnalyticsData, PrimaryStrategyGoal, ViewabiltyGoal } from '../types';

const buildViz = (data: Array<GoalChartVizData>, isViewabilityGoal: boolean) => {
  const maxDataPoint = _.maxBy(data, (d) => (d.value ?? d.point));
  const maxLabelLength = _.size(`${maxDataPoint.point}${maxDataPoint.label}`);
  const goalData = _.find(data, ['name', 'Goal']);
  const dspCount = _.size(data) - 2; // data without ALL and Goal

  // set the dimensions of the canvas
  const margin = {
    top: 10, // margin for ALL row
    right: (2 * maxLabelLength) + 8,
    bottom: 2,
    left: 62, // margin for labels
  };
  const width = 310 - margin.left - margin.right;
  const height = 40 + (dspCount * 12);

  // set the ranges
  const y = d3.scaleBand()
    .range([0, height])
    .padding(0);

  const x = d3.scaleLinear()
    .range([0, getMaxBarWidth(data)]);

  // create the SVG element
  const svg = d3.select(`.cross-platform-${isViewabilityGoal ? 'viewability' : 'primary'}-goal-chart`)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .style('font-size', '12px')
    .style('font-weight', '600')
    .append('g')
    .attr(
      'transform',
      `translate(${margin.left},${margin.top})`,
    );

  // scale the range of the data
  x.domain(d3.extent(getValueRange(data)));
  y.domain(data.map((d) => d.name));

  // add the bars
  svg.selectAll('.bar')
    .data(data)
    .enter().append('path')
    .attr('class', 'bar')
    .attr('d', (d) => {
      if (d.name === 'Goal') return '';
      const barWidth = d.value >= 0 ? x(d.value) - x(0) : x(0) - x(d.value);
      const barX = d.value >= 0 ? x(0) : x(d.value);
      const barHeight = 4;
      const barY = y(d.name) + y.bandwidth() / 4 + getCustomPadding(d) - 2;
      const borderRadius = barHeight / 2; // set the border radius to half the height of the bar
      return getBarPath(d.value, barX, barY, barWidth, barHeight, borderRadius);
    })
    .style('fill', (d) => d.color);

  // add labels to the right of the bars
  svg.selectAll('.text')
    .data(data)
    .enter()
    .append('text')
    .attr('class', 'label')
    .attr('x', (d) => (_.isNil(d.value) ? x(d.point) + 11.5 : x(d.value >= 0 ? d.value : 0) + 8))
    .attr('y', (d) => y(d.name) + y.bandwidth() / 2 + getCustomPadding(d))
    .text((d) => `${d.value ?? d.point}${d.label}`)
    .style('fill', (d) => d.color);

  // add labels to the left of the bars
  svg.selectAll('.label-left')
    .data(data)
    .enter()
    .append('text')
    .attr('class', 'label label-left')
    .attr('x', -48)
    .attr('y', (d) => y(d.name) + y.bandwidth() / 2 + getCustomPadding(d))
    .text((d) => d.name)
    .style('fill', (d) => d.color)
    .attr('class', 'cross-platform-label')
    .attr('id', (d) => d.name)
    .attr('text-anchor', 'start');

  // add the goal line
  svg.append('line')
    .attr('class', 'goal-line')
    .attr('x1', x(goalData.point))
    .attr('y1', 0)
    .attr('x2', x(goalData.point))
    .attr('y2', y(goalData.name) + y.bandwidth() / 2)
    .style('stroke-dasharray', ('2, 2'))
    .style('stroke-width', 1)
    .style('stroke', goalData.color);

  // add the goal point
  svg.append('circle')
    .attr('class', 'goal-point')
    .attr('cx', x(goalData.point))
    .attr('cy', y(goalData.name) + y.bandwidth() / 2 + getCustomPadding(goalData) - 3.5)
    .attr('r', 3.5)
    .style('fill', goalData.color);

  // add the x-axis
  svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))
    .call((g) => g.select('.domain').remove()) // remove the x-axis line
    .call((g) => g.selectAll('.tick line').remove()) // remove the x-axis ticks
    .call((g) => g.selectAll('.tick text').remove()); // remove the x-axis labels

  // add the y-axis
  svg.append('g')
    .call(d3.axisLeft(y))
    .call((g) => g.select('.domain').remove()) // remove the y-axis line
    .call((g) => g.selectAll('.tick line').remove()) // remove the y-axis ticks
    .call((g) => g.selectAll('.tick text').remove()); // remove the y-axis labels
};

type Props = {
  goal: PrimaryStrategyGoal | ViewabiltyGoal
  currency: string
  mostRecentStratCumData: StrategyGoalAnalyticsData
  dspToMostRecentCumData: DspToAnalyticsData
};

const CrossPlatformGoalChart = (props: Props) => {
  const { goal, currency, mostRecentStratCumData, dspToMostRecentCumData } = props;
  const isViewabilityGoal = goal.name === GOAL_TYPES.ivrMeasured.value;

  useMount(() => {
    buildViz(
      buildCrossPlatformGoalChartVizData(goal, currency, mostRecentStratCumData, dspToMostRecentCumData),
      isViewabilityGoal,
    );
  });

  return (
    <div>
      <GoalHeader
        title={isViewabilityGoal ? 'Viewability Goal' : 'Primary Goal'}
        description={isViewabilityGoal ? 'In-View Rate (IVR)' : (goal.shortText ?? _.toUpper(goal.name))}
      />
      <div className={`cross-platform-${isViewabilityGoal ? 'viewability' : 'primary'}-goal-chart`} />
    </div>
  );
};

export default CrossPlatformGoalChart;
