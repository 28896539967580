import { COPILOT_COLORS } from 'globalStyles';
import { CopilotModelType } from './types';

const { NEW_DESIGN_SYSTEM: { BLUES, PURPLES, YELLOWS, PINKS } } = COPILOT_COLORS;

export const INSIGHTS_IMG_PREFIX = '/img/components/Insights/';

export enum CopilotModels {
  intelligentLineItems = 'intelligentLineItems',
  viewabilityControl = 'viewabilityControl',
  budgetOptimization = 'budgetOptimization',
  bidModeling = 'bidModeling',
}

export const COPILOT_MODELS: { [key in CopilotModels]: CopilotModelType } = {
  [CopilotModels.intelligentLineItems]: {
    title: 'Intelligent Line Items',
    description: 'Copilot generates Intelligent Line Items to dynamically target high value inventory.',
    imageSrc: `${INSIGHTS_IMG_PREFIX}iliBadge.svg`,
    color: BLUES.B500_WAVE,
  },
  [CopilotModels.viewabilityControl]: {
    title: 'Viewability Control',
    description: 'Copilot adjusts viewability thresholds to exclude inventory below the viewability goal.',
    imageSrc: `${INSIGHTS_IMG_PREFIX}vcBadge.svg`,
    color: PURPLES.P500_REX,
  },
  [CopilotModels.budgetOptimization]: {
    title: 'Budget Optimization',
    description: 'Copilot dynamically adjusts budget across Line Items to maximize performance.',
    imageSrc: `${INSIGHTS_IMG_PREFIX}boBadge.svg`,
    color: YELLOWS.Y400_INSIGHTS_ORANGE,
  },
  [CopilotModels.bidModeling]: {
    title: 'Bid Modeling',
    description: 'Copilot identifies clusters of feature combinations and determines bid values.',
    imageSrc: `${INSIGHTS_IMG_PREFIX}bmBadge.svg`,
    color: PINKS.P500_MAX,
  },
};

const optSummaryArrowPaths = {
  topLeft: 'M1.36572 6.6407C0.614998 6.99101 0.290399 7.88357 0.640704 8.63428L6.34926 20.8679C6.69956 21.6187 7.59212 21.9433 8.34283 21.593C9.09355 21.2427 9.41815 20.3501 9.06785 19.5994L3.99358 8.72501L14.8679 3.65074C15.6187 3.30044 15.9433 2.40788 15.593 1.65717C15.2427 0.906447 14.3501 0.581849 13.5994 0.932154L1.36572 6.6407ZM98.4131 74.5C85.5303 74.5 76.6082 70.475 69.5983 64.4702C62.5199 58.4066 57.3455 50.2946 52.0334 41.9448C46.7686 33.6696 41.366 25.1566 33.8889 18.7515C26.3431 12.2875 16.7311 8 3.12245 8V11C16.0052 11 24.9274 15.025 31.9372 21.0298C39.0157 27.0934 44.19 35.2054 49.5022 43.5552C54.7669 51.8304 60.1695 60.3434 67.6466 66.7485C75.1924 73.2125 84.8044 77.5 98.4131 77.5V74.5ZM3.63511 6.99853L2.51266 6.59033L1.48734 9.40967L2.60979 9.81788L3.63511 6.99853Z',
  topMiddle: 'M13.0607 0.93934C12.4749 0.353553 11.5251 0.353553 10.9393 0.93934L1.3934 10.4853C0.807611 11.0711 0.807611 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.93934ZM13.5 73V2H10.5V73H13.5Z',
  topRight: 'M97.0474 6.6407C97.7981 6.99101 98.1227 7.88357 97.7724 8.63428L92.0638 20.8679C91.7135 21.6187 90.821 21.9433 90.0703 21.593C89.3195 21.2427 88.9949 20.3501 89.3452 19.5994L94.4195 8.72501L83.5451 3.65074C82.7944 3.30044 82.4698 2.40788 82.8201 1.65717C83.1704 0.906447 84.063 0.581849 84.8137 0.932154L97.0474 6.6407ZM0 74.5C12.8828 74.5 21.8049 70.475 28.8147 64.4702C35.8932 58.4066 41.0675 50.2946 46.3797 41.9448C51.6445 33.6696 57.0471 25.1566 64.5242 18.7515C72.07 12.2875 81.682 8 95.2906 8V11C82.4079 11 73.4857 15.025 66.4759 21.0298C59.3974 27.0934 54.2231 35.2054 48.9109 43.5552C43.6462 51.8304 38.2435 60.3434 30.7664 66.7485C23.2207 73.2125 13.6087 77.5 0 77.5V74.5ZM94.778 6.99853L95.9004 6.59033L96.9257 9.40967L95.8033 9.81788L94.778 6.99853Z',
  middleLeft: 'M0.93934 10.9393C0.353553 11.5251 0.353554 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.80761 11.0711 0.80761 10.4853 1.3934L0.93934 10.9393ZM73.411 10.5L2 10.5L2 13.5L73.411 13.5L73.411 10.5Z',
  middleRight: 'M72.4718 13.0607C73.0576 12.4749 73.0576 11.5251 72.4718 10.9393L62.9258 1.3934C62.3401 0.807613 61.3903 0.807614 60.8045 1.3934C60.2187 1.97919 60.2187 2.92893 60.8045 3.51472L69.2898 12L60.8045 20.4853C60.2187 21.0711 60.2187 22.0208 60.8045 22.6066C61.3903 23.1924 62.3401 23.1924 62.9259 22.6066L72.4718 13.0607ZM0.000122005 13.5L71.4111 13.5L71.4111 10.5L0.000122136 10.5L0.000122005 13.5Z',
  bottomLeft: 'M1.36572 71.3593C0.614998 71.009 0.290399 70.1164 0.640704 69.3657L6.34926 57.1321C6.69956 56.3813 7.59212 56.0567 8.34283 56.407C9.09355 56.7573 9.41815 57.6499 9.06785 58.4006L3.99358 69.275L14.8679 74.3493C15.6187 74.6996 15.9433 75.5921 15.593 76.3428C15.2427 77.0936 14.3501 77.4182 13.5994 77.0678L1.36572 71.3593ZM98.4131 3.5C85.5303 3.5 76.6082 7.52498 69.5983 13.5298C62.5199 19.5934 57.3455 27.7054 52.0334 36.0552C46.7686 44.3304 41.366 52.8434 33.8889 59.2485C26.3431 65.7125 16.7311 70 3.12245 70V67C16.0052 67 24.9274 62.975 31.9372 56.9702C39.0157 50.9066 44.19 42.7946 49.5022 34.4448C54.7669 26.1696 60.1695 17.6566 67.6466 11.2515C75.1924 4.78752 84.8044 0.5 98.4131 0.5V3.5ZM3.63511 71.0015L2.51266 71.4097L1.48734 68.5903L2.60979 68.1821L3.63511 71.0015Z',
  bottomMiddle: 'M10.9393 72.0607C11.5251 72.6464 12.4749 72.6464 13.0607 72.0607L22.6066 62.5147C23.1924 61.9289 23.1924 60.9792 22.6066 60.3934C22.0208 59.8076 21.0711 59.8076 20.4853 60.3934L12 68.8787L3.51472 60.3934C2.92893 59.8076 1.97919 59.8076 1.3934 60.3934C0.807611 60.9792 0.807611 61.9289 1.3934 62.5147L10.9393 72.0607ZM10.5 0L10.5 71H13.5L13.5 0H10.5Z',
  bottomRight: 'M97.0474 71.3593C97.7981 71.009 98.1227 70.1164 97.7724 69.3657L92.0638 57.1321C91.7135 56.3813 90.821 56.0567 90.0703 56.407C89.3195 56.7573 88.9949 57.6499 89.3452 58.4006L94.4195 69.275L83.5451 74.3493C82.7944 74.6996 82.4698 75.5921 82.8201 76.3428C83.1704 77.0936 84.063 77.4182 84.8137 77.0678L97.0474 71.3593ZM0 3.5C12.8828 3.5 21.8049 7.52498 28.8147 13.5298C35.8932 19.5934 41.0675 27.7054 46.3797 36.0552C51.6445 44.3304 57.0471 52.8434 64.5242 59.2485C72.07 65.7125 81.682 70 95.2906 70V67C82.4079 67 73.4857 62.975 66.4759 56.9702C59.3974 50.9066 54.2231 42.7946 48.9109 34.4448C43.6462 26.1696 38.2435 17.6566 30.7664 11.2515C23.2207 4.78752 13.6087 0.5 0 0.5V3.5ZM94.778 71.0015L95.9004 71.4097L96.9257 68.5903L95.8033 68.1821L94.778 71.0015Z',
};

export const singlePlatformChildDirectionOrder = [
  // top left
  {
    grid: [0, 0],
    path: optSummaryArrowPaths.topLeft,
    calculations: { childRectsOver: 1, columnsOver: 0, childRectsDown: 1, rowsDown: 0, widthDiff: 0.15, heightDiff: 0 },
  },
  // top right
  {
    grid: [2, 0],
    path: optSummaryArrowPaths.topRight,
    calculations: { childRectsOver: 2, columnsOver: 2, childRectsDown: 1, rowsDown: 0, widthDiff: 0.85, heightDiff: 0 },
  },
  // bottom left
  {
    grid: [0, 2],
    path: optSummaryArrowPaths.bottomLeft,
    calculations: { childRectsOver: 1, columnsOver: 0, childRectsDown: 2, rowsDown: 2, widthDiff: 0.15, heightDiff: 1 },
  },
  // bottom right
  {
    grid: [2, 2],
    path: optSummaryArrowPaths.bottomRight,
    calculations: { childRectsOver: 2, columnsOver: 2, childRectsDown: 2, rowsDown: 2, widthDiff: 0.85, heightDiff: 1 },
  },
  // top middle
  {
    grid: [1, 0],
    path: optSummaryArrowPaths.topMiddle,
    calculations: { childRectsOver: 1.5, columnsOver: 1, childRectsDown: 1, rowsDown: 0, widthDiff: 0.5, heightDiff: -0.1 },
  },
  // middle left
  {
    grid: [0, 1],
    path: optSummaryArrowPaths.middleLeft,
    calculations: { childRectsOver: 1, columnsOver: 0, childRectsDown: 1.5, rowsDown: 1, widthDiff: -0.1, heightDiff: 0.5 },
  },
  // middle right
  {
    grid: [2, 1],
    path: optSummaryArrowPaths.middleRight,
    calculations: { childRectsOver: 2, columnsOver: 2, childRectsDown: 1.5, rowsDown: 1, widthDiff: 1.1, heightDiff: 0.5 },
  },
  // bottom middle
  {
    grid: [1, 2],
    path: optSummaryArrowPaths.bottomMiddle,
    calculations: { childRectsOver: 1.5, columnsOver: 1, childRectsDown: 2, rowsDown: 2, widthDiff: 0.5, heightDiff: 1.1 },
  },
];

export enum SinglePlatformOptSummaryAggLevel {
  budgetGroup = 'budgetGroup',
  lineItem = 'lineItem',
}

export const SINGLE_PLATFORM_OPT_VIZ_WIDTH = 872;
export const SINGLE_PLATFORM_OPT_VIZ_HEIGHT = 540;
export const ROW_GAP = 63;
export const COL_GAP = 16;
export const CHILD_RECT_WIDTH = 280;
export const CHILD_RECT_HEIGHT = 138;
export const PARENT_RECT_WIDTH = 175;
export const PARENT_RECT_HEIGHT = 132;
export const BADGE_WIDTH = 32;
export const BADGE_HEIGHT = 20;
export const MAX_ARROW_DISPLAY = 8;
export const BOTTOM_RIGHT_IDX = 3;

export const optSummaryNotes = [
  'Visualizes the Strategy hierarchy: The buying platforms, count of Insertion Orders and count of total line items as well as the split between Original and Intelligent Line Items.',
  'Copilot, as the budget holder, is the top entity.',
  'The application of models to the strategy is denoted throughout the diagram. See legend on the right hand side.',
];
