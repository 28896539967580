import _ from 'lodash';
import React from 'react';
import { StrategyGoalAnalyticsDatum } from 'containers/StrategyAnalytics/types';
import GoalHeader from './GoalHeader';
import SinglePlatformGoalChart from './SinglePlatformGoalChart';
import VizHeader from '../Components/VizHeader';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { getPrimaryGoalPerf } from '../utils';

type Props = {
  tooltip: string
  hasRevenueType: boolean
  mostRecentCumData: StrategyGoalAnalyticsDatum
};

const SinglePlatformGoalInfo = (props: Props) => {
  const { tooltip, hasRevenueType, mostRecentCumData } = props;
  const { primaryStrategyGoal, viewabilityGoal, currency } = useInsightsContext();

  return (
    <div className="viz-legend">
      <VizHeader title="Goals" subtitle="" tooltipContent={tooltip} />
      <div className="single-platform-goal-info">
        <GoalHeader title={primaryStrategyGoal.displayName} />
        <div className="goal-chart-container">
          <SinglePlatformGoalChart
            goal={primaryStrategyGoal}
            goalValue={getPrimaryGoalPerf(mostRecentCumData) ?? 0}
            currency={currency}
            hasRevenueType={hasRevenueType}
          />
        </div>
      </div>
      {viewabilityGoal && (
        <div className="single-platform-goal-info">
          <GoalHeader title="In View Rate (IVR)" description="Secondary Goal" />
          <div className="goal-chart-container">
            <SinglePlatformGoalChart
              goal={viewabilityGoal}
              goalValue={_.get(mostRecentCumData, viewabilityGoal.name, 0)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SinglePlatformGoalInfo;
