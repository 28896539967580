/* eslint-disable react/no-unused-prop-types */
import { geoPath, select, format } from 'd3';
import _ from 'lodash';
import numeral from 'numeral';
import React, { FunctionComponentElement } from 'react';
import { feature } from 'topojson';
import ColorLegend from 'charts/Components/ColorLegend';
import MessageContainer from 'charts/Components/MessageContainer';
import { COLORS } from 'charts/constants';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import InsightsBox from './Components/InsightsBox';
import { SlideIcons, VizId } from './constants';
import SlideTitle from './Components/SlideTitle';
import VizHeader from './Components/VizHeader';
import List, { ListType } from './Components/List';
import SlideWrapper from './Components/SlideWrapper';
import { useInsightsContext } from './contexts/InsightsProvider';
import { VizScaleBuilder } from './transforms';
import { BudgetType, GeoInsightsData } from './types';
import {
  extractMetadata, getColorLegendLabel, getInsightsByVizId, tooltipsByVizId, getDeliveryTextUpper,
  getBrowserText, runSimulationToPositionGeoLabels, GeoRegionSimNode,
} from './utils';

const VIZ_ID = VizId.geoInsights;
const WIDTH = 824;
const HEIGHT = 537;

const fontSize = 10;
const fontFace = 'Gilroy Medium';

export const GeoPlaceholder = ({ setGeoFeatureInsightsLoaded }: { setGeoFeatureInsightsLoaded: (x: boolean) => void }) => (
  <MessageContainer
    additionalClasses={['geo']}
    onLoad={() => {
      setGeoFeatureInsightsLoaded(true);
    }}
    message="Geo Insights does not support this country yet. We are working on it so stay tuned!"
  />
);

// eslint-disable-next-line no-undef
export type RegionDatum = GeoJSON.Feature & {
  centroid: [number, number]
  textLength: number
  perfDatum: any
  displayLabel: string
};

const buildViz = (
  // eslint-disable-next-line no-undef
  singleCountryGeoJson: GeoJSON.FeatureCollection<GeoJSON.GeometryObject, any>,
  performanceData: Array<any>,
  colorScale,
  kpiValue: string,
  budgetType: BudgetType,
  primaryGoalSuccessEvent: string,
  onVizLoaded: (tooltips: Array<string>) => void,
  tooltips: Array<string>,
) => {
  const path = geoPath();

  const mapContainer = select(`#${VIZ_ID} #map`);

  // build region polygons
  mapContainer
    .selectAll('.region')
    .data(singleCountryGeoJson.features)
    .join('g')
    .attr('class', 'region')
    .attr('id', (d) => d.id)
    .each(function addTextAndLine(regionDatum) {
      const { geometry, properties } = regionDatum;

      const lookupObjForUnmergedGeo = { geoRegion: properties.iso_3166_2 };
      const lookupObjForMergedGeo = { geoRegion: properties.region };
      const perfDatum = _.find(performanceData, lookupObjForUnmergedGeo) || _.find(performanceData, lookupObjForMergedGeo);
      if (geometry) {
        const group = select(this);

        const perfColor = (_.get(perfDatum, primaryGoalSuccessEvent, 0) > 0)
          ? colorScale(_.get(perfDatum, kpiValue, null))
          : COLORS.GREYS.NO_DATA;
        group
          .append('path')
          .attr('d', path)
          .attr('fill', perfColor);
      }
    });

  // build region labels
  const regionData = _.map(singleCountryGeoJson.features, (geoFeature) => {
    const { properties } = geoFeature;

    const lookupObjForUnmergedGeo = { geoRegion: properties.iso_3166_2 };
    const lookupObjForMergedGeo = { geoRegion: properties.region };
    const perfDatum = _.find(performanceData, lookupObjForUnmergedGeo) || _.find(performanceData, lookupObjForMergedGeo);
    const displayLabel = _.get(perfDatum, 'displayRegionAbbr', 'N/A');
    return {
      ...geoFeature,
      centroid: path.centroid(geoFeature),
      textLength: getBrowserText(displayLabel, fontSize, fontFace),
      perfDatum,
      displayLabel,
    };
  });

  const regionLabels = mapContainer
    .selectAll('.region-label')
    .data(_.filter(regionData, (r) => !!r.perfDatum))
    .join('g')
    .attr('class', 'region-label')
    .attr('id', (d) => d.id)
    .each(function addLabelAndDeliveryData({ perfDatum, displayLabel }) {
      const group = select(this);

      group.append('text')
        .text(displayLabel);

      const deliveryNumFormat = format('.3s'); // 2 sig figs
      const deliveryVal = _.get(perfDatum, budgetType);
      group.append('text')
        .attr('dy', 11)
        .text(_.isNil(deliveryVal) ? 'N/A' : deliveryNumFormat(deliveryVal));
    });

  // apply the transform to position the labels
  const applyTransformationsToLabels = () => {
    regionLabels.attr('transform', (d: GeoRegionSimNode) => `translate(${d.x},${d.y})`);
  };

  runSimulationToPositionGeoLabels(fontSize, regionData, applyTransformationsToLabels);

  onVizLoaded(tooltips);
};

type Props = GeoInsightsData & {
  onVizLoaded: (notes: Array<string>) => void
  placeholderConfig?: {
    content: FunctionComponentElement<any>,
    messageStr: string
  }
  showPlaceholder?: boolean
};

const GeoInsights = (props: Props) => {
  const { onVizLoaded, legend, metadata, performance, geoJSON } = props;
  const { primaryStrategyGoal: { lowerIsBetter } } = useInsightsContext();
  const usableMetadata = { ...extractMetadata(metadata), lowerIsBetter };
  const {
    budgetType,
    primaryGoal,
    colorScaleRange,
    currency,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    dsp,
    hasRevenueType,
  } = usableMetadata;
  const { value: kpiValue } = primaryGoal;
  const legendDataSansUnknown = _.reject(legend, (d) => d.displayRegion === 'Unknown');

  const vizScaleBuilder = new VizScaleBuilder({
    perfAndDeliveryData: performance,
    budgetType,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    excludeDataSansSuccessEvents: false,
  });
  const colorScale = vizScaleBuilder.getColorScale({ colorScaleRange, strategyGoalConfigWithTarget: primaryGoal });

  const { topojson, metadata: geoMetadata } = _.first(geoJSON);
  const singleCountryGeoJson = feature<GeoJSON.GeoJsonProperties>(
    topojson,
    (topojson.objects[geoMetadata.key] as TopoJSON.GeometryCollection<GeoJSON.GeoJsonProperties>),
  );

  const tooltips = tooltipsByVizId[VIZ_ID](
    primaryGoal.text,
    budgetType,
    currency,
    dsp,
  );

  useMount(() => buildViz(
    singleCountryGeoJson,
    performance,
    colorScale,
    kpiValue,
    budgetType,
    primaryGoalSuccessEvent,
    onVizLoaded,
    tooltips,
  ));

  return (
    <div id={VIZ_ID} className="slide">
      <SlideTitle section="Feature Insights" subSection="Geographical" icon={SlideIcons.geoInsights} />
      <div className="grid-container">
        <div className="main-visualization">
          <VizHeader
            title="How Do Regions Compare?"
            subtitle={`${primaryGoal.insightsLabel} & ${getDeliveryTextUpper()({ budgetType })} By Region`}
            tooltipContent={tooltips[0]}
          />
          <svg id="map" width={WIDTH} height={HEIGHT} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div className="viz-legend">
            <ColorLegend
              id={`${VIZ_ID}_legend`}
              type="discrete"
              label={getColorLegendLabel(primaryGoal, currency)}
              width={264}
              lowerIsBetter={primaryGoal.lowerIsBetter}
              showMeanValue
              meanValue={vizScaleBuilder.meanPerf}
              showGoalValue
              goalValue={primaryGoal.target}
              data={{
                colorRange: colorScaleRange,
                domain: colorScale.domain(),
                format: (d) => numeral(d).format(primaryGoal.format),
              }}
              hasRevenueType={hasRevenueType}
            />
          </div>
          <List
            data={legendDataSansUnknown}
            budgetType={budgetType}
            goalMetricConfig={primaryGoal}
            features={['displayRegion']}
            type={ListType.featureCombo}
            colorScale={colorScale}
            currency={currency}
            primaryGoalSuccessEvent={primaryGoalSuccessEvent}
            withWrapping={false}
          />
        </div>
      </div>
      <InsightsBox insights={getInsightsByVizId(VIZ_ID, usableMetadata)} />
    </div>
  );
};

const GeoInsightsWrapper = (props: Props) => {
  if (props.placeholderConfig && props.showPlaceholder) {
    return (
      <div id={VIZ_ID}>{props.placeholderConfig.content}</div>
    );
  }
  return <GeoInsights {...props} />;
};

export default SlideWrapper(GeoInsightsWrapper);
