import React, { useMemo } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import ColorLegend, { Orientation } from 'charts/Components/ColorLegend';
import { transformCreativeData, VizScaleBuilder } from 'charts/InsightsViz/transforms';
import { DeviceInsightsData } from 'charts/InsightsViz/types';
import { SlideIcons, VizId, DEVICE_GRAPHIC } from 'charts/InsightsViz/constants';
import {
  getPolyColor,
  extractMetadata,
  getInsightsByVizId,
  getColorLegendLabel,
  tooltipsByVizId,
  getDeliveryTextUpper,
} from 'charts/InsightsViz/utils';
import List, { ListType } from 'charts/InsightsViz/Components/List';
import SlideWrapper from 'charts/InsightsViz/Components/SlideWrapper';
import SlideTitle from 'charts/InsightsViz/Components/SlideTitle';
import VizHeader from 'charts/InsightsViz/Components/VizHeader';
import InsightsBox from '../Components/InsightsBox';
import { useInsightsContext } from '../contexts/InsightsProvider';
import DeviceContainer from './DeviceContainer';

const TOP_N = 5;
const vizId = VizId.deviceFeatureInsights;

const DeviceInsights = (props: DeviceInsightsData & { onVizLoaded: (tooltips: Array<string>) => void }) => {
  const {
    deviceSizePosition: { metadata, legend, performance: kpisWithDevicePlacementSize },
    device: { metadata: deviceMetadata, performance: kpisWithDevice },
    onVizLoaded = _.noop,
  } = props;
  const { primaryStrategyGoal: { lowerIsBetter } } = useInsightsContext();
  const usableMetadata = { ...extractMetadata(metadata), lowerIsBetter };
  const {
    primaryGoal,
    colorScaleRange,
    budgetType,
    currency,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    features,
    hasRevenueType,
  } = usableMetadata;

  const {
    value: kpiValue,
    format: kpiFormat,
    target: kpiTarget,
    text: kpiText,
    insightsLabel: kpiLongFormText,
  } = primaryGoal;
  const memoData = useMemo(() => {
    // reject creative data with unknown dimensions
    const creativeData = _.reject(
      transformCreativeData(kpisWithDevicePlacementSize),
      (d) => _.isNil(d.height) || _.isNil(d.width),
    );

    const deviceList = _.uniq(_.map(creativeData, 'deviceType'));
    const devices = _.filter(DEVICE_GRAPHIC, (__, k) => _.includes(deviceList, k));

    const vizScaleBuilder = new VizScaleBuilder({
      perfAndDeliveryData: kpisWithDevicePlacementSize,
      budgetType,
      primaryGoalSuccessEvent,
      primaryGoalOverallValue,
      excludeDataSansSuccessEvents: false,
    });
    const colorScale = vizScaleBuilder.getColorScale({ colorScaleRange, strategyGoalConfigWithTarget: primaryGoal });
    return {
      creativeData,
      devices,
      colorScale,
      meanPerf: vizScaleBuilder.meanPerf,
    };
  }, [kpisWithDevicePlacementSize, colorScaleRange, budgetType, primaryGoal, primaryGoalSuccessEvent, primaryGoalOverallValue]);

  const tooltips = tooltipsByVizId[vizId](primaryGoal.text);
  useMount(() => {
    onVizLoaded(tooltips);
  });

  return (
    <div id={vizId} className="slide">
      <SlideTitle section="Feature Insights" subSection="Device" icon={SlideIcons.deviceInsights} />
      <div className="grid-container">
        <div className="main-visualization">
          <VizHeader
            title="Which Devices Drive Performance"
            subtitle={`${kpiLongFormText || kpiText}
              & ${getDeliveryTextUpper()({ budgetType })} by Device, Ad Size and Position`}
            tooltipContent={tooltips[0]}
          />
          <div id="device-creative-placement-chart">
            <DeviceContainer
              creativeData={_.map(memoData.creativeData, (d) => ({
                ...d,
                fill: getPolyColor(d, kpiValue, memoData.colorScale, primaryGoalSuccessEvent),
              }))}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div className="viz-legend">
            <ColorLegend
              id={`${vizId}_color-legend`}
              data={{
                colorRange: colorScaleRange,
                domain: memoData.colorScale.domain(),
                format: (d) => numeral(d).format(kpiFormat),
              }}
              width={264}
              label={getColorLegendLabel(primaryGoal, currency)}
              type="discrete"
              lowerIsBetter={primaryGoal.lowerIsBetter}
              showMeanValue
              meanValue={memoData.meanPerf}
              showGoalValue
              goalValue={kpiTarget}
              orientation={Orientation.bottom}
              hasRevenueType={hasRevenueType}
            />
          </div>
          <List
            data={_.map(_.take(legend, TOP_N), (datum) => ({
              ...datum,
              creativeSize: datum.creativeSize === 'Unknown' ? 'Video' : datum.creativeSize,
            }))}
            budgetType={budgetType}
            goalMetricConfig={primaryGoal}
            type={ListType.featureCombo}
            colorScale={memoData.colorScale}
            features={features}
            currency={currency}
            primaryGoalSuccessEvent={primaryGoalSuccessEvent}
          />
        </div>
      </div>
      <InsightsBox insights={getInsightsByVizId(vizId, {
        ...extractMetadata(deviceMetadata),
        kpisWithDevice,
      })}
      />
    </div>
  );
};

export default SlideWrapper(DeviceInsights);
