import React from 'react';
import { CopilotModelType } from './types';

const CopilotModel = ({ model }: { model: CopilotModelType }) => (
  <div className="copilot-model-container">
    <div className="copilot-model-title">
      <img src={model.imageSrc} alt={model.title} />
      <h3 style={{ color: model.color }}>{model.title}</h3>
    </div>
    <p>{model.description}</p>
  </div>
);

export default CopilotModel;
