import _ from 'lodash';
import { timeFormat, timeParse, select, selectAll } from 'd3';
import domtoimage from 'dom-to-image';
import PptxGenJS from 'pptxgenjs';
import moment, { Moment } from 'moment';
import { dateTimeFormatter, createMoment, PossibleMoment } from 'utils/dateTime';
import { COPILOT_COLORS } from 'globalStyles';
import { EXPORT_PREFIX } from './ABReportViz/constants';
import { SpeakerNotes } from './InsightsViz/types';

const { NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES } } = COPILOT_COLORS;

export const parseDate = timeParse('%Y-%m-%d');
export const parseTime = timeParse('%Y-%m-%dT%H:%M:%S.000Z');
export const formatDate = timeFormat('%Y-%m-%d');
export const formatTime = timeFormat('%Y-%m-%dT%H:%M:%S.000Z');
export const formatMonthDay = timeFormat('%b %d');
export const formatMonthSpacePaddedDay = timeFormat('%b %e');
export const formatMonthDayYear = timeFormat('%b %d, %Y');

export const chartShortDate = (possibleMoment: PossibleMoment) => dateTimeFormatter.chartShortDate(possibleMoment, true);

export const createDate = (possibleDate: PossibleMoment) => createMoment(possibleDate, true);

export const createIsoDate = (possibleDate: PossibleMoment) => dateTimeFormatter.isoDate(possibleDate, true);

export const deterministicShuffle = (list) => {
  if (list.length < 2) {
    return list;
  }
  const [firstHalf, secondHalf] = _.chunk(list, Math.ceil(list.length / 2));

  return (
    _.compact(
      _.flatten(
        _.zip(deterministicShuffle(firstHalf), deterministicShuffle(secondHalf)),
      ),
    )
  );
};

export const getDimensions = (selector: string) => {
  const container = select(selector).node();
  return _.pick(container, ['clientWidth', 'clientHeight']);
};

// to be used for scaleThreshold. We need one fewer data points in domain than we have in range
export const getColorScaleDomainBuilder = (avgMetricValue: number) => (minData, maxData) => {
  const stepLo = Math.abs(avgMetricValue - minData) / 2.5;
  const stepHi = Math.abs(maxData - avgMetricValue) / 2.5;
  return [minData,
    minData + stepLo,
    minData + (2 * stepLo),
    maxData - (2 * stepHi),
    maxData - stepHi,
    maxData,
  ];
};

export const getColorScaleDomain = (avgMetricValue: number, min: number, max: number) => getColorScaleDomainBuilder(avgMetricValue)(min, max);

// z-score represents the number of standard deviations a data point is from the mean value of dataset
export const getValForZScore = (zScore: number, mean: number, stdDev: number) => {
  if (_.isNil(stdDev)) {
    const mockStdDev = Math.abs(mean - 0) / 2;
    return (zScore * mockStdDev) + mean;
  }
  return (zScore * stdDev) + mean;
};

export const getZScore = (val: number, mean: number, stdDev: number) => (val - mean) / stdDev;

// filter out tooltip icons in powerpoint export
const isNotTooltip = (domNode: HTMLElement) => (domNode.className !== 'question circle outline icon');

const createDataUrlFromElId = async (elId: string) => {
  const node = document.getElementById(elId);
  const url = await domtoimage.toPng(node, { cacheBust: true, style: { fontFamily: 'Segoe UI' }, filter: isNotTooltip });
  return [elId, url];
};

const addWatermarkToSlides = () => {
  selectAll('#insights-viz .slide')
    .append('p')
    .attr('class', 'watermark')
    .text('The primary objective of this strategy is to increase margin. Consider this when discussing with other teams or presenting to clients.');
};

const removeWatermarkFromSlides = () => {
  selectAll('#insights-viz .slide .watermark').remove();
};

export enum ReportType {
  INSIGHTS = 'insights',
  ABINSIGHTS = 'abInsights',
}

type BaseExportConfig = {
  chartIdsToExport: Array<string>
  speakerNotes: SpeakerNotes
  preExportCallbacks?: Array<() => void>
  postExportCallbacks?: Array<() => void>
  visualizationsConfig?: {
    [key: string]: {
      tooltipContent: string,
      overrideDimensions?: { w: number, h: number }
    }
  }
};
export type InsightsSpecificConfig = {
  reportType: ReportType.INSIGHTS
  stratId: number
  stratName: string
  brandOrAdvertiserName: string
  insertWatermark: boolean
  strategyCreatedAtTime: Moment
  lastDayOfData: Moment
  flightName?: string
};
export type InsightsExportConfig = BaseExportConfig & InsightsSpecificConfig;
export type ABInsightsSpecificConfig = {
  reportType: ReportType.ABINSIGHTS
  flightAId: string
  flightBId: string
  reportTitle: string
  startDate: Moment
  endDate: Moment
};
export type ABInsightsExportConfig = BaseExportConfig & ABInsightsSpecificConfig;

export const exportPPT = async (config: InsightsExportConfig | ABInsightsExportConfig) => {
  const {
    preExportCallbacks,
    postExportCallbacks,
    chartIdsToExport,
    speakerNotes,
    visualizationsConfig = {},
  } = config;
  const pptx = new PptxGenJS();
  pptx.layout = 'LAYOUT_4x3';

  _.each(preExportCallbacks, (cb) => cb());

  if ((config.reportType === ReportType.INSIGHTS) && config.insertWatermark) {
    addWatermarkToSlides();
  }

  /*

  Build title slide

  */
  const titleSlide = pptx.addSlide();
  const titleStyles = { x: 0.25, y: '42.5%', w: '95%', fontSize: 64, color: BLUES.B500_WAVE, fontFace: 'Arial' } as PptxGenJS.TextPropsOptions;
  const subtitleStyles = { x: 0.25, y: '57.5%', w: '95%', fontSize: 26, color: NEUTRALS.N600_ABBEY, bold: true, fontFace: 'Arial' } as PptxGenJS.TextPropsOptions;
  const bodyStyles = { x: 0.25, y: '65%', fontSize: 20, color: NEUTRALS.N600_ABBEY, fontFace: 'Arial' } as PptxGenJS.TextPropsOptions;
  const lineStyles = { x: 0.25, y: '50%', w: '95%', h: 0, line: { color: NEUTRALS.N300_ALTO, width: 1.5, fontFace: 'Arial' } } as PptxGenJS.ShapeProps;
  const logoStyles = { w: 1.5, h: 0.36923, x: 0.25, y: 0.25 };

  if (config.reportType === ReportType.INSIGHTS) {
    titleSlide.addText(config.brandOrAdvertiserName, subtitleStyles);
    if (config.flightName) {
      titleSlide.addText(_.truncate(config.flightName, { length: 100 }), bodyStyles);
    }
    titleSlide.addText(`${dateTimeFormatter.isoDate(config.strategyCreatedAtTime)}   -   ${dateTimeFormatter.isoDate(config.lastDayOfData)}`, { ...bodyStyles, y: '75%' });
    titleSlide.addText('Insights', titleStyles);
  } else {
    titleSlide.addText('A/B Insights', titleStyles);
    titleSlide.addText(config.reportTitle, subtitleStyles);
    titleSlide.addText(`Start Date: ${dateTimeFormatter.isoDate(config.startDate)}`, bodyStyles);
    titleSlide.addText(`End Date: ${dateTimeFormatter.isoDate(config.endDate)}`, { ...bodyStyles, y: '70%' });
  }
  // @ts-ignore issue with props
  titleSlide.addImage({ path: '/img/copilot-logo-new.png', ...logoStyles });
  titleSlide.addShape(pptx.ShapeType.line, lineStyles);

  const promises = _.map(chartIdsToExport, createDataUrlFromElId);
  const dataUrls = await Promise.all(promises);

  _.each(dataUrls, ([elId, dataUrl]) => {
    const s = pptx.addSlide();
    const speakerNotesForSlide = _.get(speakerNotes, elId);

    const { w, h } = _.get(visualizationsConfig, `${_.replace(elId, EXPORT_PREFIX, '')}.overrideDimensions`, { w: 10, h: 7.5 }) as { w: number, h: number };
    s.addImage({ h, w, data: dataUrl });
    if (speakerNotesForSlide) {
      _.each(speakerNotesForSlide, (note) => {
        s.addNotes(`${note}\n\n`);
      });
    }
  });

  const filename = (config.reportType === ReportType.INSIGHTS)
    ? `Copilot_Insights_${config.stratId}_${config.stratName}_${dateTimeFormatter.isoDate(moment())}`
    : `Copilot_ABInsights_${config.flightAId}_${config.flightBId}_${dateTimeFormatter.isoDate(moment())}`;

  // @ts-ignore issue with props
  await pptx.writeFile(filename);

  if ((config.reportType === ReportType.INSIGHTS) && config.insertWatermark) {
    removeWatermarkFromSlides();
  }

  _.each(postExportCallbacks, (cb) => cb());
};

// if Copilot was attached < 3 days ago, just hide relevant visualizations
export const notEnoughData = (flightAttachmentTime: Moment) => {
  const now = moment().utc();
  return now.diff(flightAttachmentTime, 'days') < 3;
};
