import _ from 'lodash';
import React from 'react';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import DspSummary from './DspSummary';
import { formatDspOptData, getCrossPlatformOptSummaryArrow } from './utils';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { getLastDayOfDataByKey } from '../utils';

const CrossPlatformOptSummaryViz = () => {
  const {
    dspData,
    metadata: { attachments },
    budgetType,
    strategy: { config: { intelligentChildObjects } },
    viewabilityGoal,
  } = useInsightsContext();

  const formattedDspOptData = formatDspOptData(
    getLastDayOfDataByKey(dspData.cumData, 'dsp'),
    attachments,
    BudgetTypeMapping[budgetType],
  );
  const platformCount = _.size(formattedDspOptData);

  return (
    <div className="cross-platform-opt-summary-container">
      <div className="copilot-div">Copilot</div>
      <div className="budget-opt-div">Budget Optimization</div>
      <img src={getCrossPlatformOptSummaryArrow(platformCount)} alt="cross-platform opt summary arrow" />
      <div className="dsp-summaries" style={{ ...(platformCount === 1 && { justifyContent: 'center' }) }}>
        {_.map(formattedDspOptData, (dspInfo) => (
          <DspSummary
            key={dspInfo.name}
            dspInfo={dspInfo}
            intelligentChildObjects={intelligentChildObjects}
            viewabilityEnabled={!!viewabilityGoal}
            platformCount={platformCount}
          />
        ))}
      </div>
    </div>
  );
};

export default CrossPlatformOptSummaryViz;
