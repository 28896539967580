import { Options } from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { StrategyGoalAnalyticsDatum } from 'containers/StrategyAnalytics/types';
import { UNOPTIMIZED_GOAL_KEY, areaSplineColors } from '../constants';
import { CumulativeDailyDatum, PrimaryStrategyGoal } from '../types';
import { findIntersectionOnChart, getMinValue, getMaxValue, getPrimaryGoalKey } from '../utils';

const { transparent, white, green, lineGreen, graphGrey, lineRed } = areaSplineColors;

const calculateGraphData = (cumulativePerfData, goalKpiKey, unoptimizedGoalKey, lowerIsBetter) => {
  const [optimizedRange, unOptimizedRange, optimizedColorZones, unOptimizedColorZones] = [[], [], [], []];
  let isUnOptimizedHigher = true;

  _.each(cumulativePerfData, (data, index: number) => {
    const date = moment.utc(data.date);
    const optimizedValue = _.get(data, goalKpiKey, null);
    const unOptimizedValue = _.get(data, unoptimizedGoalKey, null);

    optimizedRange.push([date, optimizedValue]);
    unOptimizedRange.push([date, unOptimizedValue]);

    const previous = cumulativePerfData[index - 1];
    const value = findIntersectionOnChart(
      index - 1,
      index,
      _.get(previous, unoptimizedGoalKey, 0),
      unOptimizedValue,
      _.get(previous, goalKpiKey, 0),
      optimizedValue,
    );
    // fill color goes to bottom of the graph if theres not a large enough differents and the lines overlap
    // must be at least a 1% difference
    if ((Math.abs(optimizedValue - unOptimizedValue) / _.mean([optimizedValue, unOptimizedValue])) > 0.01) {
      if (unOptimizedValue < optimizedValue && isUnOptimizedHigher) {
        unOptimizedColorZones.push({ value, fillColor: lowerIsBetter ? white : transparent });
        optimizedColorZones.push({ value, fillColor: lowerIsBetter ? green : transparent });
        isUnOptimizedHigher = false;
      }
      if (unOptimizedValue > optimizedValue && !isUnOptimizedHigher) {
        unOptimizedColorZones.push({ value, fillColor: lowerIsBetter ? transparent : white });
        optimizedColorZones.push({ value, fillColor: lowerIsBetter ? transparent : green });
        isUnOptimizedHigher = true;
      }
    }
  });

  const dataLength = cumulativePerfData.length;
  if (isUnOptimizedHigher) {
    unOptimizedColorZones.push({ value: dataLength, fillColor: lowerIsBetter ? green : transparent });
    optimizedColorZones.push({ value: dataLength, fillColor: lowerIsBetter ? white : transparent });
  } else if (!lowerIsBetter) {
    optimizedColorZones.push({ value: dataLength, fillColor: green });
    unOptimizedColorZones.push({ value: dataLength, fillColor: white });
  } else {
    unOptimizedColorZones.push({ value: dataLength, fillColor: transparent });
    optimizedColorZones.push({ value: dataLength, fillColor: transparent });
  }
  return [optimizedRange, unOptimizedRange, optimizedColorZones, unOptimizedColorZones];
};

const getDailySeries = (daily, shortText) => ({
  name: `Daily ${shortText}`,
  type: 'spline',
  data: daily,
  zIndex: 100,
  color: lineGreen,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: false,
      },
    },
  },
  lineWidth: 1,
});

const getOptimizedSeries = (cumulativeOptimized, optimizedColorZones, shortText) => ({
  name: `Cumulative ${shortText}`,
  data: cumulativeOptimized,
  type: 'areaspline',
  color: lineGreen,
  zIndex: 0,
  marker: {
    states: {
      hover: {
        enabled: false,
      },
    },
    symbol: 'circle',
    radius: 3,
  },
  lineWidth: 2,
  showInLegend: false,
  zoneAxis: 'x',
  zones: optimizedColorZones,
});

const getUnoptimizedSeries = (cumulativeUnOptimized, unOptimizedColorZones, shortText) => ({
  name: `Est. ${shortText} without Copilot`,
  data: cumulativeUnOptimized,
  type: 'areaspline',
  color: lineRed,
  fillColor: white,
  zIndex: 0,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: false,
      },
    },
  },
  lineWidth: 1,
  dashStyle: 'Dash',
  showInLegend: false,
  zoneAxis: 'x',
  zones: unOptimizedColorZones,
});

const getAdditionalLegendItems = (shortText) => {
  const cumData = {
    name: `Cumulative ${shortText}`,
    data: null,
    type: 'spline',
    color: lineGreen,
    zIndex: 0,
    lineWidth: 1,
    marker: {
      symbol: 'circle',
      radius: 3,
    },
  };
  const estData = {
    name: `Est. ${shortText} without Copilot`,
    data: null,
    type: 'spline',
    color: lineRed,
    zIndex: 0,
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    lineWidth: 1,
    dashStyle: 'Dash',
  };
  return [cumData, estData];
};

const getGoalSeries = (goalValue: number, shortText: string, revenueType?: string | null) => ({
  name: revenueType ? `Revenue ${_.toUpper(revenueType)}` : `Goal ${shortText}`,
  data: goalValue,
  type: 'spline',
  color: graphGrey,
  zIndex: 5,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: false,
      },
    },
  },
  lineWidth: 1,
  dashStyle: 'Dash',
});

const getChartSeries = (
  daily,
  cumulativeOptimized,
  cumulativeUnOptimized,
  goalValue,
  optimizedColorZones,
  unOptimizedColorZones,
  shortText,
  lowerIsBetter,
  revenueType,
) => {
  const dailySeries = getDailySeries(daily, shortText);
  const optimizedSeries = getOptimizedSeries(cumulativeOptimized, optimizedColorZones, shortText);
  const unOptimizedSeries = getUnoptimizedSeries(cumulativeUnOptimized, unOptimizedColorZones, shortText);
  const additionalLegendItems = getAdditionalLegendItems(shortText);
  const goalSeries = getGoalSeries(goalValue, shortText, revenueType);

  if (lowerIsBetter) {
    return [dailySeries, unOptimizedSeries, optimizedSeries, ...additionalLegendItems, goalSeries];
  }
  return [dailySeries, optimizedSeries, unOptimizedSeries, ...additionalLegendItems, goalSeries];
};

const performanceChartConfig = (series, title, minY, maxY): Options => ({
  chart: {
    height: 245,
    width: 846,
    animation: false,
    style: { fontFamily: 'Gilroy' },
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      shadow: false,
      states: {
        hover: {
          enabled: false,
        },
      },
      events: {
        legendItemClick: () => false,
      },
    },
  },
  legend: {
    itemStyle: {
      fontWeight: '400',
      fontSize: '10px',
      color: graphGrey,
    },
    symbolWidth: 35,
    margin: 20,
    itemHoverStyle: {
      color: graphGrey,
      cursor: 'default',
    },
  },
  xAxis: {
    title: {
      text: null,
    },
    lineWidth: 0,
    minorGridLineWidth: 0,
    labels: {
      enabled: false,
    },
    minorTickLength: 0,
    tickLength: 0,
    lineColor: 'transparent',
    visible: false,
  },
  yAxis: {
    title: {
      text: title,
      align: 'middle',
      rotation: -90,
      y: 5,
      style: {
        fontSize: '12px',
        fontWeight: '500',
      },
    },
    tickWidth: 1,
    gridLineColor: 'transparent',
    min: minY,
    max: maxY,
  },
  series,
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
});

type PerformanceChartData = {
  cumulativePerfData: Array<CumulativeDailyDatum>
  dailyPerfData: Array<StrategyGoalAnalyticsDatum>
  primaryStrategyGoal: PrimaryStrategyGoal
  currency: string
  revenueType: string | null
};

const PerformanceChart = (props: PerformanceChartData) => {
  const {
    dailyPerfData,
    cumulativePerfData,
    primaryStrategyGoal,
    currency,
    revenueType,
  } = props;
  const { valueType, target: goalTarget, lowerIsBetter } = primaryStrategyGoal;
  const shortText = primaryStrategyGoal.shortText ?? primaryStrategyGoal.displayName;
  const yAxisTitle = valueType === GOAL_VALUE_TYPE.CURRENCY ? `${_.upperCase(shortText)} (${currency})` : _.upperCase(shortText);
  const goalKpiKey = getPrimaryGoalKey(_.head(dailyPerfData));
  const daily = _.map(dailyPerfData, (i) => [moment.utc(i.date), i[goalKpiKey] ?? 0]);
  const goalSeries = _.map(dailyPerfData, (i) => [moment.utc(i.date), goalTarget]);
  const [
    cumulativeOptimized, cumulativeUnOptimized, optimizedColorZones, unOptimizedColorZones,
  ] = calculateGraphData(cumulativePerfData, goalKpiKey, UNOPTIMIZED_GOAL_KEY, lowerIsBetter);

  const minValue = Math.min(getMinValue(daily), getMinValue(cumulativeOptimized), getMinValue(cumulativeUnOptimized), goalTarget);
  const maxValue = Math.max(getMaxValue(daily), getMaxValue(cumulativeOptimized), getMaxValue(cumulativeUnOptimized), goalTarget);

  const series = getChartSeries(
    daily,
    cumulativeOptimized,
    cumulativeUnOptimized,
    goalSeries,
    optimizedColorZones,
    unOptimizedColorZones,
    shortText,
    lowerIsBetter,
    revenueType,
  );

  return (
    <ReactHighcharts
      config={performanceChartConfig(series, yAxisTitle, minValue, maxValue)}
      isPureConfig
    />
  );
};

export default PerformanceChart;
