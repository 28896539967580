import * as d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import { COLORS } from 'charts/constants';
import { DSP } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import {
  BudgetOptOverviewAggLevel, BudgetOptOverviewVizDataType, GROUP_CONTAINER_HEIGHT, GROUP_CONTAINER_WIDTH,
  GROUP_TO_SUB_CONTAINER_LINE_LENGTH, SUB_CONTAINER_HEIGHT, SUB_CONTAINER_WIDTH, GOAL_PERFORMANCE_INDICATOR_WIDTH,
} from './constants';
import { useInsightsContext } from '../contexts/InsightsProvider';
import {
  createTooltip, getChildDisplayName, getParentDisplayName, getTextLinesWithEllipsis,
  mouseMove, mouseOut, mouseOver, pluralizeFlightName, truncateText,
} from '../utils';

const { NEW_DESIGN_SYSTEM: { DSP: dspColors, NEUTRALS: { N200_INSIGHTS } } } = COPILOT_COLORS;

const getVizWidth = (isCrossPlatform: boolean, intelligentChildObjects: boolean): number => {
  if (isCrossPlatform) {
    return intelligentChildObjects ? 768 : 644;
  }
  return intelligentChildObjects ? 830 : 710;
};
const VIZ_HEIGHT = 583;

const getGroupContainerXPosition = (isCrossPlatform: boolean, intelligentChildObjects: boolean, mainObjContainerWidth: number): number => {
  if (isCrossPlatform) {
    return 294 + mainObjContainerWidth;
  }
  return (intelligentChildObjects ? 251 : 255) + mainObjContainerWidth;
};

const pathCreator = d3.line().curve(d3.curveCatmullRom.alpha(1));
const verticalCenteringFactor = (scale: d3.ScaleBand<string>) => (scale.bandwidth() / 2);

const createCurvePoints = (
  idx: string,
  start: number,
  groupStrokeThicknessArr: Array<number>,
  groupXPosition: number,
  yScale: d3.ScaleBand<string>,
  mainObjContainerWidth: number,
): Array<[number, number]> => {
  const index = _.toNumber(idx);
  const previousLinesThickness = _.sum(_.take(groupStrokeThicknessArr, index));
  const lineThickness = groupStrokeThicknessArr[index];
  const startingPosition = start + previousLinesThickness + (lineThickness * 0.5);
  return [
    // main obj position
    [0, startingPosition],
    // inflection point 1 - 40px right of the main obj position
    [mainObjContainerWidth * 0.65, startingPosition],
    // inflection point 2 - 56px before the group obj position
    [groupXPosition - 56, yScale(idx) + verticalCenteringFactor(yScale)],
    // group obj position
    [groupXPosition, yScale(idx) + verticalCenteringFactor(yScale)],
  ];
};

const buildViz = (
  data: Array<BudgetOptOverviewVizDataType>,
  getDeliveryLineScale: (del: number) => number,
  colorScale: d3.ScaleThreshold<number, string>,
  aggLevel: BudgetOptOverviewAggLevel,
  aggDisplayText: string,
  parentDisplayName: string,
  childDisplayName: string,
  intelligentChildObjects: boolean,
  isCrossPlatform: boolean,
  parentFlightName: string, // only necessary for single platform main obj display
) => {
  const vizContainer = d3.select('.budget-opt-overview-viz-container');
  const svgContainer = d3.select('.budget-opt-overview-viz');
  const totalDelivery = _.sumBy(data, 'delivery');
  const mainObjContainerWidth = isCrossPlatform ? 45 : 150;
  const mainObjContainerHeight = isCrossPlatform ? 269 : 136;
  const groupXPosition = getGroupContainerXPosition(isCrossPlatform, intelligentChildObjects, mainObjContainerWidth);
  const yScaleDomain = _.map(data, (_d, idx) => _.toString(idx));

  // y positioning of each group object
  const yScale = d3.scaleBand<string>()
    .domain(yScaleDomain)
    .range([0, VIZ_HEIGHT]);

  const groupStrokeThicknessArr = _.map(data, (d) => getDeliveryLineScale(d.delivery));

  // build the group obj delivery lines
  const deliveryLinesContainer = svgContainer.append('g')
    .attr('id', 'link-container');

  deliveryLinesContainer.selectAll('.link')
    .data(data)
    .enter()
    .append('g')
    .each((item: BudgetOptOverviewVizDataType, idx, svg) => {
      const { delivery, cloneDel, origDel } = item;
      const yScaleKey = _.toString(idx);
      const group = d3.select(svg[idx]);
      const start = (VIZ_HEIGHT - _.sum(groupStrokeThicknessArr)) / 2;

      // append path for delivery to group item
      // paths are entirely underneath main obj container
      group.append('path')
        .attr('stroke', N200_INSIGHTS)
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.7)
        .attr('stroke-width', getDeliveryLineScale(delivery))
        .attr('d', pathCreator(createCurvePoints(yScaleKey, start, groupStrokeThicknessArr, groupXPosition, yScale, mainObjContainerWidth)));

      // append delivery label
      const deliveryPercentage = _.round((delivery / totalDelivery) * 100);
      group.append('text')
        .attr('class', 'delivery-label')
        .text(totalDelivery === 0 ? 'N/A' : `${deliveryPercentage === 0 ? '< 1' : deliveryPercentage}%`)
        .attr('transform', `translate(${groupXPosition - 8}, ${yScale(yScaleKey) + verticalCenteringFactor(yScale) - (idx === 0 ? 4 : 0)})`); // add offset for delivery label text under first item

      if (intelligentChildObjects) {
      // append path for delivery of orig
        group.append('line')
          .attr('stroke', N200_INSIGHTS)
          .attr('stroke-opacity', 0.7)
          .attr('stroke-width', getDeliveryLineScale(origDel))
          .attr('x1', groupXPosition + GROUP_CONTAINER_WIDTH)
          .attr('x2', groupXPosition + GROUP_CONTAINER_WIDTH + GROUP_TO_SUB_CONTAINER_LINE_LENGTH)
          .attr('y1', yScale(yScaleKey) + verticalCenteringFactor(yScale) - SUB_CONTAINER_HEIGHT / 2 - 2)
          .attr('y2', yScale(yScaleKey) + verticalCenteringFactor(yScale) - SUB_CONTAINER_HEIGHT / 2 - 2);

        // append path for delivery of clones
        group.append('line')
          .attr('stroke', N200_INSIGHTS)
          .attr('stroke-opacity', 0.7)
          .attr('stroke-width', getDeliveryLineScale(cloneDel))
          .attr('x1', groupXPosition + GROUP_CONTAINER_WIDTH)
          .attr('x2', groupXPosition + GROUP_CONTAINER_WIDTH + GROUP_TO_SUB_CONTAINER_LINE_LENGTH)
          .attr('y1', yScale(yScaleKey) + verticalCenteringFactor(yScale) + SUB_CONTAINER_HEIGHT / 2 + 2)
          .attr('y2', yScale(yScaleKey) + verticalCenteringFactor(yScale) + SUB_CONTAINER_HEIGHT / 2 + 2);
      }
    });

  // add delivery text under the first group's percentage label
  deliveryLinesContainer.append('text')
    .attr('class', 'delivery-label')
    .text('delivery')
    .attr('transform', `translate(${groupXPosition - 8}, ${yScale('0') + verticalCenteringFactor(yScale) + 4})`);

  // add main obj rect and text based on strategy type
  svgContainer.append('g')
    .attr('class', 'main-obj-container')
    .call((g) => {
      g.style('transform', `translateY(${(VIZ_HEIGHT - mainObjContainerHeight) / 2}px)`);

      g.append('rect')
        .attr('fill', isCrossPlatform ? COLORS.WAVE : COLORS.WHITE)
        .attr('stroke', isCrossPlatform ? COLORS.WAVE : COLORS.LIGHT_GREY)
        .attr('width', mainObjContainerWidth)
        .attr('height', mainObjContainerHeight);

      if (isCrossPlatform) {
        g.append('text')
          .attr('id', 'main-obj-title-cross-platform')
          .text('Copilot')
          .attr('transform', `translate(${(mainObjContainerWidth + 12) / 2}, ${mainObjContainerHeight / 2})rotate(270)`);
      } else {
        g.append('text')
          .attr('id', 'main-obj-title-single-platform')
          .text(parentDisplayName);

        g.append('g')
          .attr('class', 'main-obj-parent-name')
          .selectAll('.chunk')
          .data(getTextLinesWithEllipsis(parentFlightName, 18, 6))
          .join((chunk) => chunk.append('text')
            .attr('class', 'chunk')
            .text((c) => _.join(c, ''))
            .attr('transform', (_d, idx) => `translate(12, ${(idx * 14) + 36.5})`));

        // add tooltip if parent name is truncated
        if (_.size(parentFlightName) > (18 * 6)) {
          const parentNameTooltip = createTooltip(vizContainer, parentFlightName);

          d3.select('g.main-obj-parent-name')
            .on('mouseover', () => mouseOver(parentNameTooltip))
            .on('mousemove', () => mouseMove(vizContainer, parentNameTooltip))
            .on('mouseout', () => mouseOut(parentNameTooltip));
        }
      }
    });

  // build group containers and sub containers
  svgContainer.append('g')
    .attr('class', 'group-container')
    .selectAll('g')
    .data(data)
    .join((enter) => enter
      .append('g')
      .attr('id', (d) => d.name)
      .attr('transform', (_d, idx) => `translate(${groupXPosition}, ${yScale(_.toString(idx)) + verticalCenteringFactor(yScale) - (GROUP_CONTAINER_HEIGHT / 2)})`)
      .each((item: BudgetOptOverviewVizDataType, idx, svg) => {
        const { name, origCount, cloneCount, goalPerf, origGoalPerf, cloneGoalPerf } = item;
        const isOverflow = _.get(item, 'isOverflow'); // overflow group will be the only group with isOverflow key
        const group = d3.select(svg[idx]);

        // create group container
        group.append('rect')
          .attr('width', GROUP_CONTAINER_WIDTH)
          .attr('height', GROUP_CONTAINER_HEIGHT);

        // add title label
        if (aggLevel === BudgetOptOverviewAggLevel.platform && !isOverflow) {
          const code = DSP.getById(item.dsp).code;
          const { dark, light } = dspColors[item.dsp];
          group.append('foreignObject')
            .attr('class', 'platform-badge')
            .attr('transform', 'translate(12, 12)')
            .append('xhtml:div')
            .style('color', dark)
            .style('background-color', light)
            .html(code);
        } else {
          group.append('text')
            .attr('class', 'group-container-title')
            .attr('id', `group-title-${idx}`)
            .text((aggLevel === BudgetOptOverviewAggLevel.budgetGroup || isOverflow) ? truncateText(name, 39) : childDisplayName)
            .attr('transform', 'translate(12, 12)');

          // add tooltip if group title name is truncated
          if ((aggLevel === BudgetOptOverviewAggLevel.budgetGroup || isOverflow) && _.size(name) > 39) {
            const groupTooltip = createTooltip(vizContainer, name);

            d3.select(`#group-title-${idx}`)
              .on('mouseover', () => mouseOver(groupTooltip))
              .on('mousemove', () => mouseMove(vizContainer, groupTooltip))
              .on('mouseout', () => mouseOut(groupTooltip));
          }
        }

        // add sub label to group container
        if (aggLevel === BudgetOptOverviewAggLevel.child && !isOverflow) {
          group.append('g')
            .attr('class', 'group-container-sub-label')
            .attr('id', `group-sub-${idx}`)
            .selectAll('.chunk')
            .data(getTextLinesWithEllipsis(name, 40, 2))
            .join((chunk) => chunk.append('text')
              .attr('class', 'chunk')
              .text((c) => c))
            .attr('transform', (_d, i) => `translate(12, ${(i * 14) + 33})`);

          // add tooltip if child name is truncated
          if (_.size(name) > (40 * 2)) {
            const childNameTooltip = createTooltip(vizContainer, name);

            d3.select(`g#group-sub-${idx}`)
              .on('mouseover', () => mouseOver(childNameTooltip))
              .on('mousemove', () => mouseMove(vizContainer, childNameTooltip))
              .on('mouseout', () => mouseOut(childNameTooltip));
          }
        } else {
          const subLabel = isOverflow
            ? `*Excess ${aggDisplayText}s aggregated`
            : `${origCount + cloneCount} ${childDisplayName}${pluralizeFlightName(origCount + cloneCount)}`;
          group.append('text')
            .attr('class', 'group-container-sub-label')
            .text(subLabel)
            .attr('transform', `translate(12, ${aggLevel === BudgetOptOverviewAggLevel.budgetGroup ? 33 : 40})`);
        }

        // add goal performance color indicator to the group
        group.append('path')
          .style('transform', `translateX(${GROUP_CONTAINER_WIDTH - GOAL_PERFORMANCE_INDICATOR_WIDTH}px)`)
          .attr('d', 'M0 0V0C4.41828 0 8 3.58172 8 6V65C8 69.4183 4.41828 73 0 73V73V0Z')
          .attr('fill', _.isNil(goalPerf) ? N200_INSIGHTS : colorScale(goalPerf));

        // create sub containers - add text and add goal performance color indicator
        if (intelligentChildObjects) {
          // original
          const original = group.append('g')
            .attr('transform', `translate(${GROUP_CONTAINER_WIDTH + GROUP_TO_SUB_CONTAINER_LINE_LENGTH}, 4)`);

          original.append('rect')
            .attr('class', 'sub-container orig')
            .attr('width', SUB_CONTAINER_WIDTH)
            .attr('height', SUB_CONTAINER_HEIGHT);

          original.append('text')
            .attr('class', 'sub-container-text')
            .text(`${aggLevel === BudgetOptOverviewAggLevel.child ? '' : `${origCount} `}Original`)
            .attr('transform', `translate(8, ${SUB_CONTAINER_HEIGHT / 2})`);

          original.append('path')
            .attr('d', 'M0 0C4.41828 0 8 3.58172 8 8V22C8 26.4183 4.41828 30 0 30V0Z')
            .attr('fill', _.isNil(origGoalPerf) ? N200_INSIGHTS : colorScale(origGoalPerf))
            .attr('transform', `translate(${SUB_CONTAINER_WIDTH - GOAL_PERFORMANCE_INDICATOR_WIDTH})`);

          // clone
          const clone = group.append('g')
            .attr('transform', `translate(${GROUP_CONTAINER_WIDTH + GROUP_TO_SUB_CONTAINER_LINE_LENGTH}, ${SUB_CONTAINER_HEIGHT + 8})`);

          clone.append('rect')
            .attr('class', 'sub-container clone')
            .attr('width', SUB_CONTAINER_WIDTH)
            .attr('height', SUB_CONTAINER_HEIGHT);

          clone.append('text')
            .attr('class', 'sub-container-text')
            .text(`${aggLevel === BudgetOptOverviewAggLevel.child ? '' : `${cloneCount} `}Intelligent`)
            .attr('transform', `translate(8, ${SUB_CONTAINER_HEIGHT / 2})`);

          clone.append('path')
            .attr('d', 'M0 0C4.41828 0 8 3.58172 8 8V22C8 26.4183 4.41828 30 0 30V0Z')
            .attr('fill', _.isNil(cloneGoalPerf) ? N200_INSIGHTS : colorScale(cloneGoalPerf))
            .attr('transform', `translate(${SUB_CONTAINER_WIDTH - GOAL_PERFORMANCE_INDICATOR_WIDTH})`);
        }
      }));
};

type BudgetOptOverviewVizProps = {
  data: Array<BudgetOptOverviewVizDataType>
  getDeliveryLineScale: (del: number) => number
  colorScale: d3.ScaleThreshold<number, string>
  aggLevel: BudgetOptOverviewAggLevel
  aggDisplayText: string
  isCrossPlatform: boolean
};

const BudgetOptOverviewViz = (props: BudgetOptOverviewVizProps) => {
  const { data, getDeliveryLineScale, colorScale, aggLevel, aggDisplayText, isCrossPlatform } = props;
  const {
    strategy: {
      config: { intelligentChildObjects },
      strategyType: { dsp },
    },
    metadata: { budgetSettings },
  } = useInsightsContext();

  useMount(() => buildViz(
    data,
    getDeliveryLineScale,
    colorScale,
    aggLevel,
    aggDisplayText,
    getParentDisplayName(dsp),
    getChildDisplayName(dsp),
    intelligentChildObjects,
    isCrossPlatform,
    _.get(_.head(budgetSettings), 'parentSettings.name'),
  ));

  return (
    <div className="budget-opt-overview-viz-container">
      <svg className="budget-opt-overview-viz" width={getVizWidth(isCrossPlatform, intelligentChildObjects)} height={VIZ_HEIGHT} />
    </div>
  );
};

export default BudgetOptOverviewViz;
