import React from 'react';
import { DeviceImageComponentProps } from '../types';

export default ({ x = 0, y = 0 }: DeviceImageComponentProps) => (
  <svg width="295" height="155" viewBox="0 0 295 155" fill="none" xmlns="http://www.w3.org/2000/svg" x={x} y={y}>
    <g clipPath="url(#clip0)">
      <path d="M259.524 8.38525H35.3989V132.1H259.524V8.38525Z" stroke="black" strokeWidth="0.25" />
      <path d="M267.355 0.247559H27.7222V141.618H267.355V0.247559Z" stroke="black" strokeWidth="0.25" />
      <path d="M18.7994 154.072L3.67627 149.773L291.553 149.927L276.199 154.072H18.7994Z" stroke="black" strokeWidth="0.25" />
      <path d="M4.1372 149.85C2.69867 149.612 2.01013 149.265 0.989746 148.238V143.862C1.78556 142.846 2.49011 142.453 4.1372 142.02H118.52M118.52 142.02V144.169C118.906 144.965 119.21 145.239 119.902 145.474H175.251C175.909 145.236 176.229 144.971 176.71 144.246L176.633 142.02M118.52 142.02H176.633M118.52 142.02C118.978 142.561 119.229 142.734 119.672 142.787H175.405C175.952 142.673 176.214 142.484 176.633 142.02M291.169 149.773C292.408 149.462 293.032 149.181 294.01 148.468V143.939C293.408 142.872 292.798 142.44 291.169 142.02H176.633" stroke="black" strokeWidth="0.25" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="295" height="154.43" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
