import { color } from 'd3-color';
import { COPILOT_COLORS } from 'globalStyles';
import { ModelName } from './InsightsViz/Components/ModelBadgeAndDescription';
import { deterministicShuffle } from './utils';

const { NEW_DESIGN_SYSTEM: { BLUES, NEUTRALS } } = COPILOT_COLORS;

export const PASTEL_RED = '#ff8a80';
export const PASTEL_HOTPINK = '#ff80ab';
export const PASTEL_PINK = '#ea80fc';
export const PASTEL_PURPLE = '#B388ff';
export const PASTEL_DARKBLUE = '#8c9eff';
export const PASTEL_BLUE = '#82b1ff';
export const PASTEL_LIGHTBLUE = '#80d8ff';
export const PASTEL_CYAN = '#84ffff';
export const PASTEL_TEAL = '#a7ffeb';
export const PASTEL_GREEN = '#b9f6ca';
export const PASTEL_LIMEGREEN = '#ccff90';
export const PASTEL_YELLOWGREEN = '#f4ff81';
export const PASTEL_YELLOW = '#ffff8d';
export const PASTEL_BEIGE = '#ffe57f';
export const PASTEL_ORANGE = '#ffd180';

export const PASTEL_COLOR_PALETTE = [
  PASTEL_RED,
  PASTEL_HOTPINK,
  PASTEL_PINK,
  PASTEL_PURPLE,
  PASTEL_DARKBLUE,
  PASTEL_BLUE,
  PASTEL_LIGHTBLUE,
  PASTEL_CYAN,
  PASTEL_TEAL,
  PASTEL_GREEN,
  PASTEL_LIMEGREEN,
  PASTEL_YELLOWGREEN,
  PASTEL_YELLOW,
  PASTEL_BEIGE,
  PASTEL_ORANGE,
];

export const CATEGORICAL_COLORS = deterministicShuffle(PASTEL_COLOR_PALETTE);

export const COLORS = {
  GREYS: {
    NO_DATA: '#D5DBDD',
    200: '#D5DBDD',
    300: 'hsla(192, 7%, 75%, 1)',
    500: 'hsl(192,5%,42%)',
    700: '#394446',
  },
  TRANSPARENT_WHITE: 'rgba(255, 255, 255, 0)',
  WHITE: NEUTRALS.N0_WHITE,
  AREA_GREEN: 'rgba(152, 215, 61, 0.3)',
  LINE_RED: 'rgba(255, 0, 0, 0.8)',
  LINE_GREEN: '#51C438',
  LIGHT_BLUE: BLUES.B200_LAGOON,
  LIGHT_GREY: '#EAEFF0',
  LINE_GREY: '#808080',
  WAVE: BLUES.B500_WAVE,
  MODELS: {
    [ModelName.budgetOpt]: { START: COPILOT_COLORS.orange, STOP: '#E54400' },
    [ModelName.intelligentFlights]: { START: BLUES.B500_WAVE, STOP: color(BLUES.B500_WAVE).darker() },
    [ModelName.viewabilityOpt]: { START: '#5741A7', STOP: '#30245C' },
    [ModelName.bidOpt]: { START: '#C63979', STOP: '#8B2855' },
  },
};

export enum Spacing {
  s2 = 2,
  s4 = 4,
  s8 = 8,
  s12 = 12,
  s16 = 16,
  s24 = 24,
  s32 = 32,
  s36 = 36,
  s48 = 48,
  s64 = 64,
  s128 = 128,
  s154 = 154,
  s192 = 192,
  s256 = 256,
  s312 = 312,
  s872 = 872,
}

export const COLORS_PINK_TO_GREEN = ['#D4667D', '#E69F9B', '#F3D5BA', '#D5DBAF', '#AEB67F', '#689546', '#017400'];

export const KPI_TO_SUCCESS_EVENTS = {
  cpa: 'conversions',
  conversionRate: 'conversions',
  // new below
  conversion_rate: 'conversions',
  cpc: 'clicks',
  ctr: 'clicks',
  cpcv: 'videoCompletes',
  completionRate: 'videoCompletes',
  ivrMeasured: 'viewableImpressions',
};

export const MARGIN_GOAL_NOTE = 'The primary objective of this strategy is to increase margin. Consider this when discussing with other teams or presenting to clients.';
