import React, { ComponentType, FunctionComponentElement, useCallback, useEffect } from 'react';
import _ from 'lodash';
import Loader from 'charts/Components/Loader';
import { InsightsState } from 'charts/InsightsViz/types';
import ErrorBoundary from 'components/ErrorBoundary';
import { PossibleStates } from 'utils/hooks/useFetcher';
import ErrorSegment from './ErrorSegment';

type InputProps = {
  onVizLoaded: (...args: Array<any>) => void
  onVizError: () => void
  dataFetchStates?: Array<InsightsState>
  insightsAnalyticsDataFetchState?: InsightsState
  placeholderConfig?: {
    content: FunctionComponentElement<any>
    // error message that indicates when to show placeholder content, e.g. "No matching country" for Geo Placeholder
    messageStr: string
  }
  isYouTube?: boolean
  hasGeoData?: boolean
  shouldRenderIntTargetingViz?: boolean
};

const withRenderData = <P extends {}>(WrappedComponent: ComponentType<P>) => (props: InputProps) => {
  const {
    dataFetchStates,
    onVizLoaded,
    onVizError,
    placeholderConfig = { content: undefined, messageStr: undefined },
    ...passThroughProps
  } = props;

  const allHaveData = _.every(dataFetchStates, (s) => s.kind === PossibleStates.hasData);
  const someErrors = _.some(dataFetchStates, (s) => s.kind === PossibleStates.error);
  const showPlaceholder = !!placeholderConfig.content && (
    // we are looking for a certain string in the error message to indicate when this placeholder component is appropriate to show
    _.some(dataFetchStates, (s) => s.kind === PossibleStates.error && _.includes(_.toString(s.errorObject), placeholderConfig.messageStr))
  );

  useEffect(() => {
    if (someErrors && !showPlaceholder) {
      onVizError();
    }
  }, [someErrors, onVizError, showPlaceholder]);

  /*
    "getPayloads" transforms the dataFetchStates, which will look like:
    [
      { kind: PossibleStates.hasData, data: { budgetOpt: { metadata: {...}, budgetOptHierarchy: {...}} }),
      { kind: PossibleStates.hasData, data: { childPerfData: { cumulative: [...] } }
    ]
    into an object that represents all the data props, like:

    { budgetOpt: { metadata: {...}, budgetOptHierarchy: {...} }, childPerfData: { cumulative: [...] } }
  */
  const getPayloads = useCallback((fetchStates) => _.chain(fetchStates)
    .map((fetchState) => fetchState.data)
    .reduce((prev, curr) => _.assign(prev, curr), {})
    .value(), []);

  // when all of the necessary data for this component has loaded, render it
  // until then, render the loader

  if (allHaveData || showPlaceholder) {
    return (
      <ErrorBoundary callback={onVizError}>
        <WrappedComponent
          {...getPayloads(dataFetchStates)}
          onVizLoaded={onVizLoaded}
          onVizError={onVizError}
          placeholderConfig={placeholderConfig}
          showPlaceholder={showPlaceholder}
          {...passThroughProps}
        />
      </ErrorBoundary>
    );
  }

  // until then, check for errors
  // if errors, display error message; if none, display loader
  if (someErrors) {
    const someNoDataErrors = _.some(
      dataFetchStates,
      (s) => s.kind === PossibleStates.error && _.includes(_.toString(s.errorObject), 'NoDataError'),
    );
    if (someNoDataErrors) {
      return <ErrorSegment error="There is currently no data available." />;
    }
    return <ErrorSegment />;
  }
  return <Loader />;
};

export default withRenderData;
