import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { ISO_DATE } from 'utils/dateTime';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { intelligentOptSummaryNotes } from './constants';
import IntelligentOptDeliveryAllocationChart from './IntelligentOptDeliveryAllocationChart';
import IntelligentOptPerformanceChart from './IntelligentOptPerformanceChart';
import { LastDayIsCloneDataType, getGroupedIsCloneData } from './utils';
import ErrorSegment from '../Components/ErrorSegment';
import InsightsBox from '../Components/InsightsBox';
import SlideTitle from '../Components/SlideTitle';
import SlideWrapper from '../Components/SlideWrapper';
import VizHeader from '../Components/VizHeader';
import { VizId, SlideIcons } from '../constants';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { COPILOT_MODELS, CopilotModels } from '../OptimizationSummary/constants';
import { BaseInsightsSlideProps } from '../types';
import { getInsightsByVizId, getChildDisplayName, getLastDayOfDataByKey } from '../utils';

const vizId = VizId.intelligentOptimizationSummary;

const IntelligentOptimizationOverview = ({ onVizLoaded, onVizError }: BaseInsightsSlideProps) => {
  const [dataError, setDataError] = useState<boolean>(false);
  const {
    primaryStrategyGoal: {
      displayName: goalName,
      shortText,
      target: goalTarget,
      valueType,
      lowerIsBetter,
    },
    metadata: {
      attachments,
    },
    strategy: {
      strategyType: { dsp },
    },
    isCloneData: {
      cumData: isCloneCumData,
      dailyData: isCloneDailyData,
    },
    budgetType,
    currency,
  } = useInsightsContext();

  useMount(() => {
    if (!goalName || !attachments || !isCloneCumData || !budgetType || !_.some(isCloneCumData, ['isClone', true])) {
      setDataError(true);
      onVizError();
    } else {
      onVizLoaded(intelligentOptSummaryNotes);
    }
  });

  if (dataError) {
    return <ErrorSegment />;
  }

  const childDisplayName = getChildDisplayName(dsp);
  const lowerChildDisplayName = _.lowerCase(childDisplayName);
  const goal = shortText ?? _.lowerCase(goalName);
  const deliveryText = budgetType === BudgetTypes.amount ? `spend (${currency})` : 'impressions';
  const tooltipContent = `Compares cumulative delivery allocation and ${goal} of original and intelligent ${lowerChildDisplayName}s. 
    Delivery allocation is measured in ${deliveryText}. Uplift of intelligent ${lowerChildDisplayName}s is calculated via 
    percentage difference in ${goal} between original and intelligent ${lowerChildDisplayName}s.`;
  const lastDayIsCloneCumData = getLastDayOfDataByKey(isCloneCumData, ({ isClone }) => (isClone ? 'intelligent' : 'original')) as LastDayIsCloneDataType;
  const deliveryKpi = BudgetTypeMapping[budgetType];
  const origCumDel = _.get(lastDayIsCloneCumData, `original.${deliveryKpi}`, 0) as number;
  const intCumDel = _.get(lastDayIsCloneCumData, `intelligent.${deliveryKpi}`, 0) as number;
  const totalDel = origCumDel + intCumDel;
  const intelligentCumDelPercent = totalDel === 0 ? 0 : _.round((intCumDel / totalDel) * 100);

  return (
    <div id={vizId} className="slide">
      <SlideTitle
        section="Optimization Insights"
        subSection={`Intelligent ${childDisplayName} Summary`}
        icon={SlideIcons.intelligentOptimization}
      />
      <div className="flex-container">
        <div className="main-visualization">
          <VizHeader
            title={`How Did Intelligent ${childDisplayName}s Compare to Originals`}
            subtitle={`Aggregated delivery & ${goal} across original and intelligent ${lowerChildDisplayName}s`}
            tooltipContent={tooltipContent}
          />
          <IntelligentOptDeliveryAllocationChart
            groupedIsCloneDailyData={getGroupedIsCloneData(isCloneDailyData)}
            intelligentCumDelPercent={intelligentCumDelPercent}
            childDisplayName={lowerChildDisplayName}
            budgetType={budgetType}
          />
          <IntelligentOptPerformanceChart
            groupedIsCloneCumData={getGroupedIsCloneData(isCloneCumData)}
            goal={goal}
            goalTarget={goalTarget}
            lowerIsBetter={lowerIsBetter}
            valueType={valueType}
            currency={currency}
          />
        </div>
        <div className="viz-legend">
          <div className="intelligent-opt-desc">
            <div className="intelligent-child-badge">
              <img src={COPILOT_MODELS[CopilotModels.intelligentLineItems].imageSrc} alt="intelligent child badge" />
              <span>Intelligent {childDisplayName}s</span>
            </div>
            <p>Copilot generates intelligent {lowerChildDisplayName}s to dynamically target high value inventory.</p>
          </div>
          <div className="delivery-legend">
            <div className="flex">
              <div id="original-legend" />
              <span>Aggregated Spend Original<br />{childDisplayName}s</span>
            </div>
            <div className="flex">
              <div id="intelligent-legend" />
              <span>Aggregated Spend Intelligent<br />{childDisplayName}s</span>
            </div>
          </div>
          <div className="goal-legend">
            <div className="flex">
              <div id="original-line" />
              <span>Cumulative {goal} Original {childDisplayName}s</span>
            </div>
            <div className="flex">
              <div id="intelligent-line" />
              <span>Cumulative {goal} Intelligent {childDisplayName}s</span>
            </div>
            <div className="flex">
              <div id="goal-line" />
              <span>Goal {goal}</span>
            </div>
          </div>
        </div>
      </div>
      <InsightsBox
        insights={getInsightsByVizId(vizId, {
          totalDaysOfData: moment().diff(moment(_.get(_.minBy(isCloneCumData, 'date'), 'date')).format(ISO_DATE), 'days'),
          totalIntelligentChildCount: _.size(_.flatMap(attachments, ({ children }) => _.filter(children, ['isClone', true]))),
          lastDayIsCloneCumData,
          goal,
          lowerIsBetter,
          intelligentCumDelPercent,
          childDisplayName: lowerChildDisplayName,
        })}
      />
    </div>
  );
};

export default React.memo(SlideWrapper(IntelligentOptimizationOverview));
