import React from 'react';
import _ from 'lodash';
import { getCreativePositionAndDimensions } from 'charts/InsightsViz/utils';
import { DeviceConfig, CreativeDatumWithPosition, Placements } from 'charts/InsightsViz/types';

type PlacementContainerProps = {
  deviceData: Array<CreativeDatumWithPosition & { fill: string }>
  deviceConfig: DeviceConfig
  deviceMargins: { left: number, right: number, top: number, bottom: number }
  screenMargins: { left: number, right: number, top: number, bottom: number }
  extraYTranslate?: number
};

// Holds all the rectangles that represent the creatives
const PlacementContainer = ({ deviceData, deviceMargins, screenMargins, deviceConfig, extraYTranslate = 0 }: PlacementContainerProps) => (
  <g transform={`translate(${deviceMargins.left + screenMargins.left}, ${deviceMargins.top + screenMargins.top + extraYTranslate})`}>
    {
      _.map(deviceData, (d) => {
        const { x, y, width, height } = getCreativePositionAndDimensions(d, deviceConfig);
        return (
          <rect
            key={d.creativeSize}
            className="single-creative-rect"
            x={x}
            y={y}
            width={width}
            height={height}
            fill={d.fill}
            stroke={d.fill}
          />
        );
      })
    }
  </g>
);

type Props = {
  deviceConfig: DeviceConfig
  data: Array<CreativeDatumWithPosition & { fill: string }>
};

export default ({ deviceConfig, data }: Props) => {
  const { screenMargins, deviceMargins, imageWidth, imageHeight, deviceType, ImageComponent, displayName } = deviceConfig;
  const innerScreenHeight = imageHeight - screenMargins.top - screenMargins.bottom;

  const devicesByPlacement = _.groupBy(data, 'foldPosition');
  const devicesAboveFold = devicesByPlacement[Placements.ABOVE];
  const devicesBelowFold = devicesByPlacement[Placements.BELOW];
  const devicesUnknownPlacement = devicesByPlacement[Placements.UNKNOWN];

  return (
    <div className="device" id={deviceType}>
      <h3>{displayName}</h3>
      <svg
        width={imageWidth + deviceMargins.left + deviceMargins.right}
        height={imageHeight + deviceMargins.top + deviceMargins.bottom}
      >
        <ImageComponent x={deviceMargins.left} y={deviceMargins.top} />
        <line
          className="the-fold"
          y1={deviceMargins.top + screenMargins.top + (0.5 * innerScreenHeight)}
          y2={deviceMargins.top + screenMargins.top + (0.5 * innerScreenHeight)}
          x1={deviceMargins.left / 2}
          x2={imageWidth + deviceMargins.left + (deviceMargins.right / 4)}
        />
        <text
          className="placement-label"
          x={imageWidth + deviceMargins.left + (deviceMargins.right / 4)}
          y={deviceMargins.top + screenMargins.top + (0.5 * innerScreenHeight)}
        >
          The Fold
        </text>
        {(_.size(devicesAboveFold) > 0) && (
          <>
            <PlacementContainer
              deviceMargins={deviceMargins}
              screenMargins={screenMargins}
              deviceData={devicesAboveFold}
              deviceConfig={deviceConfig}
            />
            <text
              className="placement-label"
              x="16"
              y={deviceMargins.top + screenMargins.top + (0.25 * innerScreenHeight)}
              style={{ transformOrigin: `16px ${deviceMargins.top + screenMargins.top + (0.25 * innerScreenHeight)}px` }}
            >
              Above
            </text>
          </>
        )}
        {(_.size(devicesBelowFold) > 0) && (
          <>
            <PlacementContainer
              deviceConfig={deviceConfig}
              deviceMargins={deviceMargins}
              screenMargins={screenMargins}
              extraYTranslate={(0.5 * innerScreenHeight)}
              deviceData={devicesBelowFold}
            />
            <text
              className="placement-label"
              x="16"
              y={deviceMargins.top + screenMargins.top + (0.75 * innerScreenHeight)}
              style={{ transformOrigin: `16px ${deviceMargins.top + screenMargins.top + (0.75 * innerScreenHeight)}px` }}
            >
              Below
            </text>
          </>
        )}
        {(_.size(devicesUnknownPlacement) > 0) && (
          <>
            <PlacementContainer
              deviceConfig={deviceConfig}
              deviceMargins={deviceMargins}
              screenMargins={screenMargins}
              extraYTranslate={imageHeight}
              deviceData={devicesUnknownPlacement}
            />
            <text
              className="placement-label"
              x="16"
              y={deviceMargins.top + (1.25 * imageHeight)}
              style={{ transformOrigin: `16px ${deviceMargins.top + (1.25 * imageHeight)}px` }}
            >
              Unknown
            </text>
            <text
              className="placement-label"
              x="30"
              y={deviceMargins.top + (1.25 * imageHeight)}
              style={{ transformOrigin: `30px ${deviceMargins.top + (1.25 * imageHeight)}px` }}
            >
              Placement
            </text>
          </>
        )}
      </svg>
    </div>
  );
};
