import React from 'react';
import _ from 'lodash';
import { FeatureInsightsDatum } from 'charts/InsightsViz/types';
import { cleanSingleAppSiteName } from 'charts/InsightsViz/utils';
import { BaseListRowProps } from './ListRow';

const formatFeatureValues = (features: Array<string>, datum: FeatureInsightsDatum) => (
  _.chain(features)
    .map((f) => ((f === 'site') ? cleanSingleAppSiteName(datum[f]) : datum[f]))
    .join(' | ')
    .value());

type Props = BaseListRowProps & { features: Array<string> };

const FeatureComboListRow = (props: Props) => {
  const {
    delivery,
    features,
    datum,
    perfStyle,
    formattedPerfVal,
  } = props;

  return (
    <tr className="list-row feature-combo" key={formatFeatureValues(features, datum)}>
      <td className="delivery">{delivery}</td>
      <td className="name">{formatFeatureValues(features, datum)}</td>
      <td className="perf">
        <span style={perfStyle}>{formattedPerfVal}</span>
      </td>
    </tr>
  );
};

export default FeatureComboListRow;
