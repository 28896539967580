import { max, min } from 'd3';
import _ from 'lodash';
import React, { useState } from 'react';
import { Icon, Popup } from 'buildingBlocks';
import ColorLegend from 'charts/Components/ColorLegend';
import { COLORS_PINK_TO_GREEN } from 'charts/constants';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import BudgetAllocationVizTable from './BudgetAllocationVizTable';
import { budgetAllocAvailScaleNotes } from './constants';
import { getOrderedTopDelChildrenWithGoal } from './utils';
import ErrorSegment from '../Components/ErrorSegment';
import InsightsBox from '../Components/InsightsBox';
import SlideTitle from '../Components/SlideTitle';
import SlideWrapper from '../Components/SlideWrapper';
import VizHeader from '../Components/VizHeader';
import { SlideIcons, VizId } from '../constants';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { BaseInsightsSlideProps } from '../types';
import { getInsightsByVizId, getChildDisplayName, pluralizeFlightName, getLastDayOfDataByKey, getPrimaryGoalKey, getGoalPerfColorScale, getRoundedGoalText } from '../utils';

const vizId = VizId.budgetAllocationAndAvailableScale;

const BudgetAllocationAndAvailableScale = ({ onVizLoaded, onVizError }: BaseInsightsSlideProps) => {
  const [dataError, setDataError] = useState<boolean>(false);
  const {
    primaryStrategyGoal: {
      lowerIsBetter,
      target: goalTarget,
      displayName: goalName,
      valueType,
      shortText,
    },
    metadata: { attachments },
    strategy: {
      strategyType: { dsp },
    },
    childData: { cumData: childCumData, dailyData: childDailyData },
    currency,
    budgetType,
    budgetOptData: { budgetAllocationData },
  } = useInsightsContext();

  useMount(() => {
    if (!attachments || !childCumData || !childDailyData || !budgetAllocationData) {
      setDataError(true);
      onVizError();
    } else {
      onVizLoaded(budgetAllocAvailScaleNotes);
    }
  });

  if (dataError || !budgetAllocationData) {
    return <ErrorSegment />;
  }

  const allChildFlights = _.flatMap(attachments, 'children');
  const childDisplayName = _.lowerCase(getChildDisplayName(dsp));
  const { topDeliveringChildren, notableBudgetGrowth, notableBudgetReduction, childDeliveryCapacityCount } = budgetAllocationData;
  const numberOfTopDeliveringChildren = _.size(topDeliveringChildren);
  const lineItemData = getLastDayOfDataByKey(childCumData, 'childExtId');
  const primaryGoalKey = getPrimaryGoalKey(_.head(_.values(lineItemData)));
  const orderedDataWithGoalPerf = getOrderedTopDelChildrenWithGoal(topDeliveringChildren, lineItemData, primaryGoalKey);
  const deliveryLabel = budgetType === BudgetTypes.amount ? currency : 'IMP';

  const goalPerfScale = _.map(lineItemData, primaryGoalKey);
  const colorScaleRange = lowerIsBetter ? _.reverse([...COLORS_PINK_TO_GREEN]) : COLORS_PINK_TO_GREEN;
  const minMaxMean = (min(goalPerfScale) + max(goalPerfScale)) / 2;
  const colorScale = getGoalPerfColorScale(colorScaleRange, goalPerfScale, minMaxMean, valueType);

  return (
    <div id={vizId} className="slide">
      <SlideTitle section="Optimization Insights" subSection="Budget Allocation & Available Scale" icon={SlideIcons.budgetOptimization} />
      <div className="main-visualization">
        <VizHeader
          title="How Allocation Was Adjusted Against Available Scale"
          subtitle={`Average daily delivery & budget allocated for top ${numberOfTopDeliveringChildren} ${childDisplayName}${pluralizeFlightName(numberOfTopDeliveringChildren)}`}
          tooltipContent="Visualizes budget allocation and budget delivery to represent how Copilot influenced delivery."
        />
        <div className="budget-opt-tooltip">
          Budget Optimization
          <Popup
            hoverable
            wide="very"
            trigger={<span><Icon name="question circle outline" /></span>}
            content={`Copilot dynamically adjusts budget across ${childDisplayName}s to maximize performance.`}
          />
        </div>
        <BudgetAllocationVizTable
          data={orderedDataWithGoalPerf}
          goal={shortText ?? goalName}
          childDisplayName={childDisplayName}
          deliveryLabel={deliveryLabel}
          colorScale={colorScale}
          valueType={valueType}
        />
        <div className="budget-allocation-viz-footer">
          <div className="delivery-cap-text">
            <img src="/img/components/Insights/deliveryCapTriangle.svg" alt="delivery cap" />
            <span>
              Delivery Cap Reached:
            </span>
            Delivery Cap is reached when average delivery is lower or equal to 70% of average allocated budget
          </div>
          <ColorLegend
            id="budget-allocation-viz"
            type="discrete"
            showLabel={false}
            label=""
            width={172}
            meanValue={minMaxMean}
            showGoalValue
            goalValue={goalTarget}
            lowerIsBetter={lowerIsBetter}
            data={{
              colorRange: colorScaleRange,
              domain: colorScale.domain(),
              format: (d) => getRoundedGoalText(valueType, d),
            }}
            margins={{ top: 8, bottom: 0, left: 0, right: 0 }}
          />
        </div>
      </div>
      <InsightsBox insights={getInsightsByVizId(
        vizId,
        {
          lowerIsBetter,
          goal: goalName,
          childDisplayName,
          allChildFlights,
          notableBudgetGrowth,
          notableBudgetReduction,
          childDeliveryCapacityCount,
        },
      )}
      />
    </div>
  );
};

export default React.memo(SlideWrapper(BudgetAllocationAndAvailableScale));
