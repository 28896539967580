import { SemanticICONS } from 'buildingBlocks';
import { COLORS } from 'charts/constants';
import Mobile from 'charts/InsightsViz/DeviceInsights/Mobile';
import Tablet from 'charts/InsightsViz/DeviceInsights/Tablet';
import Laptop from 'charts/InsightsViz/DeviceInsights/Laptop';
import CTV from 'charts/InsightsViz/DeviceInsights/CTV';
import { COPILOT_COLORS } from 'globalStyles';
import { Devices } from './types';

const { graphGrey, NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES } } = COPILOT_COLORS;

export const MAX_CACHE_AGE = 18 * 60 * 60; // 18 hrs

export enum IconType {
  fontAwesome = 'font-awesome',
  custom = 'custom',
}

type FontAwesomeIcon = {
  kind: IconType.fontAwesome
  name: SemanticICONS
};

type CustomIcon = {
  kind: IconType.custom
  name: string
};

export type IconConfig = FontAwesomeIcon | CustomIcon;

export const SlideIcons: { [title: string]: IconConfig } = {
  tableOfContents: {
    kind: IconType.fontAwesome,
    name: 'book',
  },
  performanceAndDeliverySummary: {
    kind: IconType.fontAwesome,
    name: 'dashboard',
  },
  optimizationSummary: {
    kind: IconType.fontAwesome,
    name: 'wrench',
  },
  budgetOptimization: {
    kind: IconType.custom,
    name: 'Budget',
  },
  intelligentOptimization: {
    kind: IconType.custom,
    name: 'AI',
  },
  contextualInsights: {
    kind: IconType.custom,
    name: 'Browser',
  },
  deviceInsights: {
    kind: IconType.fontAwesome,
    name: 'mobile alternate',
  },
  geoInsights: {
    kind: IconType.custom,
    name: 'Globe',
  },
};

export enum VizId {
  tableOfContents = 'table-of-contents',
  performanceAndDelivery = 'performance-and-delivery',
  optimizationSummary = 'optimization-summary',
  budgetOptimizationOverview = 'budget-optimization-overview',
  budgetAllocationAndAvailableScale = 'budget-allocation-and-available-scale',
  intelligentOptimizationSummary = 'intelligent-optimization-summary',
  intelligentOptimizationTargeting = 'intelligent-optimization-targeting',
  contextualFeatureInsights = 'contextual-feature-insights',
  deviceFeatureInsights = 'device-feature-insights',
  geoInsights = 'geographical-feature-insights',
}

export const DEVICE_GRAPHIC = {
  [Devices.PHONE]: {
    ImageComponent: Mobile,
    deviceType: Devices.PHONE,
    displayName: 'Mobile',
    pixelWidth: 960,
    pixelHeight: 1725,
    horizontalRowPixelHeight: 120,
    screenMargins: {
      left: 3,
      right: 3,
      top: 15,
      bottom: 15,
    },
    deviceMargins: {
      left: 40,
      right: 60,
      top: 0,
      bottom: 100,
    },
    imageHeight: 120,
    imageWidth: 58.294573644,
    aspectRatio: 0.4857881137,
  },
  [Devices.TABLET]: {
    ImageComponent: Tablet,
    deviceType: Devices.TABLET,
    displayName: 'Tablet',
    pixelWidth: 1280,
    pixelHeight: 1650,
    horizontalRowPixelHeight: 120,
    screenMargins: {
      left: 6.6,
      right: 6.5,
      top: 15.5,
      bottom: 19,
    },
    deviceMargins: {
      left: 40,
      right: 60,
      top: 0,
      bottom: 100,
    },
    imageHeight: 157,
    imageWidth: 106.0981337812,
    aspectRatio: 0.6757842916,
  },
  [Devices.LAPTOP_OR_PC]: {
    ImageComponent: Laptop,
    deviceType: Devices.LAPTOP_OR_PC,
    displayName: 'Desktop',
    pixelWidth: 2560,
    pixelHeight: 1420,
    horizontalRowPixelHeight: 120,
    screenMargins: {
      left: 34,
      right: 34,
      top: 8,
      bottom: 22,
    },
    deviceMargins: {
      left: 40,
      right: 60,
      top: 0,
      bottom: 100,
    },
    imageHeight: 154.8325484005,
    imageWidth: 295,
    aspectRatio: 1.9052841476,
  },
  [Devices.CTV]: {
    ImageComponent: CTV,
    deviceType: Devices.CTV,
    displayName: 'Connected TV',
    pixelWidth: 2560,
    pixelHeight: 1436.16,
    horizontalRowPixelHeight: 120,
    screenMargins: {
      left: 19,
      right: 40,
      top: 13,
      bottom: 5,
    },
    deviceMargins: {
      left: 40,
      right: 60,
      top: 0,
      bottom: 100,
    },
    imageHeight: 165.495,
    imageWidth: 295,
    aspectRatio: 1.4713541667,
  },
};

export const FEATURE_SHORT_NAMES = {
  creativeSize: 'adSize',
  deviceType: 'device',
  foldPosition: 'adPosition',
  displayRegion: 'region',
};

export const CURRENCY_OR_PERCENTAGE_PRECISION = 2;
export const VALUE_PRECISION = 3;
export const CURRENCY_FORMAT = '0[.][000]';
export const PERCENTAGE_FORMAT = '0[.][00]%';
export const ROUNDED_PERCENTAGE_FORMAT = '0%';

export const POLL_DELAY_TIME_MS = 10 * 1000;
const MAX_TOTAL_POLLING_TIME_MS = 10 * 60 * 1000;
export const MAX_ITERATIONS = MAX_TOTAL_POLLING_TIME_MS / POLL_DELAY_TIME_MS;

export enum Status {
  queued = 'queued',
  inProgress = 'in_progress',
  complete = 'complete',
  error = 'error',
}

export enum FeatureInsightsDatavizType {
  intelligentOpt = 1,
  featureSite = 2,
  featureDevice = 3,
  featureGeo = 4,
  budgetOpt = 5,
}

export const areaSplineColors = {
  transparent: COLORS.TRANSPARENT_WHITE,
  white: NEUTRALS.N0_WHITE,
  green: COLORS.AREA_GREEN,
  lineGrey: COLORS.LINE_GREY,
  lineGreen: COLORS.LINE_GREEN,
  graphGrey,
  blue: BLUES.B500_WAVE,
  lineRed: COLORS.LINE_RED,
};

export const UNOPTIMIZED_GOAL_KEY = 'estimatedKpiCumulative';
