export const intelligentOptSummaryNotes = [
  'Compares cumulative delivery and performance of Original and Intelligent Line Items.',
  'Uplift of Intelligent Line Items is calculated via percentage difference in performance between Original and Intelligent Line Items.',
  'Delivery',
  'Represents the cumulative delivery on Original and Intelligent Line Items.',
  'It\'s important to note that a low delivery level is not necessarily bad. Intelligent Line Items are designed to deliver better performance than their Originals, but not at great scale, after all they improve performance by restricting targeting to a subset of the best performing elements of the Original.',
  'Performance',
  'Represents the performance of each Line Item group. The area between the lines can be thought of as the uplift or Copilot\'s value added.',
  'Ideally, the Intelligent Line Item\'s line should mimic the line movement of the graph above. If performance is improving, Copilot\'s budget allocation should also increase and therefore the delivery should increase. The inverse is also true.',
  'However, do not forget there are limitations to how much each line can scale.',
];
