import { Options } from 'highcharts';
import _ from 'lodash';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { GOAL_VALUE_TYPE, GOAL_TYPES } from 'constantsBase';
import { safeDivision } from 'utils/calculations';
import { VALUE_PRECISION } from '../constants';
import { PrimaryStrategyGoal, ViewabiltyGoal } from '../types';
import { formatCurrencyGoal, roundedPercentageGoal, formatPercentageGoal } from '../utils';

const MAX_GOAL_VALUE_POINT = 12;
const MIN_GOAL_VALUE_POINT = 1;
const GOAL_TARGET_POINT = 10;

const getMaxPointOnChart = (num, den) => (
  Math.max(_.round(safeDivision(num, den) * GOAL_TARGET_POINT), MIN_GOAL_VALUE_POINT)
);

const calculateGoalBarLengths = (goalTarget: number, goalValue: number) => {
  let barLength = MAX_GOAL_VALUE_POINT;
  let lineLength = GOAL_TARGET_POINT;
  if (goalValue > goalTarget) {
    lineLength = getMaxPointOnChart(goalTarget, goalValue);
  } else if (goalValue < goalTarget) {
    barLength = getMaxPointOnChart(goalValue, goalTarget);
  } else {
    lineLength = MAX_GOAL_VALUE_POINT;
  }
  return { barLength, lineLength };
};

const goalChartConfig = (goalTarget, goalValue, barLength, lineLength, hasRevenueType): Options => ({
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    height: 90,
    width: 250,
    animation: false,
    style: { fontFamily: 'Gilroy' },
  },
  plotOptions: {
    series: {
      animation: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    bar: {
      pointWidth: 40,
      dataLabels: {
        enabled: true,
        align: 'left',
        formatter: () => `${goalValue}`,
        style: {
          fontWeight: '400',
          fontSize: '14px',
        },
      },
    },
    scatter: {
      dataLabels: {
        enabled: true,
        formatter: () => `${goalTarget} ${hasRevenueType ? 'revenue value' : 'goal'}`,
        x: 10,
        y: 37,
        style: {
          fontWeight: '300',
          fontSize: '12px',
        },
      },
    },
  },
  xAxis: {
    title: {
      text: null,
    },
    visible: false,
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    min: 0,
    max: 20,
    labels: {
      enabled: false,
    },
    gridLineColor: 'transparent',
    plotLines: [{
      color: '#BBC2C4',
      width: 2,
      value: lineLength,
      dashStyle: 'Dash',
      zIndex: 4,
      label: {
        text: '',
      },
    }],
  },
  series: [
    {
      name: '',
      type: 'bar',
      data: [barLength],
      color: '#E0E0E0',
      showInLegend: false,
    },
    {
      name: '',
      linkedTo: 'previous',
      type: 'scatter',
      color: '#BBC2C4',
      data: [lineLength],
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  ],
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
});

const formatGoal = (goalValueType, goalTarget, goalValue, goalType, currencyCode) => {
  switch (goalValueType) {
    case GOAL_VALUE_TYPE.CURRENCY:
      return [formatCurrencyGoal(goalTarget, currencyCode, true), formatCurrencyGoal(goalValue, currencyCode, true)];
    case GOAL_VALUE_TYPE.PERCENTAGE: {
      if (goalType === GOAL_TYPES.ivrMeasured.value) {
        return [roundedPercentageGoal(goalTarget), roundedPercentageGoal(goalValue)];
      }
      return [formatPercentageGoal(goalTarget), formatPercentageGoal(goalValue)];
    }
    default:
      return [_.round(goalTarget, VALUE_PRECISION), _.round(goalValue, VALUE_PRECISION)];
  }
};

type SinglePlatformGoalChartProps = {
  goal: PrimaryStrategyGoal | ViewabiltyGoal
  goalValue: number
  currency?: string
  hasRevenueType?: boolean
};

const SinglePlatformGoalChart = (props: SinglePlatformGoalChartProps) => {
  const { goal, goalValue, currency, hasRevenueType = false } = props;
  const { target, valueType, name } = goal;
  const [formattedGoalTarget, formattedGoalValue] = formatGoal(valueType, target, goalValue, name, currency);
  const { barLength, lineLength } = calculateGoalBarLengths(target, goalValue);
  return (
    <ReactHighcharts
      config={goalChartConfig(formattedGoalTarget, formattedGoalValue, barLength, lineLength, hasRevenueType)}
      isPureConfig
    />
  );
};

export default SinglePlatformGoalChart;
