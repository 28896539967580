import { select, ScaleThreshold, rgb } from 'd3';
import _ from 'lodash';
import React from 'react';
import { DSP } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';
import { TopDeliveringChildWithGoal } from './constants';
import { createTooltip, getRoundedGoalText, getTextLinesWithEllipsis, mouseMove, mouseOut, mouseOver } from '../utils';

const { NEW_DESIGN_SYSTEM: { DSP: dspColors, BLUES, NEUTRALS } } = COPILOT_COLORS;

export const FlightNameCell = ({ data, index, topChildrenCount }: { data: TopDeliveringChildWithGoal, index: number, topChildrenCount: number }) => {
  const { childName, dsp, isClone } = data;
  const lineCount = topChildrenCount <= 6 ? 2 : 1;
  const id = `ba-viz-flight-${index}`;

  // add tooltip if childName is truncated
  if (_.size(childName) > (34 * lineCount)) {
    const nameContainer = select(`#${id}`);
    const nameTooltip = createTooltip(nameContainer, childName);

    nameContainer
      .on('mouseover', () => mouseOver(nameTooltip))
      .on('mousemove', () => mouseMove(nameContainer, nameTooltip))
      .on('mouseout', () => mouseOut(nameTooltip));
  }

  return (
    <div>
      <div className="ba-sc-flight-name" id={id}>
        {_.map(getTextLinesWithEllipsis(childName, 34, lineCount), (str: string, idx) => (
          <p key={idx} style={{ color: isClone ? BLUES.B500_WAVE : NEUTRALS.N600_ABBEY }}>{str}</p>
        ))}
      </div>
      {dsp && (
        <div className="dsp-badge" style={{ backgroundColor: dspColors[dsp].light, color: dspColors[dsp].dark }}>
          {DSP.getById(dsp).code}
        </div>
      )}
    </div>
  );
};

export const DeliveryRatioCell = ({ data: { averageDailyDelivery, averageDailyBudgetAllocation } }: { data: TopDeliveringChildWithGoal }) => {
  const deliveryRatio = _.round((averageDailyDelivery / averageDailyBudgetAllocation) * 100);
  return (
    <div className="delivery-ratio-cell">
      <p>{averageDailyBudgetAllocation === 0 ? '-' : `${deliveryRatio}%`}</p>
      {deliveryRatio <= 70 && (
        <div className="delivery-ratio-reached">
          <img src="/img/components/Insights/deliveryCapTriangle.svg" alt="delivery cap" />
          Delivery Cap Reached
        </div>
      )}
    </div>
  );
};

export const GoalPerformanceCell = (props: {
  data: TopDeliveringChildWithGoal
  colorScale: ScaleThreshold<number, string>
  valueType: string
}) => {
  const { data: { goalPerf }, colorScale, valueType } = props;
  const goalPerfColor = colorScale(goalPerf);
  const { r, g, b } = rgb(goalPerfColor);
  return (
    <div
      className="goal-perf"
      style={{ backgroundColor: `rgba(${r},${g},${b},0.6)`, border: `1px solid ${goalPerfColor}` }}
    >
      {getRoundedGoalText(valueType, goalPerf, true)}
    </div>
  );
};
