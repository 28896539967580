import { select } from 'd3';
import _ from 'lodash';
import React from 'react';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { VizId } from '../constants';
import {
  performanceAndDeliveryGoalsDesc, deliveryTypeWithCurrency, higherOrLowerByGoalType, budgetTypeWithCurrency, spendOrDelivery, highestOrLowestByGoalType, showCurrencyOrImpressions,
  goalPerformanceAgainstAvg, deliveryPerformanceAgainstAvg, formatDisplayValueBasedOnGoalType, showPercentageOnlyForRateBased, showFormattedGoalSymbol, getInsightsArrow, INSIGHTS_ARROW,
} from './utils';
import { createTooltip, formatDspObjectName, getRoundedGoalText, mouseMove, mouseOut, mouseOver, pluralizeFlightName, truncateText } from '../utils';
import { BudgetOptOverviewAggLevel } from '../BudgetOptimizationOverview/constants';

const TEXT_LENGTH_LIMITS = {
  [VizId.performanceAndDelivery]: {
    delivery: 45,
  },
  [VizId.budgetOptimizationOverview]: {
    highestPerformance: 28,
    highestDelivery: 31,
  },
  [VizId.budgetAllocationAndAvailableScale]: {
    notableBudget: 20,
  },
  [VizId.intelligentOptimizationTargeting]: {
    title: 18,
    highestPerformance: 50,
    highestDelivery: 40,
  },
  [VizId.contextualFeatureInsights]: {
    title: 20,
    highestPerformance: 20,
    highestDelivery: 20,
  },
  [VizId.geoInsights]: {
    title: 20,
  },
};

const budgetOptOverviewStatBoxDescStarter = (aggLevel: BudgetOptOverviewAggLevel, aggDisplayText: string, name: string, maxChars: number, id: string) => {
  if (aggLevel === BudgetOptOverviewAggLevel.platform) {
    return name;
  }
  // add tooltip if name is truncated
  if (_.size(name) > maxChars) {
    const container = select(`#${id}`);
    const nameTooltip = createTooltip(container, name);

    container
      .on('mouseover', () => mouseOver(nameTooltip))
      .on('mousemove', () => mouseMove(container, nameTooltip))
      .on('mouseout', () => mouseOut(nameTooltip));
  }

  return (
    <span>
      {_.capitalize(aggDisplayText)}&nbsp;
      <span id={id} className="tooltip-span">&quot;{truncateText(name, maxChars)}&quot;</span>
    </span>
  );
};

export const statBoxesConfig = {
  [VizId.performanceAndDelivery]: [
    {
      title: () => 'Goals',
      statisticValue: (data) => `${formatDisplayValueBasedOnGoalType(data.primaryGoalKpi, data.primaryGoal)}${showPercentageOnlyForRateBased(data)}`,
      statisticSuffix: (data) => data.primaryGoalDisplayName,
      description: (data) => performanceAndDeliveryGoalsDesc(data),
    },
    {
      title: () => 'Impact',
      statisticValue: (data) => `${data.uplift}%`,
      statisticSuffix: (data) => data.primaryGoalDisplayName,
      description: (data) => (
        `Copilot optimizations led to an estimated ${data.uplift}% ${data.upliftDirection}
        in ${data.primaryGoalDisplayName} across the Strategy.`
      ),
      upliftArrow: (data) => getInsightsArrow(data.upliftDirection, data.lowerIsBetter),
    },
    {
      title: () => 'Delivery',
      statisticValue: (data) => `${data.delivery}%`,
      statisticSuffix: () => 'Budget Delivery',
      description: (data) => (
        `Copilot has delivered ${data.delivery}% of the Strategy budget`
      ),
    },
  ],
  [VizId.optimizationSummary]: [
    {
      title: () => 'Budget Optimization',
      statisticValue: (data) => data.lineItemCount,
      statisticSuffix: ({ lineItemCount, childFlightName }) => (`${childFlightName}${pluralizeFlightName(lineItemCount)}`),
      description: ({ isCrossPlatform, dspCount, lineItemCount, childFlightName }) => (isCrossPlatform
        ? `Copilot dynamically adjusted budget each day across ${dspCount} buying platforms and ${lineItemCount} line items to maximize performance.`
        : `Copilot dynamically adjusted budget each day across ${lineItemCount} ${childFlightName}${pluralizeFlightName(lineItemCount)} to maximize performance.`
      ),
    },
    {
      title: (data) => `Intelligent ${data.childFlightName}s`,
      statisticValue: (data) => (data.intelligentChildObjects ? data.cloneCount : 0),
      statisticSuffix: ({ childFlightName, cloneCount }) => `Intelligent ${childFlightName}${pluralizeFlightName(cloneCount)}`,
      description: ({ childFlightName, intelligentChildObjects, cloneCount }) => (intelligentChildObjects
        ? `Copilot generated ${cloneCount} Intelligent ${childFlightName}${pluralizeFlightName(cloneCount)} to dynamically target high performing inventory.`
        : `Intelligent ${childFlightName}s was not activated on this Strategy.`
      ),
    },
    {
      title: ({ viewabilityGoal, parentFlightName }) => (viewabilityGoal ? 'Viewability Control' : `${parentFlightName}s`),
      statisticValue: ({ viewabilityGoal, lineItemCount, parentCount }) => (viewabilityGoal ? lineItemCount : parentCount),
      statisticSuffix: ({ viewabilityGoal, childFlightName, cloneCount, parentFlightName, parentCount }) => (viewabilityGoal
        ? `${childFlightName}${pluralizeFlightName(cloneCount)}`
        : `${parentFlightName}${pluralizeFlightName(parentCount)}`
      ),
      description: ({ viewabilityGoal, lineItemCount, childFlightName, parentCount, parentFlightName, isCrossPlatform }) => (viewabilityGoal
        ? `Copilot applied Viewability models to ${lineItemCount} ${childFlightName}${pluralizeFlightName(lineItemCount)} optimizing towards a ${_.round(viewabilityGoal.target * 100)}% Viewability goal.`
        : `Copilot dynamically adjusted budget across ${parentCount} ${isCrossPlatform ? 'Object' : parentFlightName}${pluralizeFlightName(parentCount)} to maximize performance.`
      ),
    },
  ],
  [VizId.budgetOptimizationOverview]: [
    {
      title: () => 'Budget Updates',
      statisticValue: (data) => data.budgetAdjustments,
      statisticSuffix: () => 'Budget Adjustments',
      description: ({ budgetAdjustments, totalChildCount, childDisplayName, goal }) => (
        `Copilot dynamically adjusted budget ${budgetAdjustments} times across ${totalChildCount} ${childDisplayName}${pluralizeFlightName(totalChildCount)} to improve ${goal}.`
      ),
    },
    {
      title: () => 'Best Performance',
      statisticValue: ({ bestPerformingGroup: { goalPerf }, valueType }) => getRoundedGoalText(valueType, goalPerf),
      statisticSuffix: ({ goal }) => goal,
      description: ({ lowerIsBetter, bestPerformingGroup: { delivery, name }, goal, totalDelivery, aggLevel, aggDisplayText }) => {
        const deliveryPercentage = _.round((delivery / totalDelivery) * 100);
        return (
          <span>
            {budgetOptOverviewStatBoxDescStarter(aggLevel, aggDisplayText, name, TEXT_LENGTH_LIMITS[VizId.budgetOptimizationOverview].highestPerformance, 'bo-best-performance')}
            &nbsp;had the {lowerIsBetter ? 'lowest' : 'highest'} {goal}, accounting for {deliveryPercentage === 0 ? '< 1' : deliveryPercentage}% of delivery.
          </span>
        );
      },
    },
    {
      title: () => 'Highest Delivery',
      statisticValue: ({ highestDeliveryGroup: { delivery }, totalDelivery }) => (totalDelivery === 0 ? 'N/A' : `${_.round((delivery / totalDelivery) * 100)}%`),
      statisticSuffix: () => 'of Budget Allocated',
      description: ({ aggLevel, aggDisplayText, deliveryKpi, currency, highestDeliveryGroup: { name, delivery } }) => {
        const deliveryInCurrency = deliveryKpi === BudgetTypeMapping.amount;
        return (
          <span>
            {budgetOptOverviewStatBoxDescStarter(aggLevel, aggDisplayText, name, TEXT_LENGTH_LIMITS[VizId.budgetOptimizationOverview].highestDelivery, 'bo-highest-delivery')}
            &nbsp;accounted for {_.round(delivery, deliveryInCurrency ? 2 : 0)} {deliveryInCurrency ? currency : 'impressions'} of the total budget.
          </span>
        );
      },
    },
  ],
  [VizId.budgetAllocationAndAvailableScale]: [
    {
      title: () => 'Notable Budget Growth',
      statisticValue: ({ notableBudgetGrowth: { budgetChange } }) => `${_.round(budgetChange)}%`,
      statisticSuffix: () => 'Increase in Delivery',
      description: ({ goal, lowerIsBetter, childDisplayName, notableBudgetGrowth: { childName, isClone } }) => {
        // add tooltip if childName is truncated
        if (_.size(childName) > TEXT_LENGTH_LIMITS[VizId.budgetAllocationAndAvailableScale].notableBudget) {
          const container = select('#notable-budget-growth-name');
          const nameTooltip = createTooltip(container, childName);

          container
            .on('mouseover', () => mouseOver(nameTooltip))
            .on('mousemove', () => mouseMove(container, nameTooltip))
            .on('mouseout', () => mouseOut(nameTooltip));
        }

        return (
          <span>
            Copilot increased budget on {isClone ? 'intelligent' : 'original'} {childDisplayName}&nbsp;
            <span id="notable-budget-growth-name" className="tooltip-span">&quot;{truncateText(childName, TEXT_LENGTH_LIMITS[VizId.budgetAllocationAndAvailableScale].notableBudget)}&quot;</span>
            &nbsp;due to {lowerIsBetter ? 'lower' : 'higher'} {goal} and available scale.
          </span>
        );
      },
      upliftArrow: () => ({ direction: INSIGHTS_ARROW.direction.up, color: INSIGHTS_ARROW.color.green }),
    },
    {
      title: () => 'Notable Budget Reduction',
      statisticValue: ({ notableBudgetReduction: { budgetChange } }) => `${_.round(Math.abs(budgetChange))}%`,
      statisticSuffix: () => 'Reduction in Delivery',
      description: ({ childDisplayName, notableBudgetReduction: { childName, isClone } }) => {
        // add tooltip if childName is truncated
        if (_.size(childName) > TEXT_LENGTH_LIMITS[VizId.budgetAllocationAndAvailableScale].notableBudget) {
          const container = select('#notable-budget-reduction-name');
          const nameTooltip = createTooltip(container, childName);

          container
            .on('mouseover', () => mouseOver(nameTooltip))
            .on('mousemove', () => mouseMove(container, nameTooltip))
            .on('mouseout', () => mouseOut(nameTooltip));
        }

        return (
          <span>
            Copilot decreased budget on {isClone ? 'intelligent' : 'original'} {childDisplayName}&nbsp;
            <span id="notable-budget-reduction-name" className="tooltip-span">&quot;{truncateText(childName, TEXT_LENGTH_LIMITS[VizId.budgetAllocationAndAvailableScale].notableBudget)}&quot;</span>
            &nbsp;due to low scale, we recommend you review targeting settings to allow more delivery.
          </span>
        );
      },
      upliftArrow: () => ({ direction: INSIGHTS_ARROW.direction.down, color: INSIGHTS_ARROW.color.orange }),
    },
    {
      title: () => 'Delivery Capacity',
      statisticValue: ({ totalChildFlights, childDeliveryCapacityCount }) => `${_.round((childDeliveryCapacityCount / totalChildFlights) * 100)}%`,
      statisticSuffix: ({ childDisplayName }) => `of ${_.startCase(childDisplayName)}s Maximized Allocation`,
      description: ({ totalChildFlights, childDeliveryCapacityCount, childDisplayName }) => (childDeliveryCapacityCount === 0
        ? `All ${childDisplayName}s had enough scale.`
        : `Copilot maximized delivery on ${childDeliveryCapacityCount} out of ${totalChildFlights} ${childDisplayName}s. ${childDeliveryCapacityCount === 1 ? 'This' : 'These'} ${childDisplayName}${pluralizeFlightName(childDeliveryCapacityCount)} could not deliver more.`
      ),
    },
  ],
  [VizId.intelligentOptimizationSummary]: [
    {
      title: () => 'Intelligent Updates',
      statisticValue: ({ totalDaysOfData, totalIntelligentChildCount }) => totalDaysOfData * totalIntelligentChildCount,
      statisticSuffix: () => 'Intelligent Targeting Updates',
      description: ({ totalDaysOfData, totalIntelligentChildCount, childDisplayName }) => (
        `Copilot automatically adjusted targeting ${totalDaysOfData * totalIntelligentChildCount} times across 
        ${totalDaysOfData} day${pluralizeFlightName(totalDaysOfData)} on ${totalIntelligentChildCount} intelligent ${childDisplayName}${pluralizeFlightName(totalIntelligentChildCount)}.`
      ),
    },
    {
      title: () => 'Intelligent Uplift',
      statisticValue: ({ upliftPercentage }) => `${upliftPercentage}%`,
      statisticSuffix: ({ goal }) => goal,
      description: ({ childDisplayName, upliftPercentage, goalUpliftDirection, goal }) => (
        `Copilot intelligent ${childDisplayName}s achieved a ${upliftPercentage}% ${goalUpliftDirection} in ${goal} 
        compared to original ${childDisplayName}s.`
      ),
      upliftArrow: ({ goalUpliftDirection, lowerIsBetter }) => getInsightsArrow(goalUpliftDirection, lowerIsBetter),
    },
    {
      title: () => 'Intelligent Budget Allocation',
      statisticValue: ({ intelligentCumDelPercent }) => `${intelligentCumDelPercent}%`,
      statisticSuffix: () => 'Cumulative Delivery',
      description: ({ intelligentCumDelPercent, childDisplayName }) => (
        `Copilot allocated ${intelligentCumDelPercent}% of total delivery to intelligent ${childDisplayName}s.`
      ),
    },
  ],
  [VizId.intelligentOptimizationTargeting]: [
    {
      title: () => 'Intelligent Targeting',
      statisticValue: (data) => data.intelligentFeatureCombinationsTargeted,
      statisticSuffix: () => 'Combinations Targeted',
      description: (data) => (
        `Copilot analyzed ${data.intelligentFeatureCombinationsAnalyzed} feature values and 
        targeted ${data.intelligentFeatureCombinationsTargeted} unique combinations on 
        Intelligent ${data.childExtTypeDisplayName}s.`
      ),
    },
    {
      title: () => 'Best Performance',
      statisticValue: (data) => truncateText(data.highestPerformingFeatureValues, TEXT_LENGTH_LIMITS[VizId.intelligentOptimizationTargeting].title),
      statisticSuffix: () => '',
      description: (data) => `Feature 
      ${truncateText((data.highestPerformingFeatureValues), TEXT_LENGTH_LIMITS[VizId.intelligentOptimizationTargeting].highestPerformance)} 
      achieved the ${highestOrLowestByGoalType(data.lowerIsBetter)} ${data.primaryGoalType} of ${data.highestPerformingGoalPerformance}${showFormattedGoalSymbol(data)}.`,
      stylingClassNames: 'breakword',
    },
    {
      title: () => 'Highest Delivery',
      statisticValue: (data) => truncateText(data.highestDeliveringFeatureValues, TEXT_LENGTH_LIMITS[VizId.intelligentOptimizationTargeting].title),
      statisticSuffix: () => '',
      description: (data) => `Feature 
      ${truncateText((data.highestDeliveringFeatureValues), TEXT_LENGTH_LIMITS[VizId.intelligentOptimizationTargeting].highestDelivery)} 
      achieved the ${highestOrLowestByGoalType(data.lowerIsBetter)} ${_.lowerCase(spendOrDelivery(data))} of ${data.highestDeliveringDelivery} ${showCurrencyOrImpressions(data)}.`,
      stylingClassNames: 'breakword',
    },
  ],
  [VizId.contextualFeatureInsights]: [
    {
      title: () => 'Sites Above Goal',
      statisticValue: (data) => data.countFeaturesAboveGoal,
      statisticSuffix: () => 'Sites and Applications',
      description: (data) => `${data.countFeaturesAboveGoal} Sites & Applications accounted for 
      ${data.deliveryPctFeaturesAboveGoal}% of ${_.lowerCase(deliveryTypeWithCurrency(data, false))} 
      and achieved ${data.primaryGoalType} ${higherOrLowerByGoalType(data.lowerIsBetter)} than ${data.hasRevenueType ? 'revenue value' : 'goal target'}.`,
    },
    {
      title: () => 'Best Performance',
      statisticValue: (data) => truncateText((data.highestPerformingFeature), TEXT_LENGTH_LIMITS[VizId.contextualFeatureInsights].title),
      statisticSuffix: () => '',
      description: (data) => `“${truncateText((data.highestPerformingFeature), TEXT_LENGTH_LIMITS[VizId.contextualFeatureInsights].highestPerformance)}” achieved the 
      ${highestOrLowestByGoalType(data.lowerIsBetter)} 
      ${data.primaryGoalType} of ${data.highestPerformingGoalPerformance}${showFormattedGoalSymbol(data)} and accounted for 
      ${(goalPerformanceAgainstAvg(data.highestDeliveringObjDelivery, data.avgDeliveryAcrossFeatures))} 
      ${_.lowerCase(deliveryTypeWithCurrency(data, false))}.`,
      stylingClassNames: 'breakword',
    },
    {
      title: () => 'Highest Delivery',
      statisticValue: (data) => truncateText((data.highestDeliveringFeature), TEXT_LENGTH_LIMITS[VizId.contextualFeatureInsights].title),
      statisticSuffix: () => '',
      description: (data) => `“${truncateText((data.highestDeliveringFeature), TEXT_LENGTH_LIMITS[VizId.contextualFeatureInsights].highestDelivery)}” accounted for 
      ${data.highestDeliveringPctDelivery}% ${budgetTypeWithCurrency(data, false)}  and 
      ${(deliveryPerformanceAgainstAvg(data.highestPerformingGoalPerformance, data.primaryGoalOverallValue))} 
      ${data.primaryGoalType}.`,
      stylingClassNames: 'breakword',
    },
  ],
  [VizId.deviceFeatureInsights]: [
    {
      title: () => 'Devices Above Goal',
      statisticValue: (data) => data.featureNamesAboveGoalCountString,
      statisticSuffix: (data) => ((data.featureNamesAboveGoalCount === 1) ? 'Device' : 'Devices'),
      description: (data) => (
        data.featureNamesAboveGoalCount > 0
          ? (`${data.featureNamesAboveGoalValue} accounted for ${data.deliveryPctFeaturesAboveGoal}% 
          of ${_.lowerCase(deliveryTypeWithCurrency(data, false))} and achieved 
          ${data.primaryGoalType} ${higherOrLowerByGoalType(data.lowerIsBetter)} than ${data.hasRevenueType ? 'revenue value' : 'goal target'}.`)
          : (`None of the devices targeted achieved ${data.primaryGoalType} 
          ${higherOrLowerByGoalType(data.lowerIsBetter)} than ${data.hasRevenueType ? 'revenue value' : 'goal target'}.`)),
    },
    {
      title: () => 'Best Performance',
      statisticValue: (data) => data.highestPerformingFeature,
      statisticSuffix: () => '',
      description: (data) => `${data.highestPerformingFeature} devices had the 
      ${highestOrLowestByGoalType(data.lowerIsBetter)}
      ${data.primaryGoalType} of ${data.highestPerformingGoalPerformance}${showFormattedGoalSymbol(data)} and accounted for 
      ${(goalPerformanceAgainstAvg(data.highestDeliveringObjDelivery, data.avgDeliveryAcrossFeatures))} 
      ${_.lowerCase(deliveryTypeWithCurrency(data, false))}.`,
    },
    {
      title: () => 'Highest Delivery',
      statisticValue: (data) => data.highestDeliveringFeature,
      statisticSuffix: () => '',
      description: (data) => `${data.highestDeliveringFeature} devices accounted for ${data.highestDeliveringPctDelivery}% ${budgetTypeWithCurrency(data, false)} and
      ${(deliveryPerformanceAgainstAvg(data.highestPerformingGoalPerformance, data.primaryGoalOverallValue))} 
      ${data.primaryGoalType}.`,
    },
  ],
  [VizId.geoInsights]: [
    {
      title: () => 'Regions Above Goal',
      statisticValue: (data) => (data.countFeaturesAboveGoal > 0 ? data.countFeaturesAboveGoal : 'No Regions'),
      statisticSuffix: (data) => (data.countFeaturesAboveGoal > 0 ? 'Regions' : ''),
      description: (data) => (
        data.countFeaturesAboveGoal > 0
          ? (`${data.countFeaturesAboveGoal} Provinces & Regions 
          accounted for ${data.deliveryPctFeaturesAboveGoal}% of ${_.lowerCase(deliveryTypeWithCurrency(data, false))} and 
          achieved ${data.primaryGoalType} ${higherOrLowerByGoalType(data.lowerIsBetter)} than ${data.hasRevenueType ? 'revenue value' : 'goal target'}.`)
          : (`None of the regions targeted achieved ${data.primaryGoalType} 
          ${higherOrLowerByGoalType(data.lowerIsBetter)} than ${data.hasRevenueType ? 'revenue value' : 'goal target'}.`)),
    },
    {
      title: () => 'Best Performance',
      statisticValue: (data) => truncateText(formatDspObjectName(data.highestPerformingFeature), TEXT_LENGTH_LIMITS[VizId.geoInsights].title),
      statisticSuffix: () => '',
      description: (data) => `${data.highestPerformingFeature} had the 
      ${highestOrLowestByGoalType(data.lowerIsBetter)}  
      ${data.primaryGoalType} of ${data.highestPerformingGoalPerformance}${showFormattedGoalSymbol(data)} 
      and accounted for 
      ${(goalPerformanceAgainstAvg(data.highestDeliveringObjDelivery, data.avgDeliveryAcrossFeatures))} 
      ${_.lowerCase(deliveryTypeWithCurrency(data, false))}.`,
      stylingClassNames: 'breakword',
    },
    {
      title: () => 'Highest Delivery',
      statisticValue: (data) => truncateText(formatDspObjectName(data.highestDeliveringFeature), TEXT_LENGTH_LIMITS[VizId.geoInsights].title),
      statisticSuffix: () => '',
      description: (data) => `${data.highestDeliveringFeature} accounted for ${data.highestDeliveringPctDelivery}% of
      ${_.lowerCase(spendOrDelivery(data))} and 
      ${(deliveryPerformanceAgainstAvg(data.highestPerformingGoalPerformance, data.avgDeliveryAcrossFeatures))} 
      ${data.primaryGoalType}.`,
      stylingClassNames: 'breakword',
    },
  ],
};
