import React from 'react';
import _ from 'lodash';

const WIDTH = 832;

type Props = {
  colors: Array<string>
  colorStops: Array<number>
  meanX: number
};

// colors should always be from small to large (that could be pink to green or green to pink, depending on whether lowerIsBetter)
export default ({ colorStops, colors, meanX }: Props) => {
  const excludeTextPosition = _.max([_.first(colorStops), 60]);
  const exploreTextPosition = meanX;
  const exploitTextPosition = _.min([_.last(colorStops), WIDTH - 40]);
  return (
    <svg id="exclude-explore-exploit" width={WIDTH} height="46" viewBox="0 0 831 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M791 12L1 13V33L791 32V41L830.5 21L791 1V12Z" fill="url(#paint0_linear)" />
        <text dx={excludeTextPosition} dy="26">Exclude</text>
        <text dx={exploreTextPosition} dy="26">Explore</text>
        <text dx={exploitTextPosition} dy="26">Exploit</text>
      </g>
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="28.4803" x2="792.534" y2="28.4803" gradientUnits="userSpaceOnUse">
          {
            _.map(colorStops, (c, i) => {
              const color = colors[i];
              return <stop key={color} offset={c / WIDTH} stopColor={color} />;
            })
          }
          <stop key="last" offset={1} stopColor={_.last(colors)} />
        </linearGradient>
      </defs>
    </svg>
  );
};
