import { TopDeliveringChild } from 'charts/BudgetOptimizationViz/types';

export const FLIGHT_CELL_WIDTH = 252;
export const VIZ_WIDTH = 521;
export const VIZ_HEIGHT = 480;
export const DELIVERY_RATIO_WIDTH = 170;
export const GOAL_PERFORMANCE_WIDTH = 225;

export type TopDeliveringChildWithGoal = TopDeliveringChild & {
  goalPerf: number
  dsp: number
  isClone: boolean
};

export const budgetAllocAvailScaleNotes = [
  'Visualizes how Copilot allocated budget and how the Buying Platform spent it.',
  'Displays the top line items by revenue.',
  'Average Daily Delivery represents how much the line item spent per day, on average.',
  'Average Daily Budget Allocated represents how much Copilot allocated to the line item per day, on average.',
  'In a lot of cases, Budget Allocation is a bit higher than Delivery. This is because, in order to deliver as much as possible on the best performing lines, Copilot allocates a bit more than what the line has shown it can spend.',
  'However, if the ratio of Delivery to Allocation falls below 70% we consider the line item to have reached its delivery capacity.',
  'If you see this on a valuable line item, you should review the configurations of that line and consider opening up targeting to increase scale.',
  'You may also see Delivery being higher than Budget Allocation. This is very rare and a symptom that something needs attention. Review the line item\'s history in the buying platform to check if daily budgets have been respected. Contact Copilot help for support in investigating further.',
];
