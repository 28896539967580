import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsData, StrategyGoalAnalyticsMetadataType } from 'containers/StrategyAnalytics/types';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { GlobalState } from 'reducers';
import { Flight, Strategy } from 'utils/types';
import { DSP, STRATEGY_TYPE } from 'constantsBase';
import { FeatureInsightsDatavizType } from './constants';
import InsightsProvider from './contexts/InsightsProvider';
import InsightsViz from './InsightsViz';
import { useIsYouTubeFetcher, BooleanFetchState, useIntelligentOptVizDataFetcher, useFeatureInsightsVizDataFetcher } from './utils';
import '../styles/insights.sass';

export const InsightsVizWithData = () => {
  const strategyAnalyticsMetadata = useSelector<GlobalState>((state) => state.strategyAnalytics.metadata);
  const {
    strategy,
    flights,
    analytics: cumDataAnalytics,
    strategyGoalAnalyticsMetadata: { attachments },
  } = strategyAnalyticsMetadata as { strategy: Strategy, flights: Array<Flight>, analytics: AnalyticsData, strategyGoalAnalyticsMetadata: StrategyGoalAnalyticsMetadataType };
  const { id: strategyId, strategyType, config } = strategy;
  const { dsp } = strategyType;
  const strategyTypeId = _.get(strategyType, 'id') as number;
  const isCrossPlatform = isCrossPlatformStrategyType(strategyTypeId);
  const isAmzn = strategyTypeId === STRATEGY_TYPE.amznBudgetOptimization.id;
  const isWalmart = _.some(flights, { dsp: DSP.WALMART.id }) || dsp === DSP.WALMART.id;
  const isCrossPlatformOrAmznOrWalmart = isCrossPlatform || isAmzn || isWalmart;
  const flightId = _.toNumber(_.get(_.first(flights), 'id'));
  const hasIntelligence = _.get(config, 'intelligentChildObjects');

  const intelligentOptDataFetchState = useIntelligentOptVizDataFetcher(
    strategyId,
    flightId,
    hasIntelligence,
    isCrossPlatformOrAmznOrWalmart,
  );

  const contextualFeatureInsightsDataFetchState = useFeatureInsightsVizDataFetcher(
    strategyId,
    flightId,
    FeatureInsightsDatavizType.featureSite,
    isCrossPlatformOrAmznOrWalmart,
  );

  const deviceFeatureInsightsDataFetchState = useFeatureInsightsVizDataFetcher(
    strategyId,
    flightId,
    FeatureInsightsDatavizType.featureDevice,
    isCrossPlatformOrAmznOrWalmart,
  );

  const geoFeatureInsightsDataFetchState = useFeatureInsightsVizDataFetcher(
    strategyId,
    flightId,
    FeatureInsightsDatavizType.featureGeo,
    isCrossPlatformOrAmznOrWalmart,
  );
  const isYouTubeFetchState = useIsYouTubeFetcher(strategyTypeId, attachments) as BooleanFetchState;
  const notEnoughData = _.size(cumDataAnalytics) < 3;

  if (notEnoughData) {
    return <div id="charts-empty-render">Insights data begins populating after 3 days of activation. Please check back later.</div>;
  }

  return (
    <InsightsProvider>
      <InsightsViz
        intelligentOptDataFetchState={intelligentOptDataFetchState}
        contextualFeatureInsightsFetchState={contextualFeatureInsightsDataFetchState}
        deviceFeatureInsightsFetchState={deviceFeatureInsightsDataFetchState}
        geoFeatureInsightsFetchState={geoFeatureInsightsDataFetchState}
        isYouTube={isYouTubeFetchState.data}
      />
    </InsightsProvider>
  );
};

export default InsightsVizWithData;
