import React from 'react';

type GoalHeaderProps = {
  title: string
  description?: string
};

const GoalHeader = (props: GoalHeaderProps) => (
  <>
    <p className="goal-title">{props.title}</p>
    <p className="goal-description">{props.description}</p>
  </>
);

export default GoalHeader;
