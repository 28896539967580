import _ from 'lodash';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { BudgetOptData, NotableBudget } from 'charts/BudgetOptimizationViz/types';
import { GOAL_TYPES, GOAL_VALUE_TYPE } from 'constantsBase';
import { StrategyGoalAnalyticsAttachmentChild, StrategyGoalAnalyticsDatum } from 'containers/StrategyAnalytics/types';
import { safeDivision } from 'utils/calculations';
import { Goal } from 'utils/types';
import { BudgetOptOverviewAggLevel, BudgetOptOverviewVizDataType } from '../BudgetOptimizationOverview/constants';
import { VizId, VALUE_PRECISION } from '../constants';
import { LastDayIsCloneDataType } from '../IntelligentOptimizationSummary/utils';
import { BudgetType, DeviceFeatureInsightsDatum, PrimaryStrategyGoal, ViewabiltyGoal } from '../types';
import { InsightsGoal } from '../transforms';
import { isRateBasedGoal, cleanSingleAppSiteName, UsableMetadata, getPrimaryGoalPerf, getPrimaryGoalKey } from '../utils';

const DISPLAY_STRINGS = {
  increase: 'increase',
  decrease: 'decrease',
  reduction: 'reduction',
  higher: 'higher',
  lower: 'lower',
  highest: 'highest',
  lowest: 'lowest',
  intelligent: 'Intelligent',
  original: 'Original',
  impressions: 'Impressions',
  spend: 'Spend',
  delivery: 'Delivery',
  impressionDelivery: 'Impression Delivery',
  average: 'average',
  higherThanAverage: 'higher than average',
  lowerThanAverage: 'lower than average',
  belowAverage: 'below average',
  aboveAverage: 'above average',
};

export const INSIGHTS_ARROW = {
  direction: {
    up: 'up',
    down: 'down',
  },
  color: {
    green: 'green',
    orange: 'orange',
  },
};

export const higherOrLowerByGoalType = (lowerIsBetter: boolean) => (lowerIsBetter ? DISPLAY_STRINGS.lower : DISPLAY_STRINGS.higher);

export const highestOrLowestByGoalType = (lowerIsBetter: boolean) => (lowerIsBetter ? DISPLAY_STRINGS.lowest : DISPLAY_STRINGS.highest);

export const showFormattedGoalSymbol = (data) => (isRateBasedGoal(data.primaryGoalTypeValue) ? '%' : ` ${data.primaryGoalSymbol}`);

export const showPercentageOnlyForRateBased = (data) => (isRateBasedGoal(data.primaryGoalTypeValue) ? '%' : '');

export const formatDisplayValueBasedOnGoalType = (kpiPerf: number, goalType: string, shouldRoundValue: boolean = false, returnEmptyStr: boolean = false) => {
  if (_.isNil(kpiPerf)) {
    return returnEmptyStr ? '' : 'N/A';
  }
  const value = isRateBasedGoal(goalType) ? +(kpiPerf * 100).toPrecision(VALUE_PRECISION) : +kpiPerf.toPrecision(VALUE_PRECISION);
  return shouldRoundValue ? _.round(value) : value;
};

export const performanceAndDeliveryGoalsDesc = (data) => {
  const { isSystemGoal, primaryGoalKpi, primaryGoal, primaryGoalDisplayName, currency, lowerIsBetter } = data;
  const label = lowerIsBetter ? ` ${currency}` : '%';
  const additionalViewabilityString = data.viewabilityKpi
    ? `and a ${formatDisplayValueBasedOnGoalType(data.viewabilityKpi, GOAL_TYPES.ivrMeasured.value, true)}% In-View Rate` : '';
  const kpiMetric = formatDisplayValueBasedOnGoalType(primaryGoalKpi, primaryGoal);
  return `
    Copilot achieved ${isSystemGoal ? `${data.successEventValue} ${data.successEvent} at` : ''} a
    ${primaryGoalDisplayName} of ${kpiMetric}${isSystemGoal ? label : ''}
    ${additionalViewabilityString}
  `;
};

export const budgetTypeWithCurrency = (data, showCurrency = true) => (
  data.budgetType === BudgetType.impressions ? DISPLAY_STRINGS.impressions : `${DISPLAY_STRINGS.spend} ${showCurrency ? `(${data.currency})` : ''}`
);
export const deliveryTypeWithCurrency = (data, showCurrency = true) => (
  data.budgetType === BudgetType.impressions ? `${DISPLAY_STRINGS.impressionDelivery}` : `${DISPLAY_STRINGS.spend} ${showCurrency ? `(${data.currency})` : ''}`
);
export const spendOrDelivery = (data) => (
  data.budgetType === BudgetType.impressions ? DISPLAY_STRINGS.delivery : DISPLAY_STRINGS.spend
);
export const showCurrencyOrImpressions = (data) => (
  data.budgetType === BudgetType.impressions ? _.lowerCase(DISPLAY_STRINGS.impressions) : data.currency
);

export const goalPerformanceAgainstAvg = (delivery, avgDeliveryAcrossFeatures) => {
  const performance = safeDivision(delivery, avgDeliveryAcrossFeatures);
  if (performance < 1) {
    return DISPLAY_STRINGS.belowAverage;
  }
  if (performance > 1) {
    return DISPLAY_STRINGS.aboveAverage;
  }
  return DISPLAY_STRINGS.average;
};

export const deliveryPerformanceAgainstAvg = (primaryGoalPerformance, avgPrimaryGoalPerformanceAcrossFeatures) => {
  const performance = safeDivision(primaryGoalPerformance, avgPrimaryGoalPerformanceAcrossFeatures);
  if (performance < 1) {
    return DISPLAY_STRINGS.lowerThanAverage;
  }
  if (performance > 1) {
    return DISPLAY_STRINGS.higherThanAverage;
  }
  return DISPLAY_STRINGS.average;
};

const getGoalInfo = (strategyGoal: Goal, currency: string) => {
  const { type, target } = strategyGoal;
  const goalTypeObj = _.get(GOAL_TYPES, type);
  const { shortText: goalType, value: goalTypeValue, valueType } = goalTypeObj;
  const goalSymbol = valueType === GOAL_VALUE_TYPE.CURRENCY ? currency : '%';
  return { goalType, target, goalSymbol, goalTypeValue };
};

const getGoalsInfo = (primaryGoal: InsightsGoal, secondaryGoal: InsightsGoal, currency: string) => {
  const {
    goalType: primaryGoalType, target: primaryGoalValue,
    goalSymbol: primaryGoalSymbol, goalTypeValue: primaryGoalTypeValue,
  } = getGoalInfo(primaryGoal, currency);
  if (secondaryGoal) {
    const {
      goalType: secondaryGoalType, target: secondaryGoalValue,
      goalSymbol: secondaryGoalSymbol, goalTypeValue: secondaryGoalTypeValue,
    } = getGoalInfo(secondaryGoal, currency);
    return {
      primaryGoalType,
      primaryGoalValue,
      primaryGoalSymbol,
      primaryGoalTypeValue,
      secondaryGoalType,
      secondaryGoalValue,
      secondaryGoalSymbol,
      secondaryGoalTypeValue,
    };
  }
  return { primaryGoalType, primaryGoalValue, primaryGoalSymbol, primaryGoalTypeValue };
};

const roundDecimalPercentage = (metric: number) => _.round(100 * metric);

const performanceAndDeliveryProcessFn = (data: {
  currency: string
  primaryStrategyGoal: PrimaryStrategyGoal
  mostRecentCumData: StrategyGoalAnalyticsDatum
  budgetOptData: BudgetOptData
}) => {
  const { currency, primaryStrategyGoal, mostRecentCumData, budgetOptData } = data;
  const { isSystemGoal, name, displayName, lowerIsBetter } = primaryStrategyGoal;
  const viewabilityGoal = _.get(data, 'viewabilityGoal');

  const successEvent = isSystemGoal && _.get(GOAL_TYPES[name], 'successEvent');
  const successEventValue = _.get(mostRecentCumData, _.camelCase(successEvent));
  const mostRecentPacingData = _.last(_.get(budgetOptData, 'pacingData', []));
  const primaryGoalKpi = getPrimaryGoalPerf(mostRecentCumData);
  const uplift = roundDecimalPercentage(_.get(mostRecentPacingData, 'estimatedUplift', 0));

  return {
    currency,
    primaryGoal: name,
    primaryGoalDisplayName: _.get(primaryStrategyGoal, 'shortText', displayName),
    primaryGoalKpi,
    uplift,
    upliftDirection: lowerIsBetter ? DISPLAY_STRINGS.decrease : DISPLAY_STRINGS.increase,
    delivery: roundDecimalPercentage(_.get(budgetOptData, 'eventBudgetDelivered', 0)),
    lowerIsBetter,
    isSystemGoal,
    ...(successEvent && { successEvent, successEventValue }),
    ...(!!viewabilityGoal && { viewabilityKpi: _.get(mostRecentCumData, GOAL_TYPES.ivrMeasured.value) }),
  };
};

const optimizationSummaryProcessFn = (data: {
  isCrossPlatform: boolean,
  parentCount, number,
  dspCount: number,
  lineItemCount: number,
  cloneCount: number,
  intelligentChildObjects: boolean,
  parentFlightName: string,
  childFlightName: string,
  viewabilityGoal: ViewabiltyGoal | false,
}) => data;

const budgetOptimizationOverviewProcessFn = (data: {
  lowerIsBetter: boolean
  childDisplayName: string
  totalChildCount: number
  totalDaysOfData: number
  goal: string
  valueType: string
  deliveryKpi: BudgetTypeMapping
  currency: string
  aggLevel: BudgetOptOverviewAggLevel
  aggDisplayText: string
  vizData: Array<BudgetOptOverviewVizDataType>
}) => {
  const { lowerIsBetter, vizData, totalDaysOfData, totalChildCount } = data;
  const bestPerformingGroup = lowerIsBetter ? _.minBy(vizData, 'goalPerf') : _.maxBy(vizData, 'goalPerf');

  return {
    ..._.omit(data, ['vizData', 'totalDaysOfData']),
    budgetAdjustments: totalDaysOfData * totalChildCount,
    totalDelivery: _.sumBy(vizData, 'delivery'),
    bestPerformingGroup: _.size(vizData) === 1 ? _.head(vizData) : bestPerformingGroup,
    highestDeliveryGroup: _.maxBy(vizData, 'delivery'),
  };
};

const budgetAllocationAndAvailableScaleProcessFn = (data: {
  lowerIsBetter: boolean
  goal: string
  childDisplayName: string
  allChildFlights: Array<StrategyGoalAnalyticsAttachmentChild>
  notableBudgetGrowth: NotableBudget
  notableBudgetReduction: NotableBudget
  childDeliveryCapacityCount: number
}) => {
  const { allChildFlights, notableBudgetGrowth, notableBudgetReduction } = data;
  return {
    ...data,
    totalChildFlights: _.size(allChildFlights),
    notableBudgetGrowth: {
      ...notableBudgetGrowth,
      isClone: _.find(allChildFlights, ['childId', notableBudgetGrowth.childExtId])?.isClone ?? false,
    },
    notableBudgetReduction: {
      ...notableBudgetReduction,
      isClone: _.find(allChildFlights, ['childId', notableBudgetReduction.childExtId])?.isClone ?? false,
    },
  };
};

const intelligentOptimizationSummaryProcessFn = (data: {
  totalDaysOfData: number
  totalIntelligentChildCount: number
  lastDayIsCloneCumData: LastDayIsCloneDataType
  goal: string
  lowerIsBetter: boolean
  intelligentCumDelPercent: number
  childDisplayName: string
}) => {
  const { lastDayIsCloneCumData: { original, intelligent } } = data;
  const goalKpiKey = getPrimaryGoalKey(original);
  const origGoalPerf = _.get(original, goalKpiKey, 0);
  const intGoalPerf = _.get(intelligent, goalKpiKey, 0);
  const intelligenceUplift = !origGoalPerf ? 0 : ((intGoalPerf - origGoalPerf) / origGoalPerf) * 100;
  return {
    ...data,
    goalUpliftDirection: intelligenceUplift > 0 ? DISPLAY_STRINGS.increase : DISPLAY_STRINGS.decrease,
    upliftPercentage: Math.abs(_.round(intelligenceUplift)),
  };
};

export const displayFeatureValues = (featureValues: { [key: string]: string }): string => {
  if (_.isEmpty(featureValues)) {
    return '';
  }
  return _.chain(featureValues)
    .map((v, k) => (k === 'site' ? cleanSingleAppSiteName(v) : v))
    .join(' | ')
    .value();
};

const intelligentOptimizationTargetingProcessFn = (data: UsableMetadata) => {
  const { primaryGoal, secondaryGoal, currency, statBoxes } = data;

  const { highestPerformingFeatureCombination, highestDeliveringFeatureCombination } = statBoxes;
  const highestPerformingFeatureValues = _.get(highestPerformingFeatureCombination, 'featureNameToValue');
  const highestDeliveringFeatureValues = _.get(highestDeliveringFeatureCombination, 'featureNameToValue');
  return {
    ...data,
    ...getGoalsInfo(primaryGoal, secondaryGoal, currency),
    ...statBoxes,
    highestPerformingFeatureValues: displayFeatureValues(highestPerformingFeatureValues),
    highestDeliveringFeatureValues: displayFeatureValues(highestDeliveringFeatureValues),
    highestPerformingGoalPerformance: formatDisplayValueBasedOnGoalType(highestPerformingFeatureCombination.primaryGoalPerformance, primaryGoal.type, false, true),
    highestDeliveringDelivery: _.round(highestDeliveringFeatureCombination.delivery),
  };
};

const featureInsightsProcessFn = (data: UsableMetadata) => {
  const { primaryGoal, secondaryGoal, currency, statBoxes } = data;
  const { featuresAboveGoal, highestDeliveringFeatureCombination, highestPerformingFeatureCombination } = statBoxes;
  const { featureNamesAboveGoal } = featuresAboveGoal;
  return {
    ...data,
    ...getGoalsInfo(primaryGoal, secondaryGoal, currency),
    ...statBoxes,
    ...featuresAboveGoal,
    deliveryPctFeaturesAboveGoal: _.round(featuresAboveGoal.deliveryPctFeaturesAboveGoal * 100),
    highestPerformingFeature: highestPerformingFeatureCombination.featureValue,
    highestPerformingGoalPerformance: formatDisplayValueBasedOnGoalType(highestPerformingFeatureCombination.primaryGoalPerformance, primaryGoal.type, false, true),
    highestDeliveringFeature: highestDeliveringFeatureCombination.featureValue,
    highestDeliveringPctDelivery: _.round(highestDeliveringFeatureCombination.deliveryPct * 100),
    featureNamesAboveGoalCount: _.size(featureNamesAboveGoal),
    featureNamesAboveGoal: _.join(featureNamesAboveGoal, ', '),
    highestDeliveringObjDelivery: +_.round(highestDeliveringFeatureCombination.delivery).toPrecision(VALUE_PRECISION),
  };
};

const contextualFeatureInsightsProcessFn = (data: UsableMetadata) => {
  const featureInsights = featureInsightsProcessFn(data);
  const highestPerformingFeature = cleanSingleAppSiteName(featureInsights.highestPerformingFeature);
  const highestDeliveringFeature = cleanSingleAppSiteName(featureInsights.highestDeliveringFeature);
  return {
    ...featureInsights,
    highestPerformingFeature,
    highestDeliveringFeature,
  };
};

const deviceFeatureInsightsProcessFn = (data: UsableMetadata & { kpisWithDevice: Array<DeviceFeatureInsightsDatum> }) => {
  const { kpisWithDevice } = data;
  const numDevices = _.size(kpisWithDevice);
  const insights = featureInsightsProcessFn(data);
  let featureNamesAboveGoalValue = insights.featureNamesAboveGoal;
  let featureNamesAboveGoalCountString;
  if (insights.featureNamesAboveGoalCount === 0) {
    featureNamesAboveGoalCountString = 'No';
    featureNamesAboveGoalValue = 'No';
  } else if (insights.featureNamesAboveGoalCount === numDevices) {
    featureNamesAboveGoalCountString = 'All';
    featureNamesAboveGoalValue = featureNamesAboveGoalValue.replace(/,\s([^,]+)$/, ' & $1');
  } else {
    featureNamesAboveGoalCountString = _.toString(insights.featureNamesAboveGoalCount);
    featureNamesAboveGoalValue = featureNamesAboveGoalValue.replace(/,\s([^,]+)$/, ' & $1');
  }
  return { ...insights, featureNamesAboveGoalValue, featureNamesAboveGoalCountString };
};

const geoInsightsProcessFn = (data: UsableMetadata) => {
  const featureInsights = featureInsightsProcessFn(data);
  const { highestPerformingFeatureCombination, highestDeliveringFeatureCombination } = featureInsights;
  const displayRegionKey = 'featureNameToValue.displayRegion';
  return {
    ...featureInsights,
    highestPerformingFeature: _.get(highestPerformingFeatureCombination, displayRegionKey, ''),
    highestDeliveringFeature: _.get(highestDeliveringFeatureCombination, displayRegionKey, ''),
  };
};

export const processInsightsDataForDisplay = {
  [VizId.performanceAndDelivery]: performanceAndDeliveryProcessFn,
  [VizId.optimizationSummary]: optimizationSummaryProcessFn,
  [VizId.budgetOptimizationOverview]: budgetOptimizationOverviewProcessFn,
  [VizId.budgetAllocationAndAvailableScale]: budgetAllocationAndAvailableScaleProcessFn,
  [VizId.intelligentOptimizationSummary]: intelligentOptimizationSummaryProcessFn,
  [VizId.intelligentOptimizationTargeting]: intelligentOptimizationTargetingProcessFn,
  [VizId.contextualFeatureInsights]: contextualFeatureInsightsProcessFn,
  [VizId.deviceFeatureInsights]: deviceFeatureInsightsProcessFn,
  [VizId.geoInsights]: geoInsightsProcessFn,
};

export const getInsightsArrow = (value, lowerIsBetter) => {
  const { direction, color } = INSIGHTS_ARROW;
  if (lowerIsBetter) {
    return _.includes([DISPLAY_STRINGS.decrease], value)
      ? { direction: direction.down, color: color.green }
      : { direction: direction.up, color: color.orange };
  }
  return _.includes([DISPLAY_STRINGS.increase], value)
    ? { direction: direction.up, color: color.green }
    : { direction: direction.down, color: color.orange };
};
