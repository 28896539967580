import React from 'react';
import _ from 'lodash';
import { ScaleThreshold } from 'd3';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { FEATURE_SHORT_NAMES } from 'charts/InsightsViz/constants';
import { OrigCloneMapping, BudgetType } from 'charts/InsightsViz/types';
import { getDeliveryTextUpper } from 'charts/InsightsViz/utils';
import ListRow from './ListRow';

export enum ListType {
  child = 'child',
  featureCombo = 'feature combo',
}

const formatFeatures = (features: Array<string>) => (
  _.chain(features)
    .map((f) => _.startCase(FEATURE_SHORT_NAMES[f] || f))
    .join(' | ')
    .value()
);

type BaseProps = {
  data: Array<any>
  budgetType: BudgetType
  goalMetricConfig: Metric & { target: number }
  features: Array<string>
  colorScale: ScaleThreshold<number, string>
  currency: string
  primaryGoalSuccessEvent: string
  withWrapping?: boolean
  overrideTitle?: string
};

type FeatureComboListProps = BaseProps & { type: ListType.featureCombo };

type ChildItemListProps = BaseProps & {
  type: ListType.child
  labels: { [childExtId: string]: string }
  cloneToOrig: OrigCloneMapping
  childExtTypeDisplayName: string
};

const getPerfTextReplacement = (perfStringToReplace: string) => {
  const replacements = {
    'Completion Rate': 'Comp.%',
    'Net CPCV': 'NetCPCV',
    'Net CPC': 'NetCPC',
  };
  return _.get(replacements, perfStringToReplace, perfStringToReplace);
};

export default (props: FeatureComboListProps | ChildItemListProps) => {
  const {
    data,
    budgetType,
    goalMetricConfig,
    features,
    colorScale,
    currency,
    withWrapping = true,
    primaryGoalSuccessEvent,
    overrideTitle,
  } = props;
  return (
    <div className={`top-list ${(withWrapping) ? 'with-text-wrapping' : ''}`}>
      <div className="list-header">
        <div className="title">
          {
            overrideTitle
            || `Top ${_.size(data)} ${formatFeatures(features)}s by ${getDeliveryTextUpper()({ budgetType })}`
          }
        </div>
      </div>
      <div className="list-body">
        <table>
          <thead>
            {
              props.type === ListType.child
                ? (
                  <tr>
                    <td>{props.childExtTypeDisplayName}</td>
                    <td className="delivery">{budgetType === BudgetType.advRevenue ? `${currency}` : 'Imps.'}</td>
                    <td>{getPerfTextReplacement(goalMetricConfig.shortText || goalMetricConfig.text)}</td>
                  </tr>
                )
                : (
                  <tr>
                    <td className="delivery">{budgetType === BudgetType.advRevenue ? `${currency}` : 'Imps.'}</td>
                    <td>{formatFeatures(features)}</td>
                    <td>{getPerfTextReplacement(goalMetricConfig.shortText || goalMetricConfig.text)}</td>
                  </tr>
                )
            }
          </thead>
          <tbody>
            {_.map(data, (d, idx) => (
              <ListRow
                key={`${d.name}-${idx}`}
                datum={d}
                budgetType={budgetType}
                goalMetricConfig={goalMetricConfig}
                type={props.type as (ListType.child & ListType.featureCombo)}
                colorScale={colorScale}
                features={features}
                primaryGoalSuccessEvent={primaryGoalSuccessEvent}
                cloneToOrig={props.type === ListType.child && props.cloneToOrig}
                labels={props.type === ListType.child && props.labels}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
