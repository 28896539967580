import _ from 'lodash';
import React from 'react';
import { StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import CrossPlatformBudgetDelivery from './CrossPlatformBudgetDelivery';
import CrossPlatformGoalChart from './CrossPlatformGoalChart';
import VizHeader from '../Components/VizHeader';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { getLastDayOfDataByKey } from '../utils';

type Props = {
  tooltip: string
};

const CrossPlatformGoalInfo = (props: Props) => {
  const { tooltip } = props;
  const { dspData, stratData, primaryStrategyGoal, currency, viewabilityGoal } = useInsightsContext();
  const dspToMostRecentCumData = getLastDayOfDataByKey(dspData.cumData, 'dsp');
  const mostRecentStratCumData = _.last(stratData.cumData) as StrategyGoalAnalyticsData;

  return (
    <div className="cross-platform-goal-info-container">
      <div className="viz-legend cross-platform-goal-viz-legend">
        <VizHeader title="Goals" tooltipContent={tooltip} />
        <div className="cross-platform-goal-chart-container">
          <CrossPlatformGoalChart
            goal={primaryStrategyGoal}
            currency={currency}
            mostRecentStratCumData={mostRecentStratCumData}
            dspToMostRecentCumData={dspToMostRecentCumData}
          />
          {viewabilityGoal && (
            <CrossPlatformGoalChart
              goal={viewabilityGoal}
              currency={currency}
              mostRecentStratCumData={mostRecentStratCumData}
              dspToMostRecentCumData={dspToMostRecentCumData}
            />
          )}
        </div>
      </div>
      <div className="viz-legend cross-platform-budget-delivery-container">
        <VizHeader title="Budget Distribution" />
        <CrossPlatformBudgetDelivery dspToMostRecentCumData={dspToMostRecentCumData} />
      </div>
    </div>
  );
};

export default CrossPlatformGoalInfo;
