import { DSP } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { DSP: dspColors, NEUTRALS } } = COPILOT_COLORS;

export const goalChartStyles = {
  ALL: { color: NEUTRALS.N800_MATTER },
  Goal: { color: NEUTRALS.N400_GRANITE },
  [DSP.APN.code]: { color: dspColors[DSP.APN.id].dark },
  [DSP.TTD.code]: { color: dspColors[DSP.TTD.id].dark },
  [DSP.DBM.code]: { color: dspColors[DSP.DBM.id].dark },
  [DSP.AMZN.code]: { color: dspColors[DSP.AMZN.id].dark },
  [DSP.WALMART.code]: { color: dspColors[DSP.WALMART.id].dark },
};

export type GoalChartVizData = {
  name: string
  color: string
  label: string
  value?: number // bar values
  point?: number // goal point & line
};

export type CrossPlatformBudgetDeliveryData = {
  name: string
  color: string
  value: number
};

export const performanceAndDeliveryNotes = [
  'Campaign Performance',
  'Compares the strategy\'s performance against (1) the estimated performance without Copilot and (2) the goal value entered in the strategy configuration.',
  'The distance between the goal line (gray, dashed) indicates how much better the strategy performed than the goal.',
  'The \'daily performance line\' is usually more volatile at the start and smoother towards the end, as copilot\'s optimizations take hold.',
  'The area between the \'estimated performance without Copilot\' and \'cumulative performance\' lines is the uplift Copilot brought to your campaign. Can also be thought of as added value - performance gains that would not have happened without Copilot.',
  'Goals (Right Hand Side)',
  'A breakout of the strategy\'s overall performance per buying platform (in the case of Cross Platform) for the primary goal and viewability goal (if enabled in the strategy settings).',
  'Campaign Delivery',
  'For Cross Platform strategies, displays revenue delivery per buying platform, per day. Fluctuations of delivery per platform are a sign of Copilot\'s budget optimizations. More on this topic in the following slides.',
  'For non Cross Platform strategies, it displays the revenue delivery per day.',
  'Cross Platform only: Budget distribution (Right Hand Side)',
  'Displays the overall budget delivery per buying platform. Whilst interesting, no conclusions should be made without analysing the next slides that delve into performance and available scale of each line item.',
];
