import React from 'react';
import { Popup, Icon } from 'buildingBlocks';

type Props = {
  title: string
  subtitle?: string
  tooltipContent?: string
};

export default (props: Props) => {
  const { title, subtitle, tooltipContent } = props;
  return (
    <div className="viz-header">
      <div className="viz-title">
        <h5>{title}</h5>
        {tooltipContent && (
          <Popup
            hoverable
            wide="very"
            trigger={<span><Icon name="question circle outline" /></span>}
          >
            <p>{tooltipContent}</p>
          </Popup>
        )}
      </div>
      { subtitle && <p className="subtitle">{subtitle}</p>}
    </div>
  );
};
