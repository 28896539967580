import _ from 'lodash';
import React from 'react';
import { ScaleLinear, format } from 'd3';
import { $Values } from 'utils/types';

export enum DeliveryLegendThicknesses {
  two = 2,
  four = 4,
  eight = 8,
  twelve = 12,
  sixteen = 16,
  twenty = 20,
}

export enum LegendLineType {
  rect = 'rect',
  line = 'line',
}

type Props = {
  id: string
  deliveryScale: ScaleLinear<number, number>
  thickness: $Values<typeof DeliveryLegendThicknesses>
  type?: LegendLineType
  chunkedName?: Array<JSX.Element>
};

type LegendLineByNameProps = Omit<Props, 'deliveryScale' | 'id'>;

const LegendLineByName = ({ chunkedName, thickness, type = LegendLineType.rect }: LegendLineByNameProps) => (
  <g>
    {(type === LegendLineType.rect)
      ? <rect height={thickness} />
      : <line x1="0" y1="0" x2="60" y2="0" />}
    {_.forEach(chunkedName, (name) => name)};
  </g>
);

export default ({ id, deliveryScale, thickness, type, chunkedName }: Props) => {
  if (chunkedName) {
    return <LegendLineByName chunkedName={chunkedName} thickness={thickness} type={type} />;
  }
  const val = deliveryScale.invert(thickness);
  const numFormat = (value: number) => format(value >= 1 ? '.2s' : '.2f')(value); // 2 sig figs
  return (
    <g key={id}>
      <rect height={thickness} />
      <text>{numFormat(val)}</text>
    </g>
  );
};
