import React from 'react';
import ModelBadgeLarge from './ModelBadgeLarge';

export enum ModelName {
  budgetOpt = 'budget optimization',
  viewabilityOpt = 'viewability optimization',
  intelligentFlights = 'intelligent flights',
  bidOpt = 'bid optimization',
}

const MODEL_DESCRIPTIONS = {
  [ModelName.budgetOpt]: (childExtTypeDisplayName: string) => ({
    name: 'budget optimization',
    fullVersion: `Copilot dynamically adjusts budget across ${childExtTypeDisplayName}s to maximize performance.`,
    condensedVersion: `Copilot dynamically adjusts budget across ${childExtTypeDisplayName}s.`,
  }),
  [ModelName.viewabilityOpt]: () => ({
    name: 'viewability optimization',
    fullVersion: 'Copilot adjusts viewability thresholds to exclude inventory below the viewability goal.',
  }),
  [ModelName.intelligentFlights]: (childExtTypeDisplayName: string) => ({
    name: `intelligent ${childExtTypeDisplayName}s`,
    fullVersion: `Copilot generates Intelligent ${childExtTypeDisplayName}s to dynamically target high value inventory.`,
    condensedVersion: 'Intelligent Lines dynamically target high value inventory.',
  }),
  [ModelName.bidOpt]: () => ({
    name: 'bid optimization',
    fullVersion: 'Copilot identifies clusters of feature combinations and determines bid values.',
  }),
};

export enum DescriptionVersion {
  full = 'full',
  condensed = 'condensed',
}
type Props = {
  modelName: ModelName
  version?: DescriptionVersion
  childExtTypeDisplayName?: string
};

export default (props: Props) => {
  const {
    modelName,
    version = DescriptionVersion.full,
    childExtTypeDisplayName = '' } = props;
  const modelDescriptionOptions = MODEL_DESCRIPTIONS[modelName](childExtTypeDisplayName);
  const descriptionAccessor = (version === DescriptionVersion.condensed) ? 'condensedVersion' : 'fullVersion';
  const modelDescription = modelDescriptionOptions[descriptionAccessor];

  return (
    <div className="model-badge-description">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ModelBadgeLarge modelName={modelName} />
        <div>
          <span className="header-4">{modelDescriptionOptions.name}</span>
          <p>{modelDescription}</p>
        </div>
      </div>
    </div>
  );
};
