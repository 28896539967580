import { Options } from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { StrategyGoalAnalyticsDatum } from 'containers/StrategyAnalytics/types';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { COPILOT_COLORS } from 'globalStyles';

const deliveryChartConfig = (daily, title): Options => ({
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    height: 245,
    width: 846,
    animation: false,
    style: { fontFamily: 'Gilroy' },
  },
  plotOptions: {
    series: {
      animation: false,
    },
    column: {
      animation: false,
      pointPadding: 0,
      borderWidth: 0,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  xAxis: {
    title: {
      text: null,
    },
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    tickWidth: 1,
    type: 'datetime',
    dateTimeLabelFormats: { day: '%e %b' },
    labels: {
      step: 2,
      align: 'center',
    },
  },
  yAxis: {
    title: {
      text: title,
      align: 'middle',
      rotation: -90,
      y: 5,
      style: {
        fontSize: '12px',
        fontWeight: '500',
      },
    },
    gridLineColor: 'transparent',
    tickWidth: 1,
  },
  series: [
    {
      name: '',
      type: 'column',
      data: daily,
      color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B200_LAGOON,
      showInLegend: false,
    },
  ],
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
});

type SinglePlatformDeliveryChartProps = {
  dailyPerfData: Array<StrategyGoalAnalyticsDatum>
  budgetType: BudgetTypes
  title: string
};

const SinglePlatformDeliveryChart = (props: SinglePlatformDeliveryChartProps) => {
  const { dailyPerfData, budgetType, title } = props;
  const daily = _.map(dailyPerfData, (i) => [moment(i.date).valueOf(), i[BudgetTypeMapping[budgetType]] ?? 0]);

  return (
    <ReactHighcharts
      config={deliveryChartConfig(daily, title)}
      isPureConfig
    />
  );
};

export default SinglePlatformDeliveryChart;
