import React from 'react';
import { DeviceImageComponentProps } from '../types';

export default ({ x, y }: DeviceImageComponentProps) => (
  <svg width="295" height="202" viewBox="0 0 295 202" fill="none" xmlns="http://www.w3.org/2000/svg" x={x} y={y}>
    <path d="M143.784 169.111V200.321H150.944V169.111H143.784Z" fill="white" stroke="black" strokeWidth="0.25" />
    <path d="M289.419 0.332031H5.31514C2.56304 0.332031 0.332031 2.56304 0.332031 5.31514V169.21C0.332031 171.962 2.56304 174.193 5.31514 174.193H289.419C292.171 174.193 294.402 171.962 294.402 169.21V5.31514C294.402 2.56304 292.171 0.332031 289.419 0.332031Z" fill="white" stroke="black" strokeWidth="0.25" />
    <path d="M277.449 13.9165H17.2852V159.98H277.449V13.9165Z" fill="white" stroke="black" strokeWidth="0.25" />
    <path d="M223.522 193.147H71.2118C69.2309 193.147 67.625 194.753 67.625 196.734V196.734C67.625 198.715 69.2309 200.321 71.2118 200.321H223.522C225.503 200.321 227.109 198.715 227.109 196.734C227.109 194.753 225.503 193.147 223.522 193.147Z" fill="white" />
    <path d="M71.2118 193.147H223.522C225.503 193.147 227.109 194.753 227.109 196.734V196.734C227.109 198.715 225.503 200.321 223.522 200.321H71.2118C69.2309 200.321 67.625 198.715 67.625 196.734V196.734C67.625 194.753 69.2309 193.147 71.2118 193.147Z" stroke="black" strokeWidth="0.25" />
    <path d="M266.858 164.767C267.358 165.085 267.738 165.556 267.948 166.11C268.155 166.663 268.179 167.27 268.017 167.838C267.852 168.406 267.512 168.907 267.039 169.265C266.568 169.622 265.997 169.818 265.405 169.822C264.815 169.827 264.239 169.64 263.763 169.29C263.287 168.939 262.937 168.444 262.765 167.878C262.593 167.312 262.608 166.706 262.808 166.149C263.009 165.593 263.383 165.116 263.876 164.79" stroke="black" strokeWidth="0.25" />
    <path d="M265.122 166.804V164.475" stroke="black" strokeWidth="0.25" />
  </svg>
);
