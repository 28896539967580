import React from 'react';
import { DeviceImageComponentProps } from '../types';

export default ({ x, y }: DeviceImageComponentProps) => (
  <svg width="59" height="120" viewBox="0 0 59 120" fill="none" xmlns="http://www.w3.org/2000/svg" x={x} y={y}>
    <path d="M55.0442 14.7729H3.31689V105.773H55.0442V14.7729Z" stroke="black" strokeWidth="0.25" />
    <path d="M50.5452 0.227051H7.27251C3.38141 0.227051 0.227051 3.38141 0.227051 7.27251V112.727C0.227051 116.618 3.38141 119.773 7.27251 119.773H50.5452C54.4363 119.773 57.5907 116.618 57.5907 112.727V7.27251C57.5907 3.38141 54.4363 0.227051 50.5452 0.227051Z" stroke="black" strokeWidth="0.25" />
    <path d="M29.5455 9.31814C30.1731 9.31814 30.6819 8.80937 30.6819 8.18177C30.6819 7.55418 30.1731 7.04541 29.5455 7.04541C28.9179 7.04541 28.4092 7.55418 28.4092 8.18177C28.4092 8.80937 28.9179 9.31814 29.5455 9.31814Z" stroke="black" strokeWidth="0.25" />
    <path d="M29.5455 117.273C31.8048 117.273 33.6364 115.441 33.6364 113.182C33.6364 110.922 31.8048 109.091 29.5455 109.091C27.2862 109.091 25.4546 110.922 25.4546 113.182C25.4546 115.441 27.2862 117.273 29.5455 117.273Z" stroke="black" strokeWidth="0.25" />
  </svg>
);
