import React from 'react';
import { Icon } from 'buildingBlocks';
import { IconConfig, IconType } from '../constants';

type Props = {
  section: string
  subSection?: string
  icon: IconConfig
};

export default (props: Props) => {
  const { section, subSection, icon } = props;
  return (
    <div className="slide-title header-2">
      {icon.kind === IconType.fontAwesome
        ? <Icon name={icon.name} />
        : <img src={`/img/icons/insights/${icon.name}.svg`} alt={icon.name} />}
      {section}
      {subSection ? ` ${String.fromCharCode(62)} ${subSection}` : ''}
    </div>
  );
};
