import _ from 'lodash';
import React, { useState } from 'react';
import { DSP, STRATEGY_TYPE } from 'constantsBase';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { COPILOT_MODELS, CopilotModels, optSummaryNotes } from './constants';
import CopilotModel from './CopilotModel';
import CrossPlatformOptSummaryViz from './CrossPlatformOptSummaryViz';
import SinglePlatformOptSummary from './SinglePlatformOptSummary';
import { CopilotModelType } from './types';
import ErrorSegment from '../Components/ErrorSegment';
import InsightsBox from '../Components/InsightsBox';
import SlideTitle from '../Components/SlideTitle';
import SlideWrapper from '../Components/SlideWrapper';
import VizHeader from '../Components/VizHeader';
import { SlideIcons, VizId } from '../constants';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { BaseInsightsSlideProps } from '../types';
import { getChildDisplayName, getInsightsByVizId, getParentDisplayName } from '../utils';

const vizId = VizId.optimizationSummary;

const OptimizationSummary = ({ onVizLoaded, onVizError }: BaseInsightsSlideProps) => {
  const [dataError, setDataError] = useState<boolean>(false);
  const {
    metadata: { attachments },
    viewabilityGoal,
    strategy: {
      strategyType: { id: stratTypeId },
      config: { intelligentChildObjects },
    },
  } = useInsightsContext();

  useMount(() => {
    if (!attachments || !stratTypeId) {
      setDataError(true);
      onVizError();
    }
    onVizLoaded(optSummaryNotes);
  });

  if (dataError) {
    return <ErrorSegment />;
  }

  const isCrossPlatform = isCrossPlatformStrategyType(stratTypeId);
  const dsp = STRATEGY_TYPE.getById(stratTypeId).dsp;
  const parentFlightName = getParentDisplayName(dsp);
  const childFlightName = getChildDisplayName(dsp);

  const subtitle = isCrossPlatform ? 'Strategy hierarchy and Copilot model application sorted by delivery' : `${_.capitalize(parentFlightName)} hierarchy and Copilot model application`;
  const tooltipContent = `Visualizes the Strategy hierarchy. Copilot models denoted by colored icons can be applied to both the ${_.lowerCase(parentFlightName)} and ${_.lowerCase(childFlightName)} levels. See descriptions of each model on the right-hand side.`;
  const containsTTDFlight = _.includes(_.map(attachments, 'dsp'), DSP.TTD.id);

  const filteredCopilotModels = _.omit(COPILOT_MODELS, [
    ...viewabilityGoal ? [] : [CopilotModels.viewabilityControl],
    ...intelligentChildObjects ? [] : [CopilotModels.intelligentLineItems],
    ...containsTTDFlight ? [] : [CopilotModels.bidModeling],
  ]);

  const dspCount = _.size(_.uniqBy(attachments, 'dsp'));
  const lineItems = _.flatMap(attachments, 'children');

  return (
    <div id={vizId} className="slide">
      <SlideTitle section="Optimization Insights" subSection="Optimization Summary" icon={SlideIcons.optimizationSummary} />
      <div className="flex-container">
        <div className="main-visualization">
          <VizHeader
            title="How Copilot Was Applied To Your Strategy"
            subtitle={subtitle}
            tooltipContent={tooltipContent}
          />
          {isCrossPlatform
            ? <CrossPlatformOptSummaryViz />
            : <SinglePlatformOptSummary />}
        </div>
        <div>
          <div className="viz-legend">
            <VizHeader title="Copilot Models" />
            {_.map(filteredCopilotModels, (model: CopilotModelType, key: string) => (
              <CopilotModel
                key={key}
                model={model}
              />
            ))}
          </div>
        </div>
      </div>
      <InsightsBox
        insights={getInsightsByVizId(
          vizId,
          {
            isCrossPlatform,
            dspCount,
            parentCount: _.size(attachments),
            lineItemCount: _.size(lineItems),
            cloneCount: _.size(_.filter(lineItems, 'isClone')),
            intelligentChildObjects,
            parentFlightName,
            childFlightName,
            viewabilityGoal,
          },
        )}
      />
    </div>
  );
};

export default React.memo(SlideWrapper(OptimizationSummary));
