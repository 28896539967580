import { Options, dateFormat } from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import { GroupedIsCloneDataType } from './utils';
import { areaSplineColors } from '../constants';
import { findIntersectionOnChart, getMinValue, getMaxValue, getPrimaryGoalKey } from '../utils';

const { transparent, white, green, lineGrey, graphGrey, blue } = areaSplineColors;

const calculateGraphData = (groupedIsCloneCumData: GroupedIsCloneDataType, lowerIsBetter: boolean) => {
  const { original, intelligent } = groupedIsCloneCumData;
  const goalKpiKey = getPrimaryGoalKey(_.head(original));
  const [originalRange, intelligentRange, originalColorZones, intelligentColorZones] = [[], [], [], []];
  let isIntelligentHigher;

  _.forEach(original, (data: StrategyGoalAnalyticsData, index: number) => {
    const { date: origDate } = data;
    const originalValue = _.get(data, goalKpiKey, 0);
    const intelligentValue = _.get(
      _.find(intelligent, ['date', origDate]),
      goalKpiKey,
      _.last(intelligentRange) ? _.last(intelligentRange)[1] : null, // use last value if data is unavailable
    );

    originalRange.push([index, originalValue]);
    intelligentRange.push([index, intelligentValue]);

    const prevIndex = index - 1;

    // initialize the first point when intelligentValue is available
    if (_.isEmpty(intelligentColorZones) && intelligentValue) {
      intelligentColorZones.push({ value: index, fillColor: transparent });
      originalColorZones.push({ value: index, fillColor: transparent });
      isIntelligentHigher = intelligentValue > originalValue;
    }

    const value = findIntersectionOnChart(
      prevIndex,
      index,
      _.get(intelligent, `[${prevIndex}].${goalKpiKey}`, 0),
      intelligentValue,
      _.get(original, `[${prevIndex}].${goalKpiKey}`, 0),
      originalValue,
    );
    if (intelligentValue) {
      if (intelligentValue > originalValue && !isIntelligentHigher) {
        intelligentColorZones.push({ value, fillColor: lowerIsBetter ? white : transparent });
        originalColorZones.push({ value, fillColor: lowerIsBetter ? green : transparent });
        isIntelligentHigher = true;
      }
      if (intelligentValue < originalValue && isIntelligentHigher) {
        intelligentColorZones.push({ value, fillColor: lowerIsBetter ? transparent : green });
        originalColorZones.push({ value, fillColor: lowerIsBetter ? transparent : white });
        isIntelligentHigher = false;
      }
    }
  });

  const dataLength = original.length;
  if (isIntelligentHigher) {
    originalColorZones.push({ value: dataLength, fillColor: lowerIsBetter ? transparent : white });
    intelligentColorZones.push({ value: dataLength, fillColor: lowerIsBetter ? transparent : green });
  } else if (!lowerIsBetter) {
    originalColorZones.push({ value: dataLength, fillColor: transparent });
    intelligentColorZones.push({ value: dataLength, fillColor: transparent });
  } else {
    originalColorZones.push({ value: dataLength, fillColor: green });
    intelligentColorZones.push({ value: dataLength, fillColor: white });
  }
  return [originalRange, intelligentRange, originalColorZones, intelligentColorZones];
};

const getOriginalSeries = (cumulativeOriginal, originalColorZones) => ({
  data: cumulativeOriginal,
  type: 'areaspline',
  color: lineGrey,
  zIndex: 0,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: false,
      },
    },
  },
  lineWidth: 2,
  showInLegend: false,
  zoneAxis: 'x',
  zones: originalColorZones,
});

const getIntelligentSeries = (cumulativeIntelligent, intelligentColorZones) => ({
  data: cumulativeIntelligent,
  type: 'areaspline',
  color: blue,
  zIndex: 0,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: false,
      },
    },
  },
  lineWidth: 2,
  showInLegend: false,
  zoneAxis: 'x',
  zones: intelligentColorZones,
});

const getGoalSeries = (goalValue) => ({
  data: goalValue,
  type: 'spline',
  color: graphGrey,
  zIndex: 5,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: false,
      },
    },
  },
  lineWidth: 1,
  dashStyle: 'Dash',
  showInLegend: false,
});

const getChartSeries = (cumulativeOriginal, originalColorZones, cumulativeIntelligent, intelligentColorZones, goalValue, lowerIsBetter) => {
  const originalSeries = getOriginalSeries(cumulativeOriginal, originalColorZones);
  const intelligentSeries = getIntelligentSeries(cumulativeIntelligent, intelligentColorZones);
  const goalSeries = getGoalSeries(goalValue);

  if (lowerIsBetter) {
    return [originalSeries, intelligentSeries, goalSeries];
  }
  return [intelligentSeries, originalSeries, goalSeries];
};

const getConfig = (series, title, minY, maxY, dates): Options => ({
  chart: {
    height: 270,
    width: 750,
    animation: false,
    style: { fontFamily: 'Gilroy' },
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      shadow: false,
      states: {
        hover: {
          enabled: false,
        },
      },
      events: {
        legendItemClick: () => false,
      },
    },
  },
  legend: {
    itemStyle: {
      fontWeight: '400',
      fontSize: '10px',
      color: graphGrey,
    },
  },
  xAxis: {
    title: {
      text: null,
    },
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    tickWidth: 1,
    type: 'datetime',
    labels: {
      align: 'center',
      // x-axis values are indices so we need to reference the correct date
      formatter: ({ value: idx }) => dateFormat('%e %b', dates[idx]),
    },
  },
  yAxis: {
    title: {
      text: title,
      align: 'middle',
      rotation: -90,
      y: 5,
      style: {
        fontSize: '12px',
      },
    },
    tickWidth: 1,
    gridLineColor: 'transparent',
    min: minY,
    max: maxY,
  },
  series,
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
});

type IntelligentOptPerformanceChartProps = {
  groupedIsCloneCumData: GroupedIsCloneDataType
  goal: string
  goalTarget: number
  lowerIsBetter: boolean
  valueType: string
  currency: string
};

const IntelligentOptPerformanceChart = (props: IntelligentOptPerformanceChartProps) => {
  const { groupedIsCloneCumData, goal, goalTarget, lowerIsBetter, valueType, currency } = props;
  const { original } = groupedIsCloneCumData;
  const yAxisTitle = `${_.upperCase(goal)}${valueType === GOAL_VALUE_TYPE.CURRENCY ? ` (${currency})` : ''}`;
  const dates = _.map(original, ({ date }) => moment(date));
  const goalSeries = _.map(original, (_o, idx) => [idx, goalTarget]);
  const [
    cumulativeOriginal, cumulativeIntelligent, originalColorZones, intelligentColorZones,
  ] = calculateGraphData(groupedIsCloneCumData, lowerIsBetter);
  const series = getChartSeries(cumulativeOriginal, originalColorZones, cumulativeIntelligent, intelligentColorZones, goalSeries, lowerIsBetter);
  const minValue = Math.min(getMinValue(cumulativeOriginal), getMinValue(cumulativeIntelligent), goalTarget);
  const maxValue = Math.max(getMaxValue(cumulativeOriginal), getMaxValue(cumulativeIntelligent), goalTarget);

  return <ReactHighcharts config={getConfig(series, yAxisTitle, minValue, maxValue, dates)} isPureConfig />;
};

export default IntelligentOptPerformanceChart;
