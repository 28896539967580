import _ from 'lodash';
import { deviation, max, mean, ScaleLinear, scaleSqrt, scaleThreshold } from 'd3';
import { GOAL_TYPES, RevenueType } from 'constantsBase';
import metrics, { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { getColorScaleDomain, getValForZScore } from 'charts/utils';
import { getActiveStrategyGoalsInPriorityOrder } from 'utils';
import { StrategyGoalDB, StrategyGoalsDB, GoalType } from 'utils/types';
import { DEVICE_GRAPHIC } from './constants';
import { DeviceSizePositionFeatureInsightsDatum, CreativeDatum, CreativeDatumWithPosition, BudgetType } from './types';
import { isRateBasedGoal } from './utils';

const isHorizontalCreative = (width: number, height: number) => width / height > 1;

const getDimensions = (creativeSize: string, deviceType: string): Array<number> => {
  if (creativeSize === 'Unknown') {
    const deviceWidth = _.get(DEVICE_GRAPHIC, `${deviceType}.pixelWidth`, 0);
    const deviceHeight = _.get(DEVICE_GRAPHIC, `${deviceType}.pixelHeight`, 0);
    return [deviceWidth * 0.75, (deviceHeight / 2) * 0.75];
  }
  return _.map(_.split(creativeSize, 'x'), _.toNumber);
};

/*
  Figure out the width, height, placement, device attrs of a creative based on the string devicePlacementSize
  Also determine if it's a horizontal banner or not
*/
export const getCreativeDimension = (data: Array<DeviceSizePositionFeatureInsightsDatum>):
Array<CreativeDatum> => _.map(data, (d) => {
  const { creativeSize, deviceType } = d;
  const [width, height] = getDimensions(creativeSize, deviceType);
  return {
    ...d,
    width,
    height,
    horizontal: isHorizontalCreative(width, height),
  };
});

const reAdjustLayout = (data: Array<CreativeDatumWithPosition>, incr: number) => (
  _.map(data, (d, i) => ({
    ...d,
    x: (i > 0) ? Math.max(d.x - (incr / (data.length - i)), 0) : d.x,
  }))
);

// Creatives are laid out based on device and placement
export const layoutCreative = (data: Array<CreativeDatum | CreativeDatumWithPosition>, horizontal: boolean) => {
  // keeps track of accumulating x position of "creative" rectangles
  const x = {};
  // keeps track of x, y layouts for given device, placement
  const layout = {};
  _.forEach(data, (d) => {
    const deviceConfig = DEVICE_GRAPHIC[d.deviceType];

    if (!deviceConfig) {
      return;
      /*
        NOTE FOR FUTURE VERSIONS

        In theory, we could visualize all of the following devices:
        Other Devices
        Desktops and Laptops
        Mobile Phones
        Tablets
        TV
        Game Console
        Media Players
        Set Top Box
      */
    }
    const maxWidth = deviceConfig.pixelWidth;
    const placementText = d.foldPosition;

    if (_.isNil(x[d.deviceType])) {
      x[d.deviceType] = {
        'Above Fold': 0,
        'Below Fold': 0,
        Unknown: 0,
      };
    }

    if (_.isNil(layout[d.deviceType])) {
      layout[d.deviceType] = {
        'Above Fold': [],
        'Below Fold': [],
        Unknown: [],
      };
    }

    const prevXPos = x[d.deviceType][placementText];
    const layoutsForDevicePlacement = layout[d.deviceType][placementText];

    // Position creatives within the same layout group side-by-side in a row until out of horizontal space
    const xPos = (prevXPos + d.width > maxWidth) ? maxWidth - d.width : prevXPos;

    // If there are no more horizonal space, push back all the previous creatives in the same layout by a bit
    if (xPos < prevXPos && layoutsForDevicePlacement.length > 1) {
      reAdjustLayout(layoutsForDevicePlacement, Math.abs(xPos - prevXPos) / (layoutsForDevicePlacement.length - 1));
    }

    x[d.deviceType][placementText] = xPos + d.width;
    layoutsForDevicePlacement.push({
      ...d,
      x: xPos,
      y: horizontal ? 0 : deviceConfig.horizontalRowPixelHeight,
    });
  });

  return _.flatMapDeep(_.values(layout), _.values);
};

/*
  Separate horizonal and vertical creatives and position each group on the device
  Horizontal creatives are displayed in a row above vertical creatives,
  except in the case of "Below" placement, where the order is reversed
*/
export const getCreativeLayout = (data: Array<CreativeDatum>) => {
  const [horizontalCreatives, verticalCreatives] = _.partition(data, (d) => d.horizontal);
  const horizontalLayout = layoutCreative(horizontalCreatives, true);
  const verticalLayout = layoutCreative(verticalCreatives, false);
  return [...horizontalLayout, ...verticalLayout];
};

export const transformCreativeData = (data: Array<DeviceSizePositionFeatureInsightsDatum>)
: Array<CreativeDatumWithPosition> => getCreativeLayout(getCreativeDimension(data));

type SvgGutters = { top: number, bottom: number, left: number, right: number };
type Dimensions = { height: number, width: number };
type GoalConfig = Metric & { target: number };
type DeliveryConfig = {
  rangeMin: number
  maxZScore?: number
  budgetTypeAccessor?: string
  clamp?: boolean
};
type CircleRadiusConfig = {
  minCircleRadius?: number
  maxCircleRadius: number
  clamp?: boolean
};
type PerfScaleConfig = {
  strategyGoalConfigWithTarget: GoalConfig
  scale?: () => ScaleLinear<number, number>
  paddingLeft?: number
  maxZScore?: number
  clamp?: boolean
};
type ColorScaleConfig = {
  colorScaleRange: Array<string>
  strategyGoalConfigWithTarget: GoalConfig
};
type ScaleBuilderConfig = {
  perfAndDeliveryData,
  budgetType: BudgetType,
  primaryGoalSuccessEvent: string,
  primaryGoalOverallValue: number,
  excludeDataSansSuccessEvents: boolean,
  limitDataN?: number,
  dimensions?: Dimensions,
  svgGutters?: SvgGutters,
};
const defaultDims = { width: 0, height: 0 };
const defaultGutters = { left: 0, top: 0, right: 0, bottom: 0 };

enum DataSelection {
  allData = 'allData',
  topNData = 'topNData',
}

export class VizScaleBuilder {
  allData: Array<any>;

  topNData: Array<any>;

  dataSansSuccessEvents: Array<any>;

  dataWithSuccessEvents: Array<any>;

  countNoSuccessEvents?: number;

  budgetType: BudgetType | 'deliveryAverage';

  dimensions: Dimensions;

  svgGutters: SvgGutters;

  deliveryScale: ScaleLinear<number, number>;

  meanDelivery: number;

  stdDevDelivery: number;

  perfScale: ScaleLinear<number, number>;

  meanPerf: number;

  stdDevPerf: number;

  constructor({
    perfAndDeliveryData,
    budgetType,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    excludeDataSansSuccessEvents,
    limitDataN,
    svgGutters = defaultGutters,
    dimensions = defaultDims,
  }: ScaleBuilderConfig) {
    this.dimensions = dimensions;
    this.svgGutters = svgGutters;
    this.budgetType = budgetType;
    this.meanPerf = primaryGoalOverallValue;

    let topNData;
    const [dataWithSuccessEvents, dataSansSuccessEvents] = _.partition(perfAndDeliveryData, (d) => d[primaryGoalSuccessEvent] > 0);
    this.dataWithSuccessEvents = dataWithSuccessEvents;
    if (excludeDataSansSuccessEvents) {
      this.countNoSuccessEvents = _.size(dataSansSuccessEvents);
      topNData = _.isNil(limitDataN) ? dataWithSuccessEvents : _.take(_.orderBy(dataWithSuccessEvents, budgetType, 'desc'), limitDataN);
    } else {
      topNData = _.isNil(limitDataN) ? perfAndDeliveryData : _.take(_.orderBy(perfAndDeliveryData, budgetType, 'desc'), limitDataN);
    }
    this.allData = perfAndDeliveryData;
    this.topNData = topNData;
  }

  // minVal could be bubble max radius
  getDeliveryScale({
    rangeMin,
    maxZScore = 2,
    budgetTypeAccessor = this.budgetType,
    clamp = false,
  }: DeliveryConfig) {
    const stdDevDelivery = deviation(this.allData, (d) => d[budgetTypeAccessor]);
    this.stdDevDelivery = stdDevDelivery;
    const meanDelivery = mean(this.allData, (d) => d[budgetTypeAccessor]);
    this.meanDelivery = meanDelivery;

    const deliveryMin = _.max([0, getValForZScore(-maxZScore, meanDelivery, stdDevDelivery)]);

    const deliveryMax = (maxZScore === Infinity)
      ? max(this.allData, (d) => d[budgetTypeAccessor])
      : getValForZScore(maxZScore, meanDelivery, stdDevDelivery);

    const deliveryScale = scaleSqrt<number, number>()
      .domain([
        deliveryMin,
        deliveryMax,
      ])
      .range([this.dimensions.height - this.svgGutters.bottom - this.svgGutters.top, rangeMin])
      .clamp(clamp);
    this.deliveryScale = deliveryScale;
    return deliveryScale;
  }

  getPerfDomain({
    dataSelection,
    maxZScore,
    goalTextValue,
    lowerIsBetter,
  }) {
    const data = (dataSelection === DataSelection.allData) ? this.allData : this.topNData;

    const stdDevPerf = deviation(data, (d) => d[goalTextValue]);

    const meanPlusNZScoresPerf = getValForZScore(maxZScore, this.meanPerf, stdDevPerf);
    // if it's a rate based goal, max cannot be > 100
    const perfMax = isRateBasedGoal(goalTextValue)
      ? _.min([meanPlusNZScoresPerf, 1, max(this.dataWithSuccessEvents, (d) => d[goalTextValue])])
      : meanPlusNZScoresPerf;

    const perfMin = _.max([getValForZScore(-1 * maxZScore, this.meanPerf, stdDevPerf), 0]);

    return lowerIsBetter ? [perfMax, perfMin] : [perfMin, perfMax];
  }

  getXScale({
    strategyGoalConfigWithTarget,
    scale = scaleSqrt,
    maxZScore = 2,
    clamp = false,
  }: PerfScaleConfig) {
    const { value: goalTextValue, lowerIsBetter } = strategyGoalConfigWithTarget;

    const perfDomain = this.getPerfDomain({
      dataSelection: DataSelection.topNData,
      maxZScore,
      goalTextValue,
      lowerIsBetter,
    });

    const perfScale = scale()
      .domain(perfDomain)
      .range([0, this.dimensions.width - this.svgGutters.right - this.svgGutters.left])
      .clamp(clamp);

    return perfScale;
  }

  getCircleRadiusScale({
    maxCircleRadius,
    minCircleRadius = 0,
    clamp = false,
  }: CircleRadiusConfig) {
    const deliveryScale = this.deliveryScale;
    return scaleSqrt()
      .domain(deliveryScale.domain())
      .range([minCircleRadius, maxCircleRadius])
      .clamp(clamp);
  }

  getColorScale({ colorScaleRange, strategyGoalConfigWithTarget }: ColorScaleConfig) {
    const { value: goalTextValue, lowerIsBetter } = strategyGoalConfigWithTarget;

    const perfDomain = this.getPerfDomain({
      dataSelection: DataSelection.allData,
      maxZScore: 2,
      goalTextValue,
      lowerIsBetter,
    });

    const [perfMin, perfMax] = (strategyGoalConfigWithTarget.lowerIsBetter) ? _.reverse(perfDomain) : perfDomain;

    const colorScaleDomain = getColorScaleDomain(
      this.meanPerf,
      perfMin,
      perfMax,
    );

    return scaleThreshold<number, string>()
      .domain(colorScaleDomain)
      .range(colorScaleRange);
  }
}

export const getRevenueTypeIfExists = (goalType: string, hasRevenueType: boolean) => {
  if (!hasRevenueType) {
    return goalType;
  }
  switch (goalType) {
    case (RevenueType.cpc):
      return GOAL_TYPES.netCpc.value;
    case RevenueType.cpcv:
      return GOAL_TYPES.netCpcv.value;
    case RevenueType.cpm:
      return GOAL_TYPES.netCpm.value;
    default:
      return goalType;
  }
};

export type InsightsGoal = Metric & GoalType & StrategyGoalDB;

export const getOrderedStratGoalsWithRevenueTypes = (
  strategyGoals: StrategyGoalsDB,
  revenueType: string,
  clientEventRevenueValue: number,
): Array<InsightsGoal> => {
  const orderedStrategyGoals = getActiveStrategyGoalsInPriorityOrder(strategyGoals);
  const hasRevenueType = !_.isNil(revenueType) && !_.isNil(clientEventRevenueValue);

  return _.map(orderedStrategyGoals, (sg, i) => {
    const goalType = getRevenueTypeIfExists(sg.type, hasRevenueType);
    const metricConfig = metrics.aggregator[goalType] || metrics.ratePercentage[goalType];
    const goalConfig = GOAL_TYPES[goalType];
    const combinedConfig = { ...goalConfig, ...metricConfig, ...sg, type: goalType };
    const overrideTarget = { target: clientEventRevenueValue };
    return (hasRevenueType && i === 0) ? { ...combinedConfig, ...overrideTarget } : combinedConfig;
  });
};
