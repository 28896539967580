import {
  forceCollide, forceSimulation, forceX, forceY, rgb,
  ScaleLinear, ScaleThreshold, select, SimulationNodeDatum,
} from 'd3';
import { annotation, annotationCalloutElbow } from 'd3-svg-annotation';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import ColorLegend from 'charts/Components/ColorLegend';
import { Spacing } from 'charts/constants';
import { DSP } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import InsightsBox from './Components/InsightsBox';
import List, { ListType } from './Components/List';
import ModelBadgeAndDescription, { DescriptionVersion, ModelName } from './Components/ModelBadgeAndDescription';
import SlideTitle from './Components/SlideTitle';
import SlideWrapper from './Components/SlideWrapper';
import TargetingArrow from './Components/TargetingArrow';
import VizHeader from './Components/VizHeader';
import { SlideIcons, VizId } from './constants';
import { useInsightsContext } from './contexts/InsightsProvider';
import { VizScaleBuilder } from './transforms';
import { IntelligenceOptData, TargetingVizDatum } from './types';
import { extractMetadata, getInsightsByVizId, displayBudgetType, getColorLegendLabel, tooltipsByVizId } from './utils';

const vizId = VizId.intelligentOptimizationTargeting;

const WIDTH = 760;
const HEIGHT = 433;

const IDEAL_ANNOTATION_POSITION = { x: 480, y: 214 };

const MIN_CIRCLE_RADIUS = 2;
const MAX_CIRCLE_RADIUS = 30;

const INT_RADIUS_MULTIPLIER = 1.25;

const LIMIT_ALL_DATA = 500;

type DataCentroid = TargetingVizDatumListEntry & SimulationNodeDatum & { centY: number, centX: number, size: number };
const nodePadding = 1;
const applySimulation = (nodes: Array<DataCentroid>) => {
  const simulation = forceSimulation(nodes)
    .force('x', forceX((d: DataCentroid) => d.centX).strength(1))
    .force('y', forceY(HEIGHT / 2))
    .force('collide', forceCollide().radius((d: DataCentroid) => d.size + nodePadding).strength(1))
    .stop();
  for (let i = 0; i < 600; i += 1) {
    simulation.tick();
  }
};

type TargetingVizDatumListEntry = TargetingVizDatum & { featureVal: string };
const getDataCentroids = (
  data: Array<TargetingVizDatumListEntry>,
  xAxisScale: ScaleLinear<number, number>,
  circleSizeScale: ScaleLinear<number, number>,
  KPI: string,
  budgetType: string,
) => _.map(data, (d) => {
  const baseCircleSize = circleSizeScale(d[budgetType]);
  return {
    ...d,
    centY: HEIGHT / 2,
    centX: xAxisScale(d[KPI]) || 0,
    // account for size of outer blue ring
    size: d.targetedByIntelligence ? (baseCircleSize * INT_RADIUS_MULTIPLIER) : baseCircleSize,
  };
});

const buildViz = (
  dataCentroids: Array<DataCentroid>,
  colorScale: ScaleThreshold<number, string>,
  xAxisScale: ScaleLinear<number, number>,
  KPI: string,
  goalFormat: string,
  goalPerf: number,
  childExtTypeDisplayName: string,
  onVizLoaded: (tooltips: Array<string>) => void,
  tooltips: Array<string>,
) => {
  const svg = select(`#${vizId} svg.viz`)
    .style('overflow', 'visible');

  const xTicks = xAxisScale.ticks(4);
  // x-axis
  svg.append('g')
    .attr('class', 'x axis')
    .selectAll('.tick')
    .data([...xTicks, goalPerf])
    .join('g')
    .attr('class', 'tick')
    .each(function addAxisLineAndLabel(tickVal, idx) {
      const isGoalVal = idx === _.size(xTicks);

      const group = select(this)
        .attr('transform', `translate(${xAxisScale(tickVal) || 0},${0})`)
        .classed('goal', isGoalVal);

      group.append('line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', 0)
        .attr('y2', HEIGHT);

      const formattedTickVal = numeral(tickVal).format(goalFormat);
      group.append('text')
        .text(formattedTickVal)
        .attr('dy', HEIGHT)
        .attr('dx', Spacing.s4);

      if (isGoalVal) {
        group.append('text')
          .attr('class', 'goal-label')
          .text('goal');
      }
    });

  svg.append('g')
    .selectAll('.feature-val-combo')
    .data(dataCentroids)
    .join('g')
    .attr('class', 'feature-val-combo')
    .each(function addCircle(datum) {
      const group = select(this);
      const fillColor = colorScale(datum[KPI]);
      const { r, g, b } = rgb(fillColor);
      const perfStyle = {
        fill: `rgba(${r},${g},${b},0.6)`,
        stroke: fillColor,
      };

      if (datum.targetedByIntelligence) {
        group.append('circle')
          .attr('r', (d: DataCentroid) => d.size)
          .attr('cx', (d: DataCentroid) => d.x)
          .attr('cy', (d: DataCentroid) => d.y)
          .attr('fill', 'none')
          .attr('stroke', COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE)
          .attr('stroke-width', 1.5);
      }
      group.append('circle')
        .attr('r', (d: DataCentroid) => (d.targetedByIntelligence ? (d.size / INT_RADIUS_MULTIPLIER) : d.size))
        .attr('cx', (d: DataCentroid) => d.x)
        .attr('cy', (d: DataCentroid) => d.y)
        .attr('fill', perfStyle.fill)
        .attr('stroke', perfStyle.stroke);
    });

  // find the "was_targeted" datum that will be positioned closest to designated annotation label
  const annotationDatum = _.chain(dataCentroids)
    .filter('targetedByIntelligence')
    // sum of x and y distance from IDEAL_ANNOTATION_POSITION
    .minBy((d) => Math.sqrt(
      ((d.x - IDEAL_ANNOTATION_POSITION.x) ** 2) + ((d.y - IDEAL_ANNOTATION_POSITION.y) ** 2),
    ))
    .value();

  if (annotationDatum) {
    const annotationConfig = {
      note: {
        title: `Targeted on 
        Intelligent ${childExtTypeDisplayName}s`,
        wrapSplitter: /\n/,
      },
      x: annotationDatum.x + (annotationDatum.size / INT_RADIUS_MULTIPLIER),
      y: annotationDatum.y - (annotationDatum.size / INT_RADIUS_MULTIPLIER),
      className: 'annotation',
      dy: -50,
      dx: 50,
      color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
      disable: ['subject'],
    };

    const makeAnnotations = annotation()
      .type(annotationCalloutElbow)
      .annotations([annotationConfig]);

    svg
      .append('g')
      .attr('class', 'annotation-group')
      .call(makeAnnotations as any);
  }
  onVizLoaded(tooltips);
};

const IntelligentOptimizationTargetingViz = (props: IntelligenceOptData & { onVizLoaded: (notes: Array<string>) => void }) => {
  const { metadata, targetingViz: perfAndDeliveryData, onVizLoaded = _.noop } = props;

  const { primaryStrategyGoal: { lowerIsBetter } } = useInsightsContext();
  const usableMetadata = { ...extractMetadata(metadata), lowerIsBetter };
  const { budgetType,
    primaryGoal,
    parentExtTypeDisplayName,
    childExtTypeDisplayName,
    colorScaleRange,
    currency,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    dsp,
    features,
    hasRevenueType,
  } = usableMetadata;
  const { value: KPI,
    format: goalFormat,
    text: metricText,
    target: goalTarget } = primaryGoal;

  const isTtd = dsp === DSP.TTD.id;
  const numTopData = isTtd ? 5 : 10;

  const vizScaleBuilder = new VizScaleBuilder({
    perfAndDeliveryData,
    budgetType,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    excludeDataSansSuccessEvents: false,
    limitDataN: LIMIT_ALL_DATA,
    dimensions: { width: WIDTH, height: HEIGHT },
  });

  const topFeatureData = _.take(_.filter(vizScaleBuilder.topNData, (d) => d.targetedByIntelligence), numTopData);

  const xAxisScale = vizScaleBuilder.getXScale({
    strategyGoalConfigWithTarget: primaryGoal,
    clamp: true,
  });

  const colorScale = vizScaleBuilder.getColorScale({
    colorScaleRange,
    strategyGoalConfigWithTarget: primaryGoal,
  });

  vizScaleBuilder.getDeliveryScale({ rangeMin: 0, maxZScore: Infinity });
  const circleSizeScale = vizScaleBuilder.getCircleRadiusScale({
    maxCircleRadius: MAX_CIRCLE_RADIUS,
    minCircleRadius: MIN_CIRCLE_RADIUS,
    clamp: true,
  });

  const dataCentroids = getDataCentroids(vizScaleBuilder.topNData, xAxisScale, circleSizeScale, KPI, budgetType);
  applySimulation(dataCentroids);
  const tooltips = tooltipsByVizId[vizId](primaryGoal.text, childExtTypeDisplayName, parentExtTypeDisplayName, dsp);

  useMount(() => {
    buildViz(dataCentroids, colorScale, xAxisScale, KPI, goalFormat, goalTarget, childExtTypeDisplayName, onVizLoaded, tooltips);
  });

  const colorStops = _.map(colorScale.domain(), (perfVal) => xAxisScale(perfVal));
  return (
    <div id={vizId} className="slide">
      <SlideTitle
        section="Optimization Insights"
        subSection={`Intelligent ${childExtTypeDisplayName} Targeting`}
        icon={SlideIcons.intelligentOptimization}
      />
      <div className="grid-container">
        <div className="main-visualization">
          <VizHeader
            title={`Which Features Were Targeted on Intelligent ${childExtTypeDisplayName}s`}
            subtitle={`Features Analyzed and Targeted on Intelligent ${childExtTypeDisplayName}s`}
            tooltipContent={tooltips[0]}
          />
          <div className="trends">
            <div>
              <img src="/img/icons/insights/arrow.svg" alt="left-arrow" />
              <span>{metricText}</span>
              <span style={{ color: lowerIsBetter ? _.last(colorScaleRange) : _.first(colorScaleRange) }}>
                {lowerIsBetter ? 'Higher' : 'Lower'}
              </span>
            </div>
            <div>
              <span>{metricText}</span>
              <span style={{ color: lowerIsBetter ? _.first(colorScaleRange) : _.last(colorScaleRange) }}>
                {lowerIsBetter ? 'Lower' : 'Higher'}
              </span>
              <img className="right-arrow" src="/img/icons/insights/arrow.svg" alt="right-arrow" />
            </div>
          </div>
          <svg className="viz" height={HEIGHT} width={WIDTH} />
          <TargetingArrow
            colorStops={primaryGoal.lowerIsBetter ? _.reverse([...colorStops]) : colorStops}
            colors={primaryGoal.lowerIsBetter ? _.reverse([...colorScaleRange]) : colorScaleRange}
            meanX={xAxisScale(vizScaleBuilder.meanPerf)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div className="viz-legend">
            <ModelBadgeAndDescription
              modelName={ModelName.intelligentFlights}
              version={DescriptionVersion.condensed}
              childExtTypeDisplayName={childExtTypeDisplayName}
            />
            <ColorLegend
              id={`${vizId}_legend`}
              type="discrete"
              lowerIsBetter={primaryGoal.lowerIsBetter}
              label={getColorLegendLabel(primaryGoal, currency)}
              width={264}
              showMeanValue
              meanValue={vizScaleBuilder.meanPerf}
              showGoalValue
              goalValue={goalTarget}
              data={{
                colorRange: colorScaleRange,
                domain: colorScale.domain(),
                format: (d) => numeral(d).format(goalFormat),
              }}
              hasRevenueType={hasRevenueType}
            />
            <svg className="legend">
              <g className="targeted">
                <circle r="12" />
                <text>Targeted on</text>
                <text dy="12">Intelligent {childExtTypeDisplayName}s</text>
              </g>
              <g className="delivery">
                <circle r="6.2" cy="7.2" />
                <circle r="10" cy="4" />
                <circle r="14" />
                <text>{displayBudgetType(budgetType)}</text>
              </g>
            </svg>
          </div>
          <List
            data={topFeatureData}
            budgetType={budgetType}
            goalMetricConfig={primaryGoal}
            features={features}
            type={ListType.featureCombo}
            colorScale={colorScale}
            primaryGoalSuccessEvent={primaryGoalSuccessEvent}
            currency={currency}
            withWrapping={isTtd}
            overrideTitle={`Top ${_.size(topFeatureData)} Feature Values Targeted`}
          />
        </div>
      </div>
      <InsightsBox insights={getInsightsByVizId(vizId, usableMetadata)} />
    </div>
  );
};

export default SlideWrapper(IntelligentOptimizationTargetingViz);
