import _ from 'lodash';
import React, { useState } from 'react';
import { StrategyGoalAnalyticsDatum } from 'containers/StrategyAnalytics/types';
import { getSingleRevTypeAndValue, isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { performanceAndDeliveryNotes } from './constants';
import CrossPlatformDeliveryChart from './CrossPlatformDeliveryChart';
import CrossPlatformGoalInfo from './CrossPlatformGoalInfo';
import PerformanceChart from './PerformanceChart';
import SinglePlatformDeliveryChart from './SinglePlatformDeliveryChart';
import SinglePlatformGoalInfo from './SinglePlatformGoalInfo';
import ErrorSegment from '../Components/ErrorSegment';
import InsightsBox from '../Components/InsightsBox';
import SlideTitle from '../Components/SlideTitle';
import SlideWrapper from '../Components/SlideWrapper';
import VizHeader from '../Components/VizHeader';
import { SlideIcons, VizId } from '../constants';
import { useInsightsContext } from '../contexts/InsightsProvider';
import { BaseInsightsSlideProps } from '../types';
import { getInsightsByVizId, getDeliveryTextUpper, includeEstimatedKpiInCumulativeAnalytics, getDeliveryText } from '../utils';

const vizId = VizId.performanceAndDelivery;
const goalInfoTooltip = 'Compares Strategy performance to goals. Goals displayed are based on the goals selected in Strategy configuration.';

const PerformanceAndDelivery = ({ onVizLoaded, onVizError }: BaseInsightsSlideProps) => {
  const [dataError, setDataError] = useState<boolean>(false);
  const { stratData, budgetOptData, primaryStrategyGoal, budgetType, currency, strategy, viewabilityGoal } = useInsightsContext();

  useMount(() => {
    if (!stratData || !budgetOptData || !primaryStrategyGoal || !budgetType || !currency || !strategy) {
      setDataError(true);
      onVizError();
    } else {
      onVizLoaded(performanceAndDeliveryNotes);
    }
  });

  if (dataError) {
    return <ErrorSegment />;
  }

  const isCrossPlatform = isCrossPlatformStrategyType(strategy.strategyType.id);
  const hasRevenueType = !_.isEmpty(strategy.revTypeConfig);
  const revenueType = _.size(strategy.revTypeConfig) === 1 ? getSingleRevTypeAndValue(strategy.revTypeConfig).revenueType : null;
  const { cumData, dailyData } = stratData;
  const mostRecentCumData = _.last(cumData) as StrategyGoalAnalyticsDatum;
  const cumPacingData = budgetOptData.pacingData;
  const updatedCumulativeData = includeEstimatedKpiInCumulativeAnalytics(cumData, cumPacingData);
  const goalType = _.get(primaryStrategyGoal, 'shortText', primaryStrategyGoal.displayName);
  const deliveryChartTitle = getDeliveryTextUpper(_.toUpper)({ budgetType, currencyCode: currency });

  return (
    <div id={vizId} className="slide">
      <SlideTitle section="Campaign Overview" icon={SlideIcons.performanceAndDeliverySummary} />
      <div className="flex-container">
        <div className="visualization-container">
          <div className="performance-visualization">
            <VizHeader
              title="Campaign Performance"
              subtitle={`${_.capitalize(primaryStrategyGoal.displayName)} and estimated uplift by day`}
              tooltipContent={`Compares Strategy ${_.lowerCase(goalType)} against estimated non Copilot ${_.lowerCase(goalType)} and goal value across live flight dates.`}
            />
            <PerformanceChart
              cumulativePerfData={updatedCumulativeData}
              dailyPerfData={dailyData}
              primaryStrategyGoal={primaryStrategyGoal}
              currency={currency}
              revenueType={revenueType}
            />
          </div>
          <div className="delivery-visualization">
            <VizHeader
              title="Campaign Delivery"
              subtitle={`Total ${getDeliveryText({ budgetType })} by day`}
              tooltipContent="Displays Strategy delivery across live flight dates. Delivery is measured according to buying platform and Strategy settings."
            />
            {isCrossPlatform ? (
              <CrossPlatformDeliveryChart
                title={deliveryChartTitle}
              />
            ) : (
              <SinglePlatformDeliveryChart
                dailyPerfData={dailyData}
                budgetType={budgetType}
                title={deliveryChartTitle}
              />
            )}
          </div>
        </div>
        {isCrossPlatform ? (
          <CrossPlatformGoalInfo
            tooltip={goalInfoTooltip}
          />
        ) : (
          <SinglePlatformGoalInfo
            tooltip={goalInfoTooltip}
            hasRevenueType={hasRevenueType}
            mostRecentCumData={mostRecentCumData}
          />
        )}
      </div>
      <InsightsBox insights={getInsightsByVizId(
        vizId,
        {
          primaryStrategyGoal,
          budgetOptData,
          mostRecentCumData,
          currency,
          ...(viewabilityGoal && { viewabilityGoal }),
        },
      )}
      />
    </div>
  );
};

export default React.memo(SlideWrapper(PerformanceAndDelivery));
