import { select, axisBottom, ScaleLinear, ScaleThreshold, format } from 'd3';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import ColorLegend, { Orientation } from 'charts/Components/ColorLegend';
import { Spacing } from 'charts/constants';
import { MetricsFormattingConstants } from 'constantsBase';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import InsightsBox from './Components/InsightsBox';
import List, { ListType } from './Components/List';
import SlideTitle from './Components/SlideTitle';
import SlideWrapper from './Components/SlideWrapper';
import VizHeader from './Components/VizHeader';
import { SlideIcons, VizId } from './constants';
import { useInsightsContext } from './contexts/InsightsProvider';
import {
  extractMetadata, getInsightsByVizId, isCostBasedGoal, getColorLegendLabel, getTextLinesWithEllipsis,
  tooltipsByVizId, getDeliveryTextUpper, cleanSingleAppSiteName,
} from './utils';
import { FeatureInsightsData, FeatureInsightsDatum, BudgetType } from './types';
import { InsightsGoal, VizScaleBuilder } from './transforms';

const VIZ_ID = VizId.contextualFeatureInsights;
const DIMENSIONS = { width: 872, height: 542 };
const SVG_GUTTERS = { left: 84.75, right: Spacing.s48, top: 0, bottom: 62 };
const MAX_BUBBLE_RADIUS = 50;
const LIMIT_ALL_DATA = 25;
const TOP_N = 10;
const INT_RADIUS_MULTIPLIER = 1.25;
const FEATURE = 'site';

const currencyDisplay = (goalTextValue: string, currency: string, parens: boolean) => {
  if (!isCostBasedGoal(goalTextValue)) {
    return '';
  }
  return parens ? `(${currency})` : currency;
};

const buildViz = (
  data: Array<FeatureInsightsDatum>,
  primaryGoal: InsightsGoal,
  budgetType: BudgetType,
  currencyCode: string,
  perfScale: ScaleLinear<number, number>,
  deliveryScale: ScaleLinear<number, number>,
  radiusScale: ScaleLinear<number, number>,
  colorScale: ScaleThreshold<number, string>,
  meanPerf: number,
  featureLabelsToShow: { [feature: string]: boolean },
  onVizLoaded: (tooltips: Array<string>) => void,
  tooltips: Array<string>,
  hasRevenueType: boolean,
  revenueType: string,
) => {
  const { value: goalTextValue, target: goalTargetValue, format: goalFormat, text: goalDisplayText } = primaryGoal;
  const svg = select(`#${VIZ_ID} svg.viz`);
  // Axes and axis labels
  const xAxis = (g) => g
    .attr('transform', `translate(${SVG_GUTTERS.left},${DIMENSIONS.height - SVG_GUTTERS.bottom})`)
    .call(axisBottom(perfScale).ticks(7).tickFormat((d) => numeral(d).format(goalFormat)));

  svg.append('g').attr('class', 'axis x').call(xAxis);

  // build the y-axis, which is made up of an axis line plus for each ticket, a sized semi-circle
  // we use 'foreignObject' to put an svg inside an svg
  svg.append('foreignObject').attr('width', SVG_GUTTERS.left).attr('height', DIMENSIONS.height)
    .append('svg')
    .attr('width', MAX_BUBBLE_RADIUS)
    .attr('height', DIMENSIONS.height)
    .attr('transform', `translate(${SVG_GUTTERS.left},0)`)
    .style('overflow', 'visible')
    .attr('class', 'axis y')
    .call((g) => {
      g.selectAll('.tick')
        .data(deliveryScale.ticks(4))
        .join((enter) => {
          const tick = enter.append('g').attr('class', 'tick')
            .style('transform', (d) => `translateY(${deliveryScale(d)}px)`);

          tick.append('circle')
            .attr('r', (d) => radiusScale(d));

          tick.append('text')
            .text(format('.2s')) // 2 sig fis
            .attr('dx', -Spacing.s4);
          return tick;
        });
      g.append('line')
        .attr('class', 'domain')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', deliveryScale.range()[0])
        .attr('y2', deliveryScale.range()[1] - MAX_BUBBLE_RADIUS);
    });

  const container = svg.append('g')
    .attr('transform', `translate(${SVG_GUTTERS.left},${SVG_GUTTERS.top})`);

  container.append('g')
    .attr('class', 'axis-label y')
    .append('text')
    .text(getDeliveryTextUpper(_.toUpper)({ budgetType, currencyCode }))
    .attr('dy', Spacing.s8)
    .attr('dx', -Spacing.s4);

  svg.append('g')
    .attr('class', 'axis-label x')
    .attr('transform', `translate(${DIMENSIONS.width - SVG_GUTTERS.right}, ${DIMENSIONS.height - SVG_GUTTERS.bottom - Spacing.s12})`)
    .append('text')
    .text(`${_.upperCase(goalTextValue)} ${currencyDisplay(goalTextValue, currencyCode, true)}`);

  const meanPerfX = perfScale(meanPerf);
  const goalPerfX = perfScale(goalTargetValue);
  const meanIsFurtherRightThanGoal = meanPerfX > goalPerfX;

  if (meanPerfX) {
    // mean line and label
    container.append('g')
      .attr('class', 'mean')
      .attr('transform', `translate(${meanPerfX}, 0)`)
      .call((g) => {
        g.append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', 0)
          .attr('y2', deliveryScale.range()[0] + Spacing.s16);

        g.append('g').attr('class', 'label')
          .style('text-anchor', meanIsFurtherRightThanGoal ? 'start' : 'end')
          .attr('transform', `translate(0,${deliveryScale.range()[0] + Spacing.s16 + Spacing.s12})`)
          .call((label) => {
            label.append('text').text(`mean ${goalDisplayText}`);

            label.append('text')
              .text(`${numeral(meanPerf).format(goalFormat)} ${currencyDisplay(goalTextValue, currencyCode, false)}`)
              .attr('dy', Spacing.s16);
          });
      });
  }

  if (goalPerfX) {
    // goal line and label
    container.append('g').attr('class', 'goal')
      .style('text-anchor', meanIsFurtherRightThanGoal ? 'end' : 'start')
      .attr('transform', `translate(${goalPerfX}, 0)`)
      .call((g) => {
        g.append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', 0)
          .attr('y2', deliveryScale.range()[0] + Spacing.s16);

        g.append('g').attr('class', 'label')
          .attr('transform', `translate(0,${deliveryScale.range()[0] + Spacing.s16 + Spacing.s12})`)
          .call((label) => {
            label.append('text')
              .text(hasRevenueType ? `Revenue ${_.toUpper(revenueType)}` : `goal ${goalDisplayText}`);

            label.append('text')
              .text(`${numeral(goalTargetValue).format(goalFormat)} ${currencyDisplay(goalTextValue, currencyCode, false)}`)
              .attr('dy', Spacing.s16);
          });
      });

    // apply a shaded green box to indicate where delivery is good
    container.append('rect')
      .attr('id', 'goal-box')
      .attr('height', DIMENSIONS.height - SVG_GUTTERS.top - SVG_GUTTERS.bottom)
      .attr('width', perfScale.range()[1] - goalPerfX)
      .style('transform', `translateX(${goalPerfX}px)`);
  }

  const [perfMin, perfMax] = perfScale.domain();

  const nonOutliers = _.filter(data, (d) => _.inRange(d[goalTextValue], perfMin, perfMax));
  const bubbleContainers = container.selectAll('.bubble')
    .data(nonOutliers)
    .join('g')
    .attr('class', 'bubble')
    .attr('transform', (d) => `translate(${perfScale(d[goalTextValue])},${deliveryScale(d[budgetType])})`);

  bubbleContainers
    .filter((d) => d.targetedByIntelligence)
    .append('circle')
    .attr('class', 'targeted')
    .attr('r', (d) => radiusScale(d[budgetType]) * INT_RADIUS_MULTIPLIER);

  bubbleContainers
    .append('circle')
    .attr('r', (d) => radiusScale(d[budgetType]))
    .attr('stroke', (d) => colorScale(d[goalTextValue]))
    .attr('fill', (d) => colorScale(d[goalTextValue]));

  bubbleContainers
    .filter((d) => {
      const featureVal = d[FEATURE];
      return (featureLabelsToShow[featureVal]);
    })
    .append('g')
    .each(function addMultiLineText(datum) {
      const group = select(this);

      group.selectAll('.chunk')
        .data(getTextLinesWithEllipsis(cleanSingleAppSiteName(datum[FEATURE]), 20, 2))
        .join('text')
        .text(_.identity)
        .attr('dy', (_d, i) => i * 11);
    });
  onVizLoaded(tooltips);
};

const ContextualFeatureInsights = (props: FeatureInsightsData & {
  onVizLoaded: (notes: Array<string>) => void
}) => {
  const {
    metadata,
    legend,
    performance: perfAndDeliveryData,
    onVizLoaded = _.noop,
  } = props;
  const { primaryStrategyGoal: { lowerIsBetter } } = useInsightsContext();
  const usableMetadata = { ...extractMetadata(metadata), lowerIsBetter };
  const {
    budgetType,
    primaryGoal,
    colorScaleRange,
    childExtTypeDisplayName,
    currency,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    features,
    dsp,
    hasRevenueType = false,
    revenueType,
  } = usableMetadata;

  const vizScaleBuilder = new VizScaleBuilder({
    perfAndDeliveryData,
    budgetType,
    primaryGoalSuccessEvent,
    primaryGoalOverallValue,
    limitDataN: LIMIT_ALL_DATA,
    dimensions: DIMENSIONS,
    svgGutters: SVG_GUTTERS,
    excludeDataSansSuccessEvents: true,
  });
  const deliveryScale = vizScaleBuilder.getDeliveryScale({
    rangeMin: MAX_BUBBLE_RADIUS * INT_RADIUS_MULTIPLIER,
    maxZScore: Infinity,
    clamp: true,
  });

  const perfScale = vizScaleBuilder.getXScale({ strategyGoalConfigWithTarget: primaryGoal });
  const circleRadiusScale = vizScaleBuilder.getCircleRadiusScale({
    maxCircleRadius: MAX_BUBBLE_RADIUS,
    clamp: true,
  });

  const colorScale = vizScaleBuilder.getColorScale({
    colorScaleRange,
    strategyGoalConfigWithTarget: primaryGoal,
  });

  // only show feature labels for top ten that are also in legend
  const featureLabelsToShow = _.fromPairs(_.map(legend, (d) => ([d[FEATURE], true])));

  const tooltips = tooltipsByVizId[VIZ_ID](primaryGoal.text, childExtTypeDisplayName, budgetType, currency, dsp);

  useMount(() => {
    buildViz(
      vizScaleBuilder.topNData,
      primaryGoal,
      budgetType,
      currency,
      perfScale,
      deliveryScale,
      circleRadiusScale,
      colorScale,
      vizScaleBuilder.meanPerf,
      featureLabelsToShow,
      onVizLoaded,
      tooltips,
      hasRevenueType,
      revenueType,
    );
  });
  const deliveryText = getDeliveryTextUpper()({ budgetType });
  return (
    <div id={VIZ_ID} className="slide">
      <SlideTitle section="Feature Insights" subSection="Contextual" icon={SlideIcons.contextualInsights} />
      <div className="grid-container">
        <div className="main-visualization">
          <VizHeader
            title="How Do Sites & Applications Compare"
            subtitle={`${primaryGoal.insightsLabel || primaryGoal.text}
            & ${deliveryText} by Site and Application by ${deliveryText}`}
            tooltipContent={tooltips[0]}
          />
          <p className="no-success-events">{numeral(vizScaleBuilder.countNoSuccessEvents).format(MetricsFormattingConstants.NO_DECIMALS)} {FEATURE}s with zero {primaryGoalSuccessEvent} not shown.</p>
          <svg className="viz" width={DIMENSIONS.width} height={DIMENSIONS.height} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div className="viz-legend">
            <ColorLegend
              id={`${VIZ_ID}_color-legend`}
              data={{
                colorRange: colorScaleRange,
                domain: colorScale.domain(),
                format: (d) => numeral(d).format(primaryGoal.format),
              }}
              width={264}
              label={getColorLegendLabel(primaryGoal, currency)}
              type="discrete"
              lowerIsBetter={primaryGoal.lowerIsBetter}
              showMeanValue
              meanValue={vizScaleBuilder.meanPerf}
              showGoalValue
              goalValue={primaryGoal.target}
              orientation={Orientation.bottom}
              hasRevenueType={hasRevenueType}
            />
            <svg className="targeted">
              <g className="intelligent child">
                <circle r={_.toString(Spacing.s12)} />
                <text>Targeted by Intelligent {childExtTypeDisplayName}s</text>
              </g>
            </svg>
          </div>
          <List
            data={_.take(legend, TOP_N)}
            budgetType={budgetType}
            goalMetricConfig={primaryGoal}
            features={features}
            type={ListType.featureCombo}
            colorScale={colorScale}
            currency={currency}
            primaryGoalSuccessEvent={primaryGoalSuccessEvent}
          />
        </div>
      </div>
      <InsightsBox insights={getInsightsByVizId(VIZ_ID, usableMetadata)} />
    </div>
  );
};

export default SlideWrapper(ContextualFeatureInsights);
