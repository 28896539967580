/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ExportButton from 'charts/Components/ExportButton';
import Loader from 'charts/Components/Loader';
import { MARGIN_GOAL_NOTE } from 'charts/constants';
import { ReportType } from 'charts/utils';
import { DSP, STRATEGY_TYPE } from 'constantsBase';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { GlobalState } from 'reducers';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { Flight } from 'utils/types';
import BudgetAllocationAndAvailableScale from './BudgetAllocationAndAvailableScale/BudgetAllocationAndAvailableScale';
import BudgetOptimizationOverview from './BudgetOptimizationOverview/BudgetOptimizationOverview';
import { VizId } from './constants';
import { useInsightsContext } from './contexts/InsightsProvider';
import ContextualFeatureInsights from './ContextualFeatureInsights';
import DeviceInsights from './DeviceInsights/DeviceInsights';
import GeoInsights, { GeoPlaceholder } from './GeoInsights';
import IntelligentOptimizationSummary from './IntelligentOptimizationSummary/IntelligentOptimizationSummary';
import IntelligentOptimizationTargeting from './IntelligentOptimizationTargeting';
import OptimizationSummary from './OptimizationSummary/OptimizationSummary';
import PerformanceAndDelivery from './PerformanceAndDelivery/PerformanceAndDelivery';
import TableOfContents from './TableOfContents';
import { InsightsState, SlideErrors, SpeakerNotes } from './types';
import { BooleanFetchState, getSetSlideError, hasIntOptOverviewData, stratHasRevenueType, useHasGeoDataFetcher } from './utils';
import '../styles/insights.sass';

type Props = {
  intelligentOptDataFetchState: InsightsState
  contextualFeatureInsightsFetchState: InsightsState
  deviceFeatureInsightsFetchState: InsightsState
  geoFeatureInsightsFetchState: InsightsState
  isYouTube: boolean
};

const InsightsViz = (props: Props) => {
  const {
    intelligentOptDataFetchState,
    contextualFeatureInsightsFetchState,
    deviceFeatureInsightsFetchState,
    geoFeatureInsightsFetchState,
    isYouTube,
  } = props;

  const { strategy, stratData, flights } = useSelector<GlobalState>((state) => state.strategyAnalytics.metadata) as any;
  const {
    id, name, advertiser, brand, config, strategyType: { id: stratTypeId, dsp }, createdAt,
  } = strategy;
  const flight = _.first(flights) as Flight;
  const { kind } = useInsightsContext();

  const isCrossPlatform = isCrossPlatformStrategyType(stratTypeId);
  const isAmzn = stratTypeId === STRATEGY_TYPE.amznBudgetOptimization.id;
  const isWalmart = _.some(flights, { dsp: DSP.WALMART.id }) || dsp === DSP.WALMART.id;
  const notCrossPlatformOrAmznOrWalmart = !isCrossPlatform && !isAmzn && !isWalmart;
  const shouldRenderIntTargetingViz = hasIntOptOverviewData(intelligentOptDataFetchState) && notCrossPlatformOrAmznOrWalmart && !isYouTube
    && !(_.get(strategy, 'config.bidOptimization') && _.isEqual(stratTypeId, STRATEGY_TYPE.ttdBudgetOptimization.id));
  const intelligentChildObjects = _.get(config, 'intelligentChildObjects');
  const hasGeoData = (useHasGeoDataFetcher(geoFeatureInsightsFetchState) as BooleanFetchState).data;
  const brandOrAdvertiserName = brand ? brand.name : advertiser.name;
  const hasCustomRevenueType = stratHasRevenueType(strategy);
  const strategyCreatedAtTime = moment(createdAt);
  const lastDayOfData = _.get(_.last(stratData.cumData), 'date', moment());

  const [tableOfContentsLoaded, setTableOfContentsLoaded] = useState<boolean>(false);
  const [performanceLoaded, setPerformanceLoaded] = useState<boolean>(false);
  const [optSummaryLoaded, setOptSummaryLoaded] = useState<boolean>(false);
  const [boOverviewLoaded, setBoOverviewLoaded] = useState<boolean>(false);
  const [budgetAllocAvailScaleLoaded, setBudgetAllocAvailScaleLoaded] = useState<boolean>(false);
  const [intOptOverviewLoaded, setIntOptOverviewLoaded] = useState<boolean>(false);
  const [intOptTargetingVizLoaded, setIntOptTargetingVizLoaded] = useState<boolean>(false);
  const [contextualFeatureInsightsLoaded, setContextualFeatureInsightsLoaded] = useState<boolean>(false);
  const [deviceFeatureInsightsLoaded, setDeviceFeatureInsightsLoaded] = useState<boolean>(false);
  const [geoFeatureInsightsLoaded, setGeoFeatureInsightsLoaded] = useState<boolean>(false);
  const [speakerNotes, setSpeakerNotes] = useState<SpeakerNotes>({});

  const [slideErrors, setSlideErrors] = useState<SlideErrors>(_.fromPairs(_.map(VizId, (v) => [v, false])) as SlideErrors);

  const chartsConfig = useMemo(() => ({
    [VizId.tableOfContents]: {
      shouldLoad: true,
      hasLoaded: tableOfContentsLoaded,
      setVizError: getSetSlideError(VizId.tableOfContents, setSlideErrors),
    },
    [VizId.performanceAndDelivery]: {
      shouldLoad: true,
      hasLoaded: performanceLoaded,
      setVizError: getSetSlideError(VizId.performanceAndDelivery, setSlideErrors),
    },
    [VizId.optimizationSummary]: {
      shouldLoad: true,
      hasLoaded: optSummaryLoaded,
      setVizError: getSetSlideError(VizId.optimizationSummary, setSlideErrors),
    },
    [VizId.budgetOptimizationOverview]: {
      shouldLoad: true,
      hasLoaded: boOverviewLoaded,
      setVizError: getSetSlideError(VizId.budgetOptimizationOverview, setSlideErrors),
    },
    [VizId.budgetAllocationAndAvailableScale]: {
      shouldLoad: true,
      hasLoaded: budgetAllocAvailScaleLoaded,
      setVizError: getSetSlideError(VizId.budgetAllocationAndAvailableScale, setSlideErrors),
    },
    [VizId.intelligentOptimizationSummary]: {
      shouldLoad: intelligentChildObjects,
      hasLoaded: intOptOverviewLoaded,
      setVizError: getSetSlideError(VizId.intelligentOptimizationSummary, setSlideErrors),
    },
    [VizId.intelligentOptimizationTargeting]: {
      shouldLoad: shouldRenderIntTargetingViz && !isWalmart,
      hasLoaded: intOptTargetingVizLoaded,
      setVizError: getSetSlideError(VizId.intelligentOptimizationTargeting, setSlideErrors),
    },
    [VizId.contextualFeatureInsights]: {
      shouldLoad: !isYouTube && notCrossPlatformOrAmznOrWalmart,
      hasLoaded: contextualFeatureInsightsLoaded,
      setVizError: getSetSlideError(VizId.contextualFeatureInsights, setSlideErrors),
    },
    [VizId.deviceFeatureInsights]: {
      shouldLoad: notCrossPlatformOrAmznOrWalmart,
      hasLoaded: deviceFeatureInsightsLoaded,
      setVizError: getSetSlideError(VizId.deviceFeatureInsights, setSlideErrors),
    },
    [VizId.geoInsights]: {
      shouldLoad: notCrossPlatformOrAmznOrWalmart && hasGeoData,
      hasLoaded: geoFeatureInsightsLoaded,
      setVizError: getSetSlideError(VizId.geoInsights, setSlideErrors),
    },
  }), [
    tableOfContentsLoaded, performanceLoaded, optSummaryLoaded, boOverviewLoaded, budgetAllocAvailScaleLoaded, intelligentChildObjects, intOptOverviewLoaded, shouldRenderIntTargetingViz,
    intOptTargetingVizLoaded, isYouTube, notCrossPlatformOrAmznOrWalmart, contextualFeatureInsightsLoaded, deviceFeatureInsightsLoaded, hasGeoData, geoFeatureInsightsLoaded,
  ]);

  if (!_.isEqual(kind, PossibleStates.hasData)) {
    return _.isEqual(kind, PossibleStates.initial) ? <Loader /> : <div id="charts-empty-render">There was an error retreiving Insights data for this strategy. Please check back later.</div>;
  }

  const numChartsShouldLoad = _.size(_.filter(_.values(chartsConfig), 'shouldLoad'));
  const numChartsHasLoaded = _.size(_.filter(_.values(chartsConfig), 'hasLoaded'));
  const numMissingSlides = _.size(_.pickBy(slideErrors));
  const missingSlides = numMissingSlides > 0;
  const chartsLoading = numChartsShouldLoad > (numChartsHasLoaded + numMissingSlides);
  const chartIdsToExport = _.keys(_.pickBy(chartsConfig, 'hasLoaded'));

  const onVizLoaded = (setSlideLoaded: (x: boolean) => void, vizId: VizId) => (notes: Array<string>) => {
    setSlideLoaded(true);
    setSpeakerNotes((prev) => ({ ...prev, [vizId]: notes }));
  };

  return (
    <div id="insights-viz">
      <ExportButton
        containerType="header"
        stratId={id}
        stratName={name}
        brandOrAdvertiserName={brandOrAdvertiserName}
        flightName={isCrossPlatform ? null : flight.name}
        strategyCreatedAtTime={strategyCreatedAtTime}
        lastDayOfData={lastDayOfData}
        loading={chartsLoading}
        chartIdsToExport={chartIdsToExport}
        missingSlides={missingSlides}
        speakerNotes={speakerNotes}
        insertWatermark={hasCustomRevenueType}
        reportType={ReportType.INSIGHTS}
      />
      {hasCustomRevenueType && (
        <p className="revenue-type-warning"><strong>Note: </strong>{MARGIN_GOAL_NOTE}</p>
      )}
      <TableOfContents
        onVizLoaded={(notes: Array<string>) => onVizLoaded(setTableOfContentsLoaded, VizId.tableOfContents)(notes)}
        onVizError={chartsConfig[VizId.tableOfContents].setVizError}
        isYouTube={isYouTube}
        hasGeoData={hasGeoData}
        shouldRenderIntTargetingViz={shouldRenderIntTargetingViz}
      />
      <PerformanceAndDelivery
        onVizLoaded={(notes: Array<string>) => onVizLoaded(setPerformanceLoaded, VizId.performanceAndDelivery)(notes)}
        onVizError={chartsConfig[VizId.performanceAndDelivery].setVizError}
      />
      <OptimizationSummary
        onVizLoaded={(notes: Array<string>) => onVizLoaded(setOptSummaryLoaded, VizId.optimizationSummary)(notes)}
        onVizError={chartsConfig[VizId.optimizationSummary].setVizError}
      />
      <BudgetOptimizationOverview
        onVizLoaded={(notes: Array<string>) => onVizLoaded(setBoOverviewLoaded, VizId.budgetOptimizationOverview)(notes)}
        onVizError={chartsConfig[VizId.budgetOptimizationOverview].setVizError}
      />
      <BudgetAllocationAndAvailableScale
        onVizLoaded={(notes: Array<string>) => onVizLoaded(setBudgetAllocAvailScaleLoaded, VizId.budgetAllocationAndAvailableScale)(notes)}
        onVizError={chartsConfig[VizId.budgetAllocationAndAvailableScale].setVizError}
      />
      {chartsConfig[VizId.intelligentOptimizationSummary].shouldLoad && (
        <IntelligentOptimizationSummary
          onVizLoaded={(notes: Array<string>) => onVizLoaded(setIntOptOverviewLoaded, VizId.intelligentOptimizationSummary)(notes)}
          onVizError={chartsConfig[VizId.intelligentOptimizationSummary].setVizError}
        />
      )}
      {chartsConfig[VizId.intelligentOptimizationTargeting].shouldLoad && (
        <IntelligentOptimizationTargeting
          dataFetchStates={[intelligentOptDataFetchState]}
          onVizLoaded={(notes: Array<string>) => onVizLoaded(setIntOptTargetingVizLoaded, VizId.intelligentOptimizationTargeting)(notes)}
          onVizError={chartsConfig[VizId.intelligentOptimizationTargeting].setVizError}
        />
      )}
      {chartsConfig[VizId.contextualFeatureInsights].shouldLoad && (
        <ContextualFeatureInsights
          dataFetchStates={[contextualFeatureInsightsFetchState]}
          onVizLoaded={(notes: Array<string>) => onVizLoaded(setContextualFeatureInsightsLoaded, VizId.contextualFeatureInsights)(notes)}
          onVizError={chartsConfig[VizId.contextualFeatureInsights].setVizError}
        />
      )}
      {chartsConfig[VizId.deviceFeatureInsights].shouldLoad && (
        <DeviceInsights
          dataFetchStates={[deviceFeatureInsightsFetchState]}
          onVizLoaded={(notes: Array<string>) => onVizLoaded(setDeviceFeatureInsightsLoaded, VizId.deviceFeatureInsights)(notes)}
          onVizError={chartsConfig[VizId.deviceFeatureInsights].setVizError}
        />
      )}
      {chartsConfig[VizId.geoInsights].shouldLoad && (
        <GeoInsights
          dataFetchStates={[geoFeatureInsightsFetchState]}
          onVizLoaded={(notes: Array<string>) => onVizLoaded(setGeoFeatureInsightsLoaded, VizId.geoInsights)(notes)}
          onVizError={chartsConfig[VizId.geoInsights].setVizError}
          placeholderConfig={{
            content: <GeoPlaceholder setGeoFeatureInsightsLoaded={setGeoFeatureInsightsLoaded} />,
            messageStr: 'No matching country',
          }}
        />
      )}
      <ExportButton
        containerType="footer"
        stratId={id}
        stratName={name}
        brandOrAdvertiserName={brandOrAdvertiserName}
        flightName={isCrossPlatform ? null : flight.name}
        strategyCreatedAtTime={strategyCreatedAtTime}
        lastDayOfData={lastDayOfData}
        loading={chartsLoading}
        chartIdsToExport={chartIdsToExport}
        missingSlides={missingSlides}
        speakerNotes={speakerNotes}
        insertWatermark={hasCustomRevenueType}
        reportType={ReportType.INSIGHTS}
      />
    </div>
  );
};

export default InsightsViz;
