import _ from 'lodash';
import { TopDeliveringChild } from 'charts/BudgetOptimizationViz/types';
import { StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import { TopDeliveringChildWithGoal } from './constants';

export const getOrderedTopDelChildrenWithGoal = (
  topDeliveringChildren: Array<TopDeliveringChild>,
  lineItemData: { [childExtId: string]: StrategyGoalAnalyticsData },
  primaryGoalKey: string,
): Array<TopDeliveringChildWithGoal> => _.orderBy(
  _.reduce(topDeliveringChildren, (res, topChild) => ([
    ...res,
    {
      ...topChild,
      goalPerf: _.get(lineItemData[topChild.childExtId], primaryGoalKey, 0),
      dsp: _.get(lineItemData[topChild.childExtId], 'dsp'),
      isClone: _.get(lineItemData[topChild.childExtId], 'isClone'),
    },
  ]), []),
  'averageDailyDelivery',
  'desc',
);
