import React from 'react';
import withRenderData, { OutputProps } from './ModelBadgeWrapper';

const ModelBadgeMedium = ({ startColor, stopColor, linearGradientId }: OutputProps) => (
  <svg width="31px" height="35px" viewBox="0 0 31 35">
    <title>Polygon</title>
    <defs>
      <linearGradient x1="8.93554688%" y1="27.6504723%" x2="91.0644531%" y2="73.7134853%" id={linearGradientId}>
        <stop stopColor={startColor} offset="0%" />
        <stop stopColor={stopColor as string} offset="100%" />
      </linearGradient>
      <filter x="-12.1%" y="-7.8%" width="124.1%" height="121.9%" filterUnits="objectBoundingBox" id="model-badge-large-shadow">
        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          stroke={startColor}
          strokeWidth="3.36"
          d="M14.5,1.91873323 L27.32,8.99183668 L27.32,23.0081633 L14.5,30.0812668 L1.68,23.0081633 L1.68,8.99183668 L14.5,1.91873323 Z"
          strokeLinejoin="miter"
          fill={`url(#${linearGradientId})`}
          fillRule="evenodd"
        />
        {/* The second, duplicate path is the shadow layer */}
        <path
          stroke={startColor}
          strokeWidth="3.36"
          d="M14.5,1.91873323 L27.32,8.99183668 L27.32,23.0081633 L14.5,30.0812668 L1.68,23.0081633 L1.68,8.99183668 L14.5,1.91873323 Z"
          strokeLinejoin="miter"
          fill={`url(#${linearGradientId})`}
          fillRule="evenodd"
          filter="url(#model-badge-large-shadow)"
        />
      </g>
    </g>
  </svg>
);

export default withRenderData(ModelBadgeMedium);
