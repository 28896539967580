import { JSXElementConstructor } from 'react';
import { BudgetOptDataPacingDatum } from 'charts/BudgetOptimizationViz/types';
import { GeoJsonDatum } from 'charts/types';
import { DSP } from 'constantsBase';
import { StrategyGoalAnalyticsData, StrategyGoalAnalyticsGoalType } from 'containers/StrategyAnalytics/types';
import { State } from 'utils/hooks/useFetcher';
import { StrategyGoalDB, StrategyGoalsDB } from 'utils/types';
import { Status, VizId } from './constants';

export type SlideErrors = { [key in VizId]: boolean };

export type SpeakerNotes = { [key in VizId]: Array<string> } | {};

export type CumulativeDailyDatum = StrategyGoalAnalyticsData & Pick<BudgetOptDataPacingDatum, 'estimatedKpiCumulative'>;

export type MetaDatum = {
  externalId: string
  name: string
};

export type OrigCloneMapping = {
  [orig: string]: string
};

export enum BudgetType {
  impressions = 'impressions',
  advRevenue = 'advRevenue',
}

export type Metadata = {
  budgetType: BudgetType
  childExtType: number
  children: Array<MetaDatum>
  dsp: number
  flightAttachmentTime: string
  origToClone: OrigCloneMapping
  parent: MetaDatum
  parentExtType: number
  statBoxes: {
    primaryGoalSuccessEvent: { name: string, count: number }
    primaryGoalOverallValue: number
  }
  strategy: {
    bidOptEnabled: boolean
    budgetOptEnabled: boolean
    id: number
    intelligenceOptEnabled: boolean
    name: string
    strategyGoals: StrategyGoalsDB,
    viewabilityOptEnabled: boolean
    isYoutube: boolean
    clientEventRevenueValue: number
    revenueType: string
  }
  currency: string
  features?: Array<string>
};

export type TargetingVizDatum = {
  advCost: number
  advRevenue: number
  clicks: number
  conversions: number
  cost: number
  cpcv: number
  impressionValue: number
  impressions: number
  revenue: number
  videoCompletes: number
  viewMeasuredImps: number
  viewableImpressions: number
  targetedByIntelligence: boolean
};

export type IntelligenceOptData = {
  metadata: Metadata & { features: string }
  performanceViz: {
    intelligent?: {
      [date: string]: number
    }
    original?: {
      [date: string]: number
    }
  }
  targetingViz: Array<TargetingVizDatum>
  goalValue: {
    [key: string]: number
  }
};

export type FeatureInsightsDatum = {
  activeViewMeasurableImpressions: number
  activeViewViewableImpressions: number,
  clicks: number,
  completeViewsVideo: number,
  costAdvCurrency: number,
  cpa: number,
  customBiddingTotalImpressionCustomValue: number,
  impressions: number,
  revenueAdvCurrency: number,
  site: string,
  totalConversions: number,
  totalMediaCostAdvertiserCurrency: number
  targetedByIntelligence?: boolean,
};

export type FeatureInsightsData = {
  legend: Array<FeatureInsightsDatum> // top 10 by delivery
  performance: Array<FeatureInsightsDatum>
  metadata: Metadata
};

export type DeviceFeatureInsightsDatum = FeatureInsightsDatum & {
  deviceType: string
};

export type DeviceSizePositionFeatureInsightsDatum = DeviceFeatureInsightsDatum & {
  creativeSize: string
  foldPosition: string
};

export type CreativeDatum = DeviceSizePositionFeatureInsightsDatum & {
  width: number
  height: number
  horizontal: boolean
};

export type CreativeDatumWithPosition = CreativeDatum & {
  x: number
  y: number
};
export type DeviceImageComponentProps = {
  x: number
  y: number
};
export type DeviceConfig = {
  ImageComponent: JSXElementConstructor<DeviceImageComponentProps>
  deviceType: Devices
  displayName: string
  pixelWidth: number
  pixelHeight: number
  horizontalRowPixelHeight: number
  screenMargins: {
    left: number
    right: number
    top: number
    bottom: number
  },
  deviceMargins: {
    left: number
    right: number
    top: number
    bottom: number
  },
  imageHeight: number
  imageWidth: number
  aspectRatio: number
};

type DeviceInsightsDataSlice<D> = {
  legend: Array<D>
  performance: Array<D>
  metadata: Metadata
};
export type DeviceInsightsData = {
  device: DeviceInsightsDataSlice<DeviceFeatureInsightsDatum>
  deviceSizePosition: DeviceInsightsDataSlice<DeviceSizePositionFeatureInsightsDatum>
};

export enum Devices {
  PHONE = 'Mobile',
  TABLET = 'Tablet',
  LAPTOP_OR_PC = 'Desktop',
  CTV = 'CTV',
}

export enum Placements {
  ABOVE = 'Above Fold',
  BELOW = 'Below Fold',
  UNKNOWN = 'Unknown',
}

export type GeoInsightsDatum = FeatureInsightsDatum & {
  displayAdm1: string
  displayCountry: string
  displayRegion: string
  displayRegionAbbr: string
  geoCountry: string
  geoRegion: string
};

export type GeoInsightsData = {
  metadata: Metadata
  performance: Array<GeoInsightsDatum>
  legend: Array<GeoInsightsDatum>
  geoJSON: Array<GeoJsonDatum>
};

export enum PossibleInsightsStates {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  hasData = 'hasData',
  willNotFetch = 'willNotFetch',
}

export type InsightsState = State | { kind: PossibleInsightsStates.willNotFetch };

export type PrimaryStrategyGoal = StrategyGoalAnalyticsGoalType & {
  lowerIsBetter: boolean
  shortText?: string
  valueType?: string
};

export type ViewabiltyGoal = StrategyGoalDB & {
  shortText: string
  name: string
  valueType: string
};

export type DspToAnalyticsData = {
  [DSP.APN.id]?: StrategyGoalAnalyticsData
  [DSP.TTD.id]?: StrategyGoalAnalyticsData
  [DSP.DBM.id]?: StrategyGoalAnalyticsData
  [DSP.AMZN.id]?: StrategyGoalAnalyticsData
};

export type BaseInsightsSlideProps = {
  onVizLoaded: (notes: Array<string>) => void
  onVizError: () => void
};

export type TaskStatus = {
  status: Status
  error?: string
};
