import _ from 'lodash';
import { BudgetTypeMapping } from 'charts/BudgetOptimizationViz/constants';
import { DSP } from 'constantsBase';
import { ChildGroupType, StrategyGoalAnalyticsAttachmentType, StrategyGoalAnalyticsData } from 'containers/StrategyAnalytics/types';
import { BudgetOptOverviewVizDataType, BudgetOptOverviewAggLevel, MAX_ITEM_DISPLAY } from './constants';
import { getOrigChildIdToName } from '../utils';

export const getAggLevel = (
  hasBudgetGroups: boolean,
  isCrossPlatform: boolean,
  childDisplayName: string,
): { aggLevel: BudgetOptOverviewAggLevel, aggDisplayText: string } => {
  if (hasBudgetGroups) {
    return {
      aggLevel: BudgetOptOverviewAggLevel.budgetGroup,
      aggDisplayText: 'group',
    };
  }
  return {
    aggLevel: isCrossPlatform ? BudgetOptOverviewAggLevel.platform : BudgetOptOverviewAggLevel.child,
    aggDisplayText: isCrossPlatform ? 'buying platform' : childDisplayName,
  };
};

export const combineOverflowData = (
  data: Array<BudgetOptOverviewVizDataType>,
  aggDisplayText: string,
): Array<BudgetOptOverviewVizDataType> => {
  if (_.size(data) <= MAX_ITEM_DISPLAY) {
    return data;
  }

  const remainingData = _.drop(data, MAX_ITEM_DISPLAY - 1);
  return [
    ..._.take(data, MAX_ITEM_DISPLAY - 1),
    {
      name: `+ ${_.size(remainingData)} ${_.startCase(aggDisplayText)}s`,
      origCount: _.sumBy(remainingData, 'origCount'),
      cloneCount: _.sumBy(remainingData, 'cloneCount'),
      origDel: _.sumBy(remainingData, 'origDel'),
      cloneDel: _.sumBy(remainingData, 'cloneDel'),
      delivery: _.sumBy(remainingData, 'delivery'),
      goalPerf: _.meanBy(remainingData, 'goalPerf'),
      origGoalPerf: _.meanBy(remainingData, 'origGoalPerf'),
      cloneGoalPerf: _.meanBy(remainingData, 'cloneGoalPerf'),
      isOverflow: true,
    },
  ];
};

const isOrigCheck = (isClone: boolean | null): boolean => !isClone;

// necessary for getting the goal perf of group and/or sub group
type AnalayticsDataType = {
  dspData: { [dsp: string]: StrategyGoalAnalyticsData }
  dspIsCloneData: { [dspOrigOrClone: string]: StrategyGoalAnalyticsData }
} | {
  budgetGroupData: { [groupName: string]: StrategyGoalAnalyticsData }
  budgetGroupIsCloneData: { [groupNameOrigOrClone: string]: StrategyGoalAnalyticsData }
} | {
  lineItemPairData: { [origChildExtId: string]: StrategyGoalAnalyticsData }
  lineItemData: { [childExtId: string]: StrategyGoalAnalyticsData }
};

export const buildBudgetOptOverviewVizData = (
  aggLevel: BudgetOptOverviewAggLevel,
  intelligentChildObjects: boolean,
  analyticsData: AnalayticsDataType,
  primaryGoalKey: string,
  deliveryKpi: BudgetTypeMapping,
  attachments: Array<StrategyGoalAnalyticsAttachmentType>,
  origToClone: { [childExtId: string]: string },
  childGroups?: Array<ChildGroupType>,
  childToGroupName?: { [childExtId: string]: string },
): Array<BudgetOptOverviewVizDataType> => {
  if (aggLevel === BudgetOptOverviewAggLevel.platform) {
    const dspData = _.get(analyticsData, 'dspData');
    const dspIsCloneData = _.get(analyticsData, 'dspIsCloneData');
    return _.orderBy(
      _.reduce(_.groupBy(attachments, 'dsp'), (res, dspParentFlights, dspId) => {
        const dsp = DSP.getById(dspId);
        const dspChildren = _.flatMap(dspParentFlights, 'children');
        return [
          ...res,
          {
            dsp: dsp.id,
            name: dsp.displayName,
            origCount: _.size(_.filter(dspChildren, ({ isClone }) => isOrigCheck(isClone))),
            cloneCount: _.size(_.filter(dspChildren, ['isClone', true])),
            delivery: _.get(dspData, `${dspId}.${deliveryKpi}`),
            origDel: _.get(dspIsCloneData, `${dspId}-orig.${deliveryKpi}`, 0),
            cloneDel: _.get(dspIsCloneData, `${dspId}-clone.${deliveryKpi}`, 0),
            goalPerf: _.get(dspData, `${dspId}.${primaryGoalKey}`),
            origGoalPerf: _.get(dspIsCloneData, `${dspId}-orig.${primaryGoalKey}`, 0),
            cloneGoalPerf: _.get(dspIsCloneData, `${dspId}-clone.${primaryGoalKey}`, 0),
          },
        ];
      }, []),
      'delivery',
      'desc',
    );
  }

  const childFlights = _.flatMap(attachments, 'children');

  if (aggLevel === BudgetOptOverviewAggLevel.budgetGroup) {
    const budgetGroupData = _.get(analyticsData, 'budgetGroupData');
    const budgetGroupIsCloneData = _.get(analyticsData, 'budgetGroupIsCloneData');
    return _.orderBy(
      _.reduce(childGroups, (res, { groupName }) => {
        const childrenInGroup = _.filter(childFlights, ({ childId }) => childToGroupName[childId] === groupName);
        return [
          ...res,
          {
            name: groupName,
            origCount: _.size(_.filter(childrenInGroup, ({ isClone }) => isOrigCheck(isClone))),
            cloneCount: _.size(_.filter(childrenInGroup, ['isClone', true])),
            origDel: _.get(budgetGroupIsCloneData, `${groupName}-orig.${deliveryKpi}`, 0),
            cloneDel: _.get(budgetGroupIsCloneData, `${groupName}-clone.${deliveryKpi}`, 0),
            delivery: _.get(budgetGroupData, `${groupName}.${deliveryKpi}`, 0),
            goalPerf: _.get(budgetGroupData, `${groupName}.${primaryGoalKey}`, 0),
            origGoalPerf: _.get(budgetGroupIsCloneData, `${groupName}-orig.${primaryGoalKey}`, 0),
            cloneGoalPerf: _.get(budgetGroupIsCloneData, `${groupName}-clone.${primaryGoalKey}`, 0),
          },
        ];
      }, []),
      'delivery',
      'desc',
    );
  }

  // aggLevel === BudgetOptOverviewAggLevel.child
  const origToName = getOrigChildIdToName(childFlights);
  const lineItemData = _.get(analyticsData, 'lineItemData');
  const lineItemPairData = _.get(analyticsData, 'lineItemPairData');
  return _.orderBy(
    _.reduce(origToName, (res, name: string, origExtId: string) => {
      const cloneExtId = _.get(origToClone, origExtId);
      return [
        ...res,
        {
          name,
          origCount: 1,
          cloneCount: cloneExtId ? 1 : 0,
          delivery: _.get(intelligentChildObjects ? lineItemPairData : lineItemData, `${origExtId}.${deliveryKpi}`, 0),
          origDel: _.get(lineItemData, `${origExtId}.${deliveryKpi}`, 0),
          cloneDel: _.get(lineItemData, `${cloneExtId}.${deliveryKpi}`, 0),
          goalPerf: _.get(intelligentChildObjects ? lineItemPairData : lineItemData, `${origExtId}.${primaryGoalKey}`, 0),
          origGoalPerf: _.get(lineItemData, `${origExtId}.${primaryGoalKey}`, 0),
          cloneGoalPerf: _.get(lineItemData, `${cloneExtId}.${primaryGoalKey}`, 0),
        },
      ];
    }, []),
    'delivery',
    'desc',
  );
};
