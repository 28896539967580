import _ from 'lodash';
import React from 'react';
import { Grid, Icon, Popup } from 'buildingBlocks';
import { DELIVERY_RATIO_WIDTH, FLIGHT_CELL_WIDTH, GOAL_PERFORMANCE_WIDTH, VIZ_WIDTH } from './constants';
import { pluralizeFlightName } from '../utils';

type BudgetAllocationVizTableHeaderProps = {
  goal: string
  topChildrenCount: number
  childDisplayName: string
};

const BudgetAllocationVizTableHeader = ({ goal, topChildrenCount, childDisplayName }: BudgetAllocationVizTableHeaderProps) => (
  <Grid className="budget-allocation-viz-table-header">
    <Grid.Row>
      <Grid.Column style={{ width: FLIGHT_CELL_WIDTH }}>
        {`Top ${topChildrenCount} ${_.startCase(childDisplayName)}${pluralizeFlightName(topChildrenCount)} by Delivery`}
      </Grid.Column>
      <Grid.Column style={{ width: VIZ_WIDTH - 1 }} className="flex">
        <div id="daily-div-line" />
        Average Daily Delivery
        <div id="daily-budget-line" />
        Average Daily Allocated Budget
      </Grid.Column>
      <Grid.Column style={{ width: DELIVERY_RATIO_WIDTH - 1 }} className="center">
        Delivery Ratio
        <Popup
          hoverable
          wide="very"
          trigger={<Icon name="question circle outline" />}
          content={`The average percentage of the allocated budget that a ${_.lowerCase(childDisplayName)} has delivered. 
            Calculation: Avg. Daily Delivery / Avg. Daily Budget Allocated. A higher ratio indicates a greater proportion of the budget being delivered.`}
        />
      </Grid.Column>
      <Grid.Column style={{ width: GOAL_PERFORMANCE_WIDTH }} className="center">{goal}</Grid.Column>
    </Grid.Row>
  </Grid>
);

export default BudgetAllocationVizTableHeader;
